import {withStyles} from "@material-ui/core";
import {
    AutocompleteInput,
    Create,
    NumberInput, ReferenceInput, required, SelectInput,
    SimpleForm,
    TextInput
} from "react-admin";
import React from "react";
import {OTHER_PLATFORM} from "./BulkProductList";

// component styling
const styles = {

}

function BulkProductCreate({ classes, ...props }) {
    return (
        <Create {...props}>
            <SimpleForm redirect="list">
                <ReferenceInput source="seller_id" reference="users" label="후루츠 셀러 ID" validate={required()}>
                    <AutocompleteInput optionText="username"/>
                </ReferenceInput>
                <SelectInput source="platform" label="플랫폼 명" choices={OTHER_PLATFORM} required={true} />
                <TextInput source="platform_seller_id" label="플랫폼 셀러 ID" required={true} />
                <TextInput source="platform_seller_url" label="URL" required={true} />
                <NumberInput source="shipping_fee" label="배송비" required={true} />
                <NumberInput source="shipping_fee_island" label="도서산간배송비" required={true} />
                <TextInput source="smartstore_key" label="네이버 스마트스토어 KEY" />
                <TextInput source="smartstore_secret_key" label="네이버 스마트스토어 SECRET KEY" />
            </SimpleForm>
        </Create>
    )
}

export default withStyles(styles)(BulkProductCreate);
