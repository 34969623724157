import {
    Create,
    DateTimeInput, FileField, FileInput,
    ImageField,
    ImageInput,
    required, SelectInput,
    SimpleForm,
    TextInput,
} from "react-admin";
import React from "react";
import {notificationPushTypeChoices} from "./NotificationList";

/**
 * Component for a Creating of Notifications.
 * @param props
 * @constructor
 */
export default function NotificationCreate(props) {
    return (
        <Create {...props} >
            <SimpleForm>
                <TextInput source="name" label={"알림 이름"} validate={required()} />
                <TextInput source="title" label={"알림 제목"} validate={required()} />
                <TextInput source="body" label={"알림 내용"} multiline={true} validate={required()}  />
                <ImageInput source="image" accept="image/*" multiple={false}>
                    <ImageField />
                </ImageInput>
                <TextInput source="type" label={"알림 Type"} validate={required()} disabled={true} defaultValue={'deeplink'} />
                <TextInput source="sub_type" label={"알림 sub_type"} validate={required()} placeholder={'deeplink 주소를 입력해주세요.'} />
                <DateTimeInput source="send_time"
                               validate={required()} />
                <TextInput source="firebase_label" label={"firebase_label"} validate={required()} />
                <FileInput placeholder="Upload CSV File" accept=".csv" source="user_csv" label="Users List">
                    <FileField source="user_csv" title="users"/>
                </FileInput>
                <SelectInput label="push_type" source="push_type" choices={notificationPushTypeChoices}/>
            </SimpleForm>
        </Create>
    );
}
