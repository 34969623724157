import React from 'react';
import {
  AutocompleteInput,
  Datagrid,
  DateField,
  DateInput,
  Filter,
  List,
  ReferenceField,
  ReferenceInput,
  TextField,
  TextInput,
} from 'react-admin';
import {withStyles} from '@material-ui/core';
import UserLinkField from '../components/UserLinkField';

// component styling
const styles = {};

function RecommendeeFilter(props) {
  return (
    <Filter {...props}>
      <TextInput source="code" label="초대코드" alwaysOn />
      <ReferenceInput label="친구" source="user_id" reference="users" alwaysOn>
        <AutocompleteInput optionText="username" />
      </ReferenceInput>
      <DateInput source="createdAt_from" label="시작일" alwaysOn />
      <DateInput source="createdAt_until" label="종료일" alwaysOn />
    </Filter>
  );
}
/**
 * Component for a list of Recommendee.
 * @param classes
 * @param props the react admin List component properties
 * @return {JSX.Element}
 * @constructor
 */
function RecommendeeList({classes, ...props}) {
  // build the UI elements
  return (
    <List
      {...props}
      bulkActionButtons={false}
      filters={<RecommendeeFilter />}
      sort={{field: 'id', order: 'DESC'}}>
      <Datagrid rowClick={'edit'}>
        <TextField source="id" />
        <ReferenceField
          label="추천인"
          source="user_referral_code.user_id"
          reference="users"
          link="show">
          <UserLinkField />
        </ReferenceField>
        <TextField label="초대코드" source="user_referral_code.code" />
        <ReferenceField
          label="친구"
          source="user_id"
          reference="users"
          link="show">
          <UserLinkField />
        </ReferenceField>
        <DateField label="생성일" source="created_at" showTime />
      </Datagrid>
    </List>
  );
}

export default withStyles(styles)(RecommendeeList);
