import React from 'react';
import {
    Datagrid,
    ImageField,
    List
} from 'react-admin';
import {withStyles} from "@material-ui/core";

// component styling
const styles = {
    image: {
        '& img': {
            width: 80,
            height: 106.6667, // same 3 by 4 aspect ratio as in the mobile app
            objectFit: 'cover'
        }
    },
};

/**
 * Component for a list of Onboarding Lookbook.
 * @param props the react admin List component properties
 * @return {JSX.Element}
 */
function OnboardingLookbookList({classes, ...props}) {

    // build the UI elements
    return (
        <List{...props}>
            <Datagrid rowClick="edit">
                <ImageField source="resizedSmallImage" label="Image" sortable={false} className={classes.image} />
            </Datagrid>
        </List>
    );
}

export default withStyles(styles)(OnboardingLookbookList);