import {withStyles} from "@material-ui/core";
import {
    Datagrid, Filter, ImageField,
    List, NumberInput,
    ReferenceField, SelectInput,
    TextField, TextInput, useListContext, useNotify, useRefresh, useUnselectAll,
} from "react-admin";
import React, {useState} from "react";
import UserLinkField from "../components/UserLinkField";
import {useFetch} from "../hooks/useFetch";
import {API_URL} from "../config";
import Button from "@material-ui/core/Button";
import BulkUploadCrawledCategoryModal from "./components/BulkUpdateCrawledCategoryModal";

// component styling
const styles = {
    image: {
        '& img': {
            maxWidht: '60px',
            maxHeight: '60px'
        }
    }
}

/**
 * BulkUpdateCategoryButton - multi 카테고리 입력 모달 호출
 * @returns {Element}
 * @constructor
 */
function BulkUpdateCategoryButton() {
    const { selectedIds } = useListContext();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const notify = useNotify();
    const refresh = useRefresh();
    const unselectAll = useUnselectAll();

    const handleOnClick= async () => {
        setIsModalOpen(true)
    };

    return (
        <>
            <Button onClick={handleOnClick} variant="contained" style={styles.banned} size="small">
                카테고리 등록
            </Button>
            <BulkUploadCrawledCategoryModal selectedIds={selectedIds}
                                            open={isModalOpen}
                                            onClose={() => setIsModalOpen(false)}
                                            notify={notify}
                                            refresh={refresh}
                                            unselectAll={unselectAll}
            />
        </>
    );
}

function CrawledProductFilter(props) {
    const categories = useFetch(`${API_URL}/categories?sort=["name","ASC"]`);
    const subcategories = useFetch(`${API_URL}/subcategories?sort=["name","ASC"]`);
    const statusChoices = [
        { value: 'completed', name: 'completed' },
        { value: 'failed', name: 'failed' },
    ];

    const errorChoices = [
        { value: 'false', name: 'NO' },
        { value: 'true', name: 'YES' },
    ];

    return <Filter {...props}>
        <TextInput label="후루츠셀러ID" source="seller_id" alwaysOn />
        <TextInput label="셀러 네임" source="seller_name" alwaysOn />
        <SelectInput source="status" optionValue="value" choices={statusChoices} allowEmpty={true} alwaysOn />
        <TextInput label="title" source="title" alwaysOn />
        {categories && <SelectInput label="category" source="category" choices={categories.result} optionValue="name" alwaysOn/>}
        {subcategories && <SelectInput label="subcategory" source="subcategory" choices={subcategories.result} optionValue="name" alwaysOn/>}
        <TextInput label="브랜드" source="brand" alwaysOn />
        <NumberInput label="min price" source="min_price" alwaysOn/>
        <NumberInput label="max price" source="max_price" alwaysOn/>
        <SelectInput source="error" optionValue="value" choices={errorChoices} allowEmpty={true} alwaysOn />
    </Filter>
}

function CrawledProductList({ classes, ...props }) {
    return (
        <List {...props} bulkActionButtons={<>
            <BulkUpdateCategoryButton />
        </>} filters={<CrawledProductFilter />}  sort={{field: 'id', order: 'DESC'}}>
            <Datagrid rowClick={"edit"} isRowSelectable={(record) =>
                record.status !== 'complete' && !record.category}
            >
                <TextField source="id" />
                <ImageField source="resizedSmallImages.0.url" label="image" sortable={false} className={classes.image}/>
                <ReferenceField label="Seller" source="seller_id" reference="users" link="show">
                    <UserLinkField/>
                </ReferenceField>
                <TextField source="title" label='제목'/>
                <TextField source="category" label='카테고리'/>
                <TextField source="brand" label='브랜드'/>
                <TextField source="gender" label='성별'/>
                <TextField source="price" label='가격'/>
                <TextField source="status" label='진행상태'/>
            </Datagrid>
        </List>
    )
}

export default withStyles(styles)(CrawledProductList);
