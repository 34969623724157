import React from 'react';
import Chip from '@material-ui/core/Chip';
import {translate} from 'react-admin';

const styles = {
    main: {display: 'flex', flexWrap: 'wrap'},
    chip: {margin: 4},
};

const SubCategoryField = ({record}) => {
    return (
        <span style={styles.main}>
        {
            record.subcategories && record.subcategories.map(keyword =>
                <Chip
                    key={keyword.name}
                    style={styles.chip}
                    label={keyword.name}
                />
            )
        }
    </span>
    );
}


SubCategoryField.defaultProps = {
    addLabel: true,
    source: "keywords"
};

export default SubCategoryField
