import React from 'react';
import Avatar from '@material-ui/core/Avatar';

import DefaultAvatarImage from '../assets/images/profileDefault2.png';

const AvatarField = ({ record, source, size }) => {
    const imageSrc = record?.[source] 
        ? `${record[source]}?size=${size}x${size}`
        : DefaultAvatarImage;
    return (
    <Avatar
        src={imageSrc}
        size={size=36}
        style={{ width: size, height: size }}
    />);
}

AvatarField.defaultProps = {
    size: 36,
};

export default AvatarField;
