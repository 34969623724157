import React from 'react';
import {
    EditController,
    SimpleForm,
    DateField,
    ReferenceField,
    Toolbar,
    SaveButton,
    DeleteWithConfirmButton,
    TextInput, TextField
} from 'react-admin';
import IconButton from '@material-ui/core/IconButton';
import withStyles from '@material-ui/core/styles/withStyles';
import CloseIcon from '@material-ui/icons/Close';

import StarRatingField from '../components/StarRatingField';
import UserLinkField from "../components/UserLinkField";

const editStyle = theme => ({
    root: {
        paddingTop: 40,
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '1em',
    },
    form: {
        [theme.breakpoints.up('xs')]: {
            width: 400,
        },
        [theme.breakpoints.down('xs')]: {
            width: '100vw',
            marginTop: -30,
        },
    },
    inlineField: {
        display: 'inline-block',
        width: '50%',
    },
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
});


const CustomToolbar = withStyles(editStyle)(props => (
    <Toolbar {...props}>
        <SaveButton disabled={props.pristine} />
        <DeleteWithConfirmButton
            confirmContent={`⚠️ ${props.record.user.nickname}님이 작성한 ${props.record.id}번 리뷰를 정말로 삭제하시겠습니까?`}
        />
    </Toolbar>
));
const ReviewEdit = ({classes, onCancel, ...props}) => (
    <EditController {...props}>
        {controllerProps =>{
            return controllerProps.record ? (
                <div className={classes.root}>
                    <div className={classes.title}>
                        <IconButton onClick={onCancel}>
                            <CloseIcon/>
                        </IconButton>
                    </div>
                    <SimpleForm
                        className={classes.form}
                        basePath={controllerProps.basePath}
                        record={controllerProps.record}
                        save={controllerProps.save}
                        version={controllerProps.version}
                        redirect="list"
                        resource="reviews"
                        toolbar={<CustomToolbar/>}
                    >
                        <ReferenceField label="구매자" source="user.id" reference="users" link="show">
                            <UserLinkField/>
                        </ReferenceField>
                        <ReferenceField
                            reference="products"
                            source="product_id"
                            label="상품 제목"
                            link="edit">
                            <TextField source="id"/>
                        </ReferenceField>
                        <DateField
                            source="createdAt"
                            label="작성일"
                            formClassName={classes.inlineField}
                        />
                        <StarRatingField formClassName={classes.inlineField} label={"별점"}/>
                        <TextInput multiline source={"text"} label="리뷰 내용" rowsMax={15}/>
                    </SimpleForm>
                </div>
            ) : null
        }}
    </EditController>
);

export default withStyles(editStyle)(ReviewEdit);
