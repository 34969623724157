import React from 'react';
import {
    AutocompleteInput,
    BooleanInput,
    Create,
    ReferenceInput,
    required,
    SimpleForm
} from 'react-admin';

import WarningText from "../components/WarningText";

/**
 * Component for creating an admin user.
 * @param props the react admin Create component properties
 * @return {JSX.Element}
 * @constructor
 */
export default function UserAdminCreate(props) {
    return (
        <Create {...props}>
            <SimpleForm actions={false}>
                <ReferenceInput source="user_id" reference="users" validate={required()}>
                    <AutocompleteInput optionText="username"/>
                </ReferenceInput>
                <WarningText>
                    Only users with an official FruitsFamily email and password authentication can be authorized for the admin.
                </WarningText>
                <BooleanInput source="is_admin" defaultValue={true} validate={required()}/>
            </SimpleForm>
        </Create>
    );
}
