import React, { useCallback, useEffect, useState } from 'react';
import {
    BooleanInput,
    Button,
    Create,
    DateField,
    DateTimeInput,
    FormDataConsumer,
    ImageField,
    ImageInput,
    NumberInput,
    SimpleForm,
    TextInput,
    required
} from "react-admin";
import { useForm } from 'react-final-form';
import {
    Avatar,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
} from '@material-ui/core';
import { AddCircle, Check, Delete } from '@material-ui/icons';

import { paginate } from '../utils/util';
import useForceUpdate from '../hooks/useForceUpdate';
import { DateTimePicker } from "../components/material-ui/DatePicker";
import ResourceSearcher from '../components/react-admin/customs/ResourceSearcher';
import SimpleSellerCard from '../components/SimpleSellerCard';
import UserLinkField from '../components/UserLinkField';

const styles = {
    collectionSellerFrame: {
        width: '100%'
    },
    dialogContent: {
        textAlign: 'center'
    },
    dialogPaperWidthLg: {
        minWidth: '90%',
        maxWidth: '90%',
        height: '100%'
    },
    searchedSellerDiv: {
        display: 'flex',
        justifyContent: 'center',
        flexFlow: 'row wrap'
    },
    sellerChip: {
        padding: '1px',
        margin: '0.1rem'
    },
};

/**
 * Component for creating a seller collection.
 * @param props the react admin Create component properties
 * @return {JSX.Element}
 * @constructor
 */
export default function SellerCollectionCreate(props) {
    const [isOpenSellerAdderModal, setIsOpenSellerAdderModal] = useState(false);
    const forceUpdate = useForceUpdate();

    return (
        <Create {...props}>
            <SimpleForm redirect="list">
                <TextInput multiline source="title" label="Title" />
                <DateTimeInput source="featured_from" lael="Featured From" validate={required()} />
                <DateTimeInput source="featured_until" lael="Featured Until" />
                <NumberInput source="priority" label="Priority" validate={required()} />
                <TextInput source="tab_name" label="Tab Name" validate={required()} />
                <TextInput source="sub_title" label="Subtitle" />
                <TextInput multiline source="description" label="Description" />
                <BooleanInput source="is_random" defaultValue={false} />
                <ImageInput source="image" accept="image/*" multiple={false}>
                    <ImageField source="url" />
                </ImageInput>
                <ImageInput source="icon_image" accept="image/*" multiple={false} validate={required()}>
                    <ImageField source="url" />
                </ImageInput>

                <br/>
                <h3>셀러뱃지</h3>
                <BooleanInput source="is_badge" defaultValue={false} />
                <TextInput multiline source="short_desc" label="Short Description" />
                <ImageInput label="icon(svg only)" source="icon" accept="image/svg+xml" multiple={false}>
                    <ImageField source="url" />
                </ImageInput>
                <br/>
                <FormDataConsumer>
                    {({ formData }) => formData.image !== undefined && (
                        <NumberInput source="aspect_ratio" step={0.0000000000000001} validate={required()} />
                    )}
                </FormDataConsumer>
                <Button variant="contained" label="ADD SELLERS" onClick={() => setIsOpenSellerAdderModal(true)} />
                <FormDataConsumer>
                    {({ formData }) => {
                        const form = useForm();
                        return (<CollectionSellerAdderModal open={isOpenSellerAdderModal} onAdd={(newAddedSellers) => {
                            const newMySeller = [...newAddedSellers];
                            formData.mySeller && newMySeller.push(...formData.mySeller)
                            const nonDuplicatedSellers = _.uniqBy(newMySeller, 'id');
                            form.change('mySeller', nonDuplicatedSellers)
                            forceUpdate();
                        }}
                            onClose={() => setIsOpenSellerAdderModal(false)}
                        />)
                    }}
                </FormDataConsumer>
                <FormDataConsumer>
                    {({ formData }) => {
                        const form = useForm();
                        const onDeleteSeller = (toBeDeletedSeller) => {
                            const exceptedMe = formData.mySeller.filter((seller) => seller.id !== toBeDeletedSeller.id);
                            form.change('mySeller', exceptedMe);
                        }
                        return (
                            <CollectionSellerList style={styles.collectionSellerFrame} sellers={formData.mySeller || []} onDeleteSeller={onDeleteSeller} />
                        )
                    }}
                </FormDataConsumer>
            </SimpleForm>
        </Create>
    )
}

/**
 * Sub-component for listing a list of seller collections.
 * @param {object} sellers Request type
 * @param {function} onDeleteSeller Request type
 * @returns {JSX.Element}
 * @constructor
 */
export function CollectionSellerList({ sellers, onDeleteSeller }) {
    const [updateCounter, setUpdateCounter] = useState(0); // for force update
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [displaySellers, setDisplaySellers] = useState(sellers && paginate(sellers, page, rowsPerPage) || []); // paginated Sellers
    const now = new Date();

    const onDelete = useCallback((seller) => {
        onDeleteSeller(seller)
    }, [onDeleteSeller]);

    const onChangePage = (event, newPage) => {
        setPage(newPage);
    }
    const onChangeRowsPerPage = (event) => {
        setPage(page);
        setRowsPerPage(event.target.value);
    };
    useEffect(() => {
        const sellersCopy = [...sellers];
        const newDisplaySellers = paginate(sellersCopy, page, rowsPerPage);
        setDisplaySellers(newDisplaySellers);
    }, [updateCounter, page, rowsPerPage, sellers]);
    return (
        <div style={{ marginBottom: '80px' }}>
            {displaySellers.length ?
                <Table style={styles.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Seller id</TableCell>
                            <TableCell>Seller</TableCell>
                            <TableCell>Added at</TableCell>
                            <TableCell>Featured at</TableCell>
                            <TableCell>Delete</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {displaySellers.map((seller, index) => {
                            return (
                                <TableRow key={`${seller.id}_${index}`}>
                                    <TableCell>{seller.id}</TableCell>
                                    <TableCell>
                                        <UserLinkField record={seller.user} />
                                    </TableCell>
                                    <TableCell><DateField source="guide_collection_seller.createdAt" record={seller} /></TableCell>
                                    <TableCell>
                                        <DateTimePicker
                                            value={seller.guide_collection_seller?.featuredAt || null}
                                            onChange={(newDate) => {
                                                seller.guide_collection_seller = Object.assign({}, seller.guide_collection_seller, { featuredAt: newDate });
                                                setUpdateCounter(updateCounter + 1);
                                            }}
                                            ampm={false}
                                            format='yyyy.MM.dd HH:mm:ss'
                                            maxDate={now}
                                            clearable
                                        />
                                    </TableCell>
                                    <TableCell align="left">
                                        <Button onClick={() => { onDelete(seller) }}><Delete /></Button>
                                    </TableCell>
                                </TableRow>
                            )
                        }
                        )}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[1, 2, 5, 10, 25]}
                                component="div"
                                count={sellers.length || 0}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                backIconButtonProps={{
                                    'aria-label': 'Previous Page',
                                }}
                                nextIconButtonProps={{
                                    'aria-label': 'Next Page',
                                }}
                                onChangePage={onChangePage}
                                onChangeRowsPerPage={onChangeRowsPerPage}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
                : null
            }
        </div>
    )
}

/**
 * Sub-component for adding seller to the seller collections.
 * @param {boolean} open Request type
 * @param {function} onClose Request type
 * @param {function} onAdd Request type
 * @returns {JSX.Element}
 * @constructor
 */
export function CollectionSellerAdderModal({ open, onClose, onAdd }) {
    const [searchedSellers, setSearchedSellers] = useState([]);
    const [selectedSellers, setSelectedSellers] = useState(new Map());
    const now = new Date();
    const onSelectItem = useCallback((seller) => {
        selectedSellers.set(seller.id, seller);
        setSelectedSellers(new Map(selectedSellers));
    });

    const onAddNewSellers = useCallback(() => {
        const selectedSellerArray = [...selectedSellers.values()];
        onAdd(selectedSellerArray);
        onClose();
        setSelectedSellers(new Map());
    });

    function SellerCardAction({ seller, isSelected }) {
        function FeaturedAtPicker() {
            return (
                <DateTimePicker
                    value={seller.guide_collection_seller?.featuredAt || null}
                    onChange={(newDate) => {
                        seller.guide_collection_seller = Object.assign({}, seller.guide_collection_seller, { featuredAt: newDate });
                        setSearchedSellers([...searchedSellers]);
                    }}
                    label="Featured at"
                    ampm={false}
                    format='yyyy.MM.dd HH:mm:ss'
                    maxDate={now}
                    clearable
                />
            )
        }
        return (
            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                <FeaturedAtPicker />
                {
                    isSelected
                        ? <Check />
                        : <Button onClick={e => onSelectItem(seller)} label="Add"><AddCircle /></Button>
                }
            </div>
        );
    }
    return (
        <Dialog open={open} onClose={onClose} maxWidth="lg" style={styles.dialogPaperWidthLg}>
            <DialogTitle>Collection Sellers Adder</DialogTitle>
            <DialogContent style={styles.dialogContent}>
                <ResourceSearcher resource="sellers" filterKeys={['seller_name']} filterList={{ status: 'active', excludeBanUser: true, is_visible: true }} placeholder="Enter the nickname" onChangeSearchedList={(sellers) => setSearchedSellers(sellers)} />
                <div style={{ marginTop: '20px' }}>
                    {
                        [...selectedSellers.keys()].map((key) => {
                            const mySeller = selectedSellers.get(key);
                            return <Chip style={styles.sellerChip} color="primary" key={key} label={`${key}: ${mySeller.user.nickname}`}
                                avatar={<Avatar alt={mySeller.user.nickname} />}
                                onDelete={() => { selectedSellers.delete(key); setSelectedSellers(new Map(selectedSellers)); }} />
                        })
                    }
                </div>
                <Divider style={{ margin: '18px' }} />
                <div style={styles.searchedSellerDiv}>
                    {
                        searchedSellers.map(seller => {
                            const selectedSeller = selectedSellers.get(seller.id);
                            return <SimpleSellerCard
                                key={seller.id}
                                seller={selectedSeller || seller}
                                actions={<SellerCardAction seller={selectedSeller || seller} isSelected={!!selectedSeller} />}
                            />
                        })
                    }
                </div>
            </DialogContent>
            <DialogActions>
                <Button label="Cancel" onClick={onClose} />
                <Button label="Add" onClick={onAddNewSellers} />
            </DialogActions>
        </Dialog>
    )
}
