import React, {useCallback} from 'react';
import {useState} from 'react';
import {
  AutocompleteInput,
  Button,
  FileField,
  FileInput,
  Loading,
  ReferenceInput,
  SimpleForm,
} from 'react-admin';
import {connect} from 'react-redux';
import {push} from 'connected-react-router';
import withStyles from '@material-ui/core/styles/withStyles';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import {API_URL} from '../config';
import {loadAccessToken} from '../lib/Auth';

const modalStyles = {
  dialogContent: {
    textAlign: 'center',
  },
  dialogPaperWidthMd: {
    width: '100%',
  },
  extractButton: {
    margin: '10px',
  },
};

const IssueCsvCouponModal = withStyles(modalStyles)(({
  classes,
  basePath,
  open,
  onClose,
  push,
  ...props
}) => {
  const [couponId, setCouponId] = useState(null);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleOnIssueCoupon = useCallback(async () => {
    const formData = new FormData();
    formData.append('coupon_id', couponId);
    formData.append('user_csv', file);
    setLoading(true);

    const response = await fetch(`${API_URL}/coupons/csv-issue`, {
      method: 'POST',
      headers: new Headers({
        Authorization: `bearer ${loadAccessToken()}`,
      }),
      body: formData,
    });

    const {result, error} = await response.json();

    if (result) {
      alert('쿠폰이 정상적으로 발급되었습니다.');
      setLoading(false);
      setCouponId(null);
      setFile(null);
      onClose();
    } else {
      alert(error);
      setLoading(false);
      setCouponId(null);
      setFile(null);
    }
  }, [couponId, file, onClose]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      classes={{paperWidthMd: classes.dialogPaperWidthMd}}>
      <DialogTitle>CSV 대상자 쿠폰 발급</DialogTitle>
      <DialogContent classes={{root: classes.dialogContent}}>
        {loading ? (
          <Loading
            loadingPrimary="Loading"
            loadingSecondary="waiting for extration..."
          />
        ) : (
          <SimpleForm
            toolbar={
              <Button
                disabled={!file || !couponId}
                variant="contained"
                label="쿠폰 발급"
                onClick={handleOnIssueCoupon}
              />
            }>
            <ReferenceInput
              reference={'coupons'}
              source={'coupon_id'}
              onChange={setCouponId}>
              <AutocompleteInput optionText="title" />
            </ReferenceInput>
            <FileInput
              placeholder="Upload CSV File"
              accept=".csv"
              source="user_csv"
              label="Users List"
              onChange={setFile}>
              <FileField source="user_csv" title="users" />
            </FileInput>
          </SimpleForm>
        )}
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} label="Cancel" onClick={onClose} />
      </DialogActions>
    </Dialog>
  );
});

export default connect(null, {
  push,
})(IssueCsvCouponModal);
