// in src/users.js
import React from 'react';
import {
    ArrayField,
    List,
    TopToolbar,
    Datagrid,
    ExportButton,
    TextField,
    Edit,
    SimpleForm,
    SelectInput,
    TextInput,
    Filter,
    NumberInput,
    Show,
    ShowButton,
    SimpleShowLayout,
    ReferenceField,
    DateField,
    BooleanField,
    BooleanInput,
    DateInput,
    Toolbar,
    SaveButton, AutocompleteInput, SelectField,
} from 'react-admin';
import UserLinkField from "../components/UserLinkField";
import withStyles from "@material-ui/core/styles/withStyles";
import rowStyle from './rowStyle'
import FormattableTextField from '../components/FormattableTextField';
import { textEllipsis } from '../utils/util';
import {AuditViewer} from '../audits/AuditList';
import {Table, TableBody, TableCell, TableHead, TableRow} from '@material-ui/core';
import CustomPriceField from '../components/CustomPriceField';

const styles = {
    date: {
        whiteSpace: 'nowrap'
    },
    inline: {
        display: 'inline',
        marginRight: 20
    },
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    table: {
        width: 'auto',
        tableLayout: 'fixed',
    },
    tableHead: {
        fontWeight: 'bold',
        backgroundColor: '#f5f5f5',
        width: '100px',
        whiteSpace: 'nowrap',
        border: '1px solid #e0e0e0',
        textAlign: 'center'
    },
    tableCell: {
        textAlign: 'center',
        border: '1px solid #e0e0e0'
    }
}

const COURIER_COMPANIES = [
    {name: 'CJ대한통운'},
    {name: '우체국택배'},
    {name: '롯데택배'},
    {name: 'GS Postbox 택배'},
    {name: 'CU 편의점택배'},
    {name: '로젠택배'},
    {name: '홈픽택배'},
    {name: '경동택배'},
    {name: '대신택배'},
    {name: '일양로지스'},
    {name: '농협택배'},
    {name: '한진택배'},
    {name: '천일택배'},
    {name: '건영택배'},
    {name: '합동택배'},
    {name: 'SLX택배'},
    {name: '용마로지스'},
    {name: '로지스밸리택배'},
    {name: '큐런택배'},
    {name: '기타'},
];
const feeAssignedChoices = [
    { value: 'SELLER', name: '판매자' },
    { value: 'BUYER', name: '구매자' },
]

const OrderFilter = (props) => {
    const statusChoices = [
        { value: 'all', name: 'All' },
        { value: 'pending', name: 'Pending' },
        { value: 'shipping', name: 'Shipping' },
        { value: 'completed', name: 'Completed' },
        { value: 'settled', name: 'settled' },
        { value: 'cancelled', name: 'Cancelled' },
    ];

    const platformChoices = [
        { value: null, name: 'All' },
        { value: 'MOBILE', name: 'Mobile' },
        { value: 'WEB', name: 'Web' },
    ]

    const negotiationChoices = [
        { value: '', name: 'All' },
        { value: 'true', name: 'True' },
        { value: 'false', name: 'False' },
    ]

    return (
        <Filter {...props}>
            <SelectInput source="status" allowEmpty={false} choices={statusChoices} optionValue="value" alwaysOn/>
            <SelectInput source="platform" allowEmpty={false} choices={platformChoices} optionValue="value" alwaysOn/>
            <DateInput source="createdAt_from" label="paid From" alwaysOn />
            <DateInput source="createdAt_until" label="paid Until" alwaysOn />
            <TextInput source="id" label="id"  alwaysOn/>
            <TextInput source="purchase_id" label="purchase_id"  alwaysOn/>
            <TextInput label="seller name" source="seller_name" alwaysOn/>
            <NumberInput label="customer user_id" source="user_id" alwaysOn/>
            <TextInput label="customer_phone" source="customer_phone" alwaysOn/>
            <TextInput label="customer_name" source="customer_name" alwaysOn/>
            <TextInput label="invoice number" source="invoice_number" alwaysOn/>
            <SelectInput source="negotiation_id"
                         allowEmpty={true}
                         choices={negotiationChoices}
                         optionValue="value" alwaysOn/>
            <SelectInput source="fee_assigned"
                         label={'수수료 부담 대상'}
                         allowEmpty={true}
                         choices={feeAssignedChoices}
                         optionValue="value" alwaysOn/>
        </Filter>
    );
}

export const OrderShow = withStyles(styles)(({classes = {}, ...props}) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id"/>
            <ReferenceField label="User" source="user_id" reference="users" link="show">
                <UserLinkField/>
            </ReferenceField>
            <ReferenceField label="Seller" source="seller_id" reference="users" link="show">
                <UserLinkField/>
            </ReferenceField>
            <ReferenceField
                reference="products"
                source="product_id"
                label="product"
                link="edit">
                <TextField source="title"/>
            </ReferenceField>


            <TextField label="price" source="price"/>
            <TextField label="status" source="status"className={classes.inline}/>
            <TextField label="cancellation reason" source="cancellation_reason" className={classes.inline}/>
            <TextField label="cancelled by" source="cancelled_by" className={classes.inline}/>
            <DateField label="cancelled At" source="cancelledAt" className={classes.inline}/>
            <div/>
            <DateField label="paid Date" source="createdAt" className={classes.inline}/>
            <DateField label="delivered Date" source="deliveredAt" className={classes.inline}/>
            <DateField label="completed Date" source="completedAt" className={classes.inline}/>
            <div/>
            <TextField label="customer name" source="customer_name" className={classes.inline}/>
            <TextField label="customer phone" source="customer_phone" className={classes.inline}/>
            <TextField label="address" source="address" className={classes.inline}/>
            <BooleanField label="is island" source="is_island" className={classes.inline}/>
            <div/>
            <TextField label="postal_code" source="postal_code" className={classes.inline}/>
            <TextField label="courier service" source="courier_service" className={classes.inline}/>
            <TextField label="invoice number" source="invoice_number" className={classes.inline}/>
            <TextField label="message" source="message" className={classes.inline}/>

            <h3>가격정보</h3>
            <Table style={styles.table}>
                <TableHead>
                    <TableRow>
                        <TableCell style={styles.tableHead}>수수료 부담 대상</TableCell>
                        <TableCell style={styles.tableHead}>상품 가격</TableCell>
                        <TableCell style={styles.tableHead}>네고된 가격</TableCell>
                        <TableCell style={styles.tableHead}>상품 가격과 네고 가격 차이 금액</TableCell>
                        <TableCell style={styles.tableHead}>쿠폰 할인 금액</TableCell>
                        <TableCell style={styles.tableHead}>배송비</TableCell>
                        <TableCell style={styles.tableHead}>수수료</TableCell>
                        <TableCell style={styles.tableHead}>최종 결제 금액</TableCell>
                        <TableCell style={styles.tableHead}>예상 정산 금액</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell style={styles.tableCell}>
                            <SelectField source="orderPayment.fee_assigned" choices={feeAssignedChoices} optionValue="value" defaultValue={'판매자'} />
                        </TableCell>
                        <TableCell style={styles.tableCell}>
                            <CustomPriceField source="product.price" />
                        </TableCell>
                        <TableCell style={styles.tableCell}>
                            <CustomPriceField source="negotiation_price" />
                        </TableCell>
                        <TableCell style={styles.tableCell}>
                            <CustomPriceField source="difference_price" />
                        </TableCell>
                        <TableCell style={styles.tableCell}>
                            <CustomPriceField source="coupon_discount" />
                        </TableCell>
                        <TableCell style={styles.tableCell}>
                            <CustomPriceField source="shipping_fee" />
                        </TableCell>
                        <TableCell style={styles.tableCell}>
                            <CustomPriceField source="orderPayment.card_fee_amount" />
                        </TableCell>
                        <TableCell style={styles.tableCell}>
                            <CustomPriceField source="price" />
                        </TableCell>
                        <TableCell style={styles.tableCell}>
                            <CustomPriceField source="orderPayment.settlement_amount" />
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>

            {/* Settlement */}
            <ReferenceField label="Settlement" source="settlement.id" reference="settlements" link="show" allowEmpty={true}>
                <DateField source="createdAt"/>
            </ReferenceField>

            {/* Audit History */}
            <ArrayField source="audits">
                <Datagrid rowClick="expand" expand={<AuditViewer />}>
                    <TextField source="id" label="Audit id" />
                    <TextField source="auditableId" label="Order id" />
                    <FormattableTextField source="data" format={(historyObject) => textEllipsis(JSON.stringify(historyObject), 120)}/>
                    <DateField source="createdAt" showTime className={classes.date} />
                </Datagrid>
            </ArrayField>
            <ShowButton/>
        </SimpleShowLayout>
    </Show>
));
const CustomToolbar = withStyles(styles)(props => (
    <Toolbar {...props}>
        <SaveButton disabled={props.pristine}/>
    </Toolbar>
));

export const OrderEdit = withStyles(styles)(({classes = {}, ...props}) => (
    <Edit {...props} undoable={false} redirect="list">
        <SimpleForm toolbar={<CustomToolbar/>}>
            <TextInput source="id" disabled/>
            <ReferenceField label="User" source="user_id" reference="users" link="show">
                <UserLinkField/>
            </ReferenceField>
            <ReferenceField label="Product" source="product_id" reference="products" link="edit">
                <TextField label="title" source="title"/>
            </ReferenceField>

            <h2>Status변경시 주의사항</h2>
            <p>※ Status를 <b>Pending에서 Cancelled</b>로 변경시, 결제가 취소되고 환불됩니다. 주의 부탁드립니다!</p>
            <p>※ Status를 <b>Completed</b>로 변경시, 리뷰가 자동으로 생성됩니다. 주의 부탁드립니다!</p>
            <SelectInput label="Status" source="status" choices={[
                {
                    id: 'pending', name: 'pending'
                }, {
                    id: 'shipping', name: 'shipping'
                }, {
                    id: 'completed', name: 'completed'
                }, {
                    id: 'settled', name: 'settled'
                }, {
                    id: 'cancelled', name: 'cancelled'
                }
            ]}/>
            <TextField label="cancellation reason" source="cancellation_reason"/>
            <TextField label="cancelled by" source="cancelled_by"/>
            <DateField label="cancelled At" source="cancelledAt"/>
            <DateField label="paid Date" source="createdAt"/>
            <DateField label="delivered Date" source="deliveredAt"/>
            <DateField label="completed Date" source="completedAt"/>
            <TextField source="platform"/>

            <TextInput label="customer name" source="customer_name"/>
            <TextInput label="customer phone" source="customer_phone"/>
            <TextInput label="address" source="address"/>
            <BooleanInput label="is island" source="is_island"/>
            <TextInput label="postal_code" source="postal_code"/>
            <AutocompleteInput label="courier service" source="courier_service" choices={COURIER_COMPANIES}
                               optionValue="name" allowEmpty={true}/>
            <TextInput label="invoice number" source="invoice_number"/>
            <TextInput label="message" source="message"/>

            <h3>가격 정보</h3>
            <Table style={styles.table}>
                <TableHead>
                    <TableRow>
                        <TableCell style={styles.tableHead}>수수료 부담 대상</TableCell>
                        <TableCell style={styles.tableHead}>상품 가격</TableCell>
                        <TableCell style={styles.tableHead}>네고된 가격</TableCell>
                        <TableCell style={styles.tableHead}>상품 가격과 네고 가격 차이 금액</TableCell>
                        <TableCell style={styles.tableHead}>쿠폰 할인 금액</TableCell>
                        <TableCell style={styles.tableHead}>배송비</TableCell>
                        <TableCell style={styles.tableHead}>수수료</TableCell>
                        <TableCell style={styles.tableHead}>최종 결제 금액</TableCell>
                        <TableCell style={styles.tableHead}>예상 정산 금액</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell style={styles.tableCell}>
                            <SelectField source="orderPayment.fee_assigned" choices={feeAssignedChoices} optionValue="value" defaultValue={'판매자'} />
                        </TableCell>
                        <TableCell style={styles.tableCell}>
                            <CustomPriceField source="product.price" />
                        </TableCell>
                        <TableCell style={styles.tableCell}>
                            <CustomPriceField source="negotiation_price" />
                        </TableCell>
                        <TableCell style={styles.tableCell}>
                            <CustomPriceField source="difference_price" />
                        </TableCell>
                        <TableCell style={styles.tableCell}>
                            <CustomPriceField source="coupon_discount" />
                        </TableCell>
                        <TableCell style={styles.tableCell}>
                            <CustomPriceField source="shipping_fee" />
                        </TableCell>
                        <TableCell style={styles.tableCell}>
                            <CustomPriceField source="orderPayment.card_fee_amount" />
                        </TableCell>
                        <TableCell style={styles.tableCell}>
                            <CustomPriceField source="price" />
                        </TableCell>
                        <TableCell style={styles.tableCell}>
                            <CustomPriceField source="orderPayment.settlement_amount" />
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>

            {/* Settlement */}
            <ReferenceField label="Settlement" source="settlement.id" reference="settlements" link="show"
                            allowEmpty={true}>
                <DateField source="createdAt"/>
            </ReferenceField>

            {/* Audit History */}
            <ArrayField source="audits">
                <Datagrid rowClick="expand" expand={<AuditViewer/>}>
                    <TextField source="id" label="Audit id"/>
                    <TextField source="auditableId" label="Order id"/>
                    <ReferenceField source="user_id" reference="users">
                        <UserLinkField/>
                    </ReferenceField>
                    <FormattableTextField source="data"
                                          format={(historyObject) => textEllipsis(JSON.stringify(historyObject), 120)}/>
                    <DateField source="createdAt" showTime className={classes.date}/>
                </Datagrid>
            </ArrayField>
        </SimpleForm>
    </Edit>
));

const OrderListActions = ({
                              bulkActionButtons,
                              basePath,
                              currentSort,
                              displayedFilters,
                              exporter,
                              filters,
                              filterValues,
                              onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    total
}) => (
    <TopToolbar>
        {bulkActionButtons && React.cloneElement(bulkActionButtons, {
            basePath,
            filterValues,
            resource,
            selectedIds,
            onUnselectItems,
        })}
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        }) }
        <ExportButton
            disabled={total === 0}
            resource={resource}
            sort={currentSort}
            filter={filterValues}
            exporter={exporter}
            maxResults={1000000}
        />
    </TopToolbar>
);

export const OrderList = props => (
    <List {...props} filters={<OrderFilter/>} actions={<OrderListActions />} sort={{field: 'id', order: 'DESC'}}>
        <Datagrid rowClick="edit" rowStyle={rowStyle}>
            <TextField source="id"/>
            <ReferenceField label="Seller" source="seller_id" reference="users" link="show">
                <UserLinkField/>
            </ReferenceField>
            <ReferenceField label="Buyer" source="user_id" reference="users" link="show">
                <UserLinkField/>
            </ReferenceField>
            <ReferenceField label="Product" source="product_id" reference="products" link="edit">
                <TextField label="title" source="title"/>
            </ReferenceField>
            <TextField label="payment amount" source="price"/>
            <TextField label='coupon_discount' source='coupon_discount'/>
            <TextField label="shipping fee" source="shipping_fee"/>
            <TextField label="purchase_id" source="purchase_id"/>
            <TextField label="customer name" source="customer_name"/>
            <TextField label="status" source="status"/>
            <DateField label="paid At" source="createdAt"/>
            <DateField label="delivered At" source="deliveredAt"/>
            <DateField label="completed At" source="completedAt"/>
            <ReferenceField label="settlement" source="settlement.id" reference="settlements" link="show" allowEmpty={true}>
                <DateField source="createdAt"/>
            </ReferenceField>
            <DateField label="cancelled At" source="cancelledAt"/>
            <TextField label="cancelled by" source="cancelled_by"/>
            <TextField label="cancel reason" source="cancellation_reason"/>
        </Datagrid>
    </List>
);

