import React, {Fragment, useState, useCallback} from 'react';
import {
  GET_LIST,
} from 'react-admin';
import PropTypes from 'prop-types';
import MuiTextField from '@material-ui/core/TextField';

import dataProvider from "../../../dataProvider";

/**
 * Search products, users... etc by text search
 * */
export const ResourceSearcher = ({resource, method = GET_LIST, placeholder,
  pagination = {page: 1, perPage: 20}, sort = {field: 'id', order: 'DESC'}, filterKeys = ['id'], onChangeSearchedList, filterList}) => {
  const [searchText, setSearchText] = useState('');

  const onChangeSearchText = async (event) => {
    const newText = event.target.value;
    setSearchText(newText);

    const filter = {};
    filterKeys.forEach((filterKey) => filter[filterKey] = newText);
    Object.assign(filter, filterList)

    const response = await dataProvider(method, resource, {
      pagination,
      sort,
      filter
    });

    if(onChangeSearchedList) {
      onChangeSearchedList(response.data)
    }
  };

  return (
    <Fragment>
      <MuiTextField placeholder={placeholder || "Enter the keyword"} value={searchText} onChange={onChangeSearchText} />
    </Fragment>
  )
}

ResourceSearcher.propTypes = {
  resource: PropTypes.string.isRequired,
  filterKeys: PropTypes.array.isRequired,
  method: PropTypes.string,
  pagination: PropTypes.object,
  sort: PropTypes.object,
  onChangeSearchedList: PropTypes.func,
}

export default ResourceSearcher;