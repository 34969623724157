import React from 'react';
import { 
    List, 
    Filter, 
    TextInput, 
    SelectInput, 
    DateInput, 
    ReferenceInput,
    AutocompleteInput,
    TextField,
    Datagrid,
    DateField,
    ReferenceField
} from 'react-admin'
import { withStyles } from '@material-ui/core';
import UserLinkField from "../components/UserLinkField";

const typeChoices = [
    { value: 'admin', name: 'Admin'},
    { value: 'apple', name: 'Apple'},
    { value: 'facebook', name: 'Facebook'},
    { value: 'kakao', name: 'Kakao'},
    { value: 'email', name: 'Email'},
]
const resultChoices = [
    { value: 'success', name: 'success'},
    { value: 'bad_request', name: 'bad_request'},
    { value: 'too_many_requests', name: 'too_many_requests'},
    { value: 'invalid_user', name: 'invalid_user'},
    { value: 'invalid_credentials', name: 'invalid_credentials'},
    { value: 'internal_server_error', name: 'internal_server_error'},
];

// component styling
const styles = {
    date: {
        whiteSpace: 'nowrap'
    },
    description: {
        display: 'block',
        maxWidth: 300,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    }
};

/**
 * @param props the react admin Filter component properties
 * @return {JSX.Element}
 * @constructor
 */

function LoginAuditFilter(props) {
    return (
        <Filter {...props}>
            <DateInput source="createdAt" label="Date" alwaysOn />
            <SelectInput source="loginType" choices={typeChoices} optionValue="value" allowEmpty={true} alwaysOn />
            <TextInput source="email"alwaysOn />
            <TextInput source="snsId" alwaysOn />
            <SelectInput source="result" choices={resultChoices} optionValue="value" allowEmpty={true} alwaysOn />
            <TextInput source="ipAddress" alwaysOn />
            <TextInput source="userAgent" alwaysOn />
            <ReferenceInput source="user_id" reference="users" alwaysOn>
                <AutocompleteInput optionText="username" />
            </ReferenceInput>
        </Filter>
    );
}

/**
 * Component for a list of login audits.
 * @param props the react admin List component properties
 * @return {JSX.Element}
 * @constructor
 */
function LoginAuditList({classes, ...props}) {
    return (
        <List {...props} bulkActionButtons={false} filters={<LoginAuditFilter />} sort={{field: 'createdAt', order: 'DESC'}}>
            <Datagrid rowClick="show">
                <DateField label= "Date" source="createdAt" showTime className={classes.date} />
                <ReferenceField source="user_id" reference="users" allowEmpty>
                    <UserLinkField/>
                </ReferenceField>
                <TextField source="loginType" className={classes.description} />
                <TextField source="type" className={classes.description} />
                <TextField source="email" className={classes.description} />
                <TextField source="snsId" className={classes.description} />
                <TextField source="result" className={classes.description} />
                <TextField source="ipAddress" className={classes.description} />
                <TextField source="userAgent" className={classes.description} />
                <TextField source="deviceToken" className={classes.description} />
            </Datagrid>
        </List>
    );
}

export default withStyles(styles)(LoginAuditList);