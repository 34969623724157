// in myRestProvider.js
import {stringify} from 'query-string';
import {
    GET_LIST,
    GET_ONE,
    CREATE,
    UPDATE,
    UPDATE_MANY,
    DELETE,
    DELETE_MANY,
    GET_MANY,
    GET_MANY_REFERENCE,
    userLogout
} from 'react-admin';
import {LOAD_ITEMS_LIMIT,API_URL} from './config'
import processMutipart from './utils/processForMultipart'
import { loadAccessToken, removeAccessToken } from './lib/Auth';

const apiUrl = `${API_URL}`

/**
 * Maps react-admin queries to my REST API
 *
 * @param {string} type Request type, e.g GET_LIST
 * @param {string} resource Resource name, e.g. "posts"
 * @param {Object} payload Request parameters. Depends on the request type
 * @returns {Promise} the Promise for a data response
 */
export default (type, resource, params) => {
    let url = '';
    const options = {
        headers: new Headers({
            'content-type' : 'application/json',
            Authorization: `bearer ${loadAccessToken()}`
        }),
    };

    switch (type) {
        case GET_LIST: {
            const {page, perPage} = params.pagination;
            const {field, order} = params.sort;
            const query = {
                sort: JSON.stringify([field, order]),
                offset: (page - 1) * perPage,
                limit: perPage,
                filter: JSON.stringify(params.filter),
            };
            url = `${apiUrl}/${resource}?${stringify(query)}`;
            break;
        }
        case GET_ONE:
            url = `${apiUrl}/${resource}/${params.id}`;
            break;
        case CREATE:
            url = `${apiUrl}/${resource}`;
            options.method = 'POST'
            if (params.is_multipart){
                options.body = processMutipart(params.data)
                options.headers.delete('content-type')
            } else {
                options.body = JSON.stringify(params.data);
            }
            break;
        case UPDATE:
            url = `${apiUrl}/${resource}/${params.id}`;
            options.method = 'PUT';
            console.log({data : params.data})
            if (params.is_multipart){
                console.log({data : params.data})
                options.body = processMutipart(params.data)
                options.headers.delete('content-type')
            } else {
                options.body = JSON.stringify(params.data);
            }
            break;
        case UPDATE_MANY:
            const query = {
                filter: JSON.stringify({id: params.ids}),
            };
            url = `${apiUrl}/${resource}?${stringify(query)}`;
            options.method = 'PATCH';
            options.body = JSON.stringify(params.data);
            break;
        case DELETE:
            url = `${apiUrl}/${resource}/${params.id}`;
            options.method = 'DELETE';
            break;
        case DELETE_MANY:
            url = `${apiUrl}/${resource}`;
            options.body = JSON.stringify(params)
            options.method = 'DELETE';
            break;
        case GET_MANY: {
            const query = {
                filter: JSON.stringify({id: params.ids}),
            };
            url = `${apiUrl}/${resource}?${stringify(query)}`;
            break;
        }
        case GET_MANY_REFERENCE: {
            const {page, perPage} = params.pagination;
            const {field, order} = params.sort;
            const query = {
                sort: JSON.stringify([field, order]),
                range: JSON.stringify([
                    (page - 1) * perPage,
                    page * perPage - 1,
                ]),
                offset: (page - 1) * perPage,
                limit: perPage,
                filter: JSON.stringify({
                    ...params.filter,
                    [params.target]: params.id,
                }),
            };
            url = `${apiUrl}/${resource}?${stringify(query)}`;
            break;
        }
        case "PUSH" : {
            url = `${apiUrl}/${resource}`
            options.method = "POST"
            console.log('PARAMS : ',params)
            options.body = JSON.stringify(params);
            break
        }
        default:
            throw new Error(`Unsupported Data Provider request type ${type}`);
    }

    return fetch(url, options)
        .then(res => {
            if(res.status === 401) {
                removeAccessToken();
            }
            return res.json();
        })
        .then(json => {
            if(json.error) throw new Error("서버에러 : "+json.error)
            switch (type) {
                case GET_LIST:
                case GET_MANY_REFERENCE:
                    return {
                        data: json.result,
                        total: json.total
                    };
                default:
                    return {data: json.result};
            }
        });
};