import React from 'react';
import {Button, FormDataConsumer, SimpleForm} from 'react-admin';
import {connect} from 'react-redux';
import {push} from 'connected-react-router';
import withStyles from '@material-ui/core/styles/withStyles';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import {API_URL} from '../../config';
import {CategoriesSelectInput} from '../../products';
import {useFetch} from '../../hooks/useFetch';
import {loadAccessToken} from '../../lib/Auth';

const modalStyles = {
  dialogContent: {
    textAlign: 'center',
  },
  dialogPaperWidthMd: {
    width: '100%',
  },
  extractButton: {
    margin: '10px',
  },
};

const BulkUpdateCrawledCategoryModal = withStyles(modalStyles)(({
  classes,
  basePath,
  open,
  onClose,
  push,
  ...props
}) => {
  const data = useFetch(`${API_URL}/all_categories`);
  const all_categories = data?.result.all_categories;

  const handleOnClickSave = formData => {
    multipleUpdate(formData.category, formData.subcategory_id);
  };

  const multipleUpdate = async (category, subcategory_id) => {
    try {
      const res = await fetch(
        `${API_URL}/platform-temp-product/multiple-update`,
        {
          method: 'POST',
          headers: new Headers({
            'content-type': 'application/json',
            Authorization: `Bearer ${loadAccessToken()}`,
          }),
          body: JSON.stringify({
            ids: props.selectedIds,
            category: category,
            subcategory_id: subcategory_id,
          }),
        },
      );

      const responseData = await res.json();
      props.notify(responseData.result, {type: 'info'});

      if (res.ok) {
        props.refresh();
        props.unselectAll('platform-temp-product');
      }
    } catch (error) {
    } finally {
      onClose();
    }
  };

  const closeFn = () => {
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={closeFn}
      maxWidth="md"
      classes={{paperWidthMd: classes.dialogPaperWidthMd}}>
      <DialogTitle>카테고리 등록</DialogTitle>
      <DialogContent>
        <SimpleForm toolbar={false}>
          <FormDataConsumer>
            {({formData, ...rest}) => (
              <>
                <div>선택된 {props.selectedIds.length} 건</div>
                <CategoriesSelectInput
                  record={{}}
                  all_categories={all_categories}
                  useSize={false}
                />
                <Button
                  label="등록"
                  variant="contained"
                  onClick={() => handleOnClickSave(formData)}
                />
              </>
            )}
          </FormDataConsumer>
        </SimpleForm>
      </DialogContent>
      <DialogActions>
        <Button label="Cancel" onClick={closeFn} />
      </DialogActions>
    </Dialog>
  );
});

export default connect(null, {
  push,
})(BulkUpdateCrawledCategoryModal);
