import React from 'react';
import {useState} from 'react';
import {
    Button, FileField, FileInput,
    Loading, SimpleForm,
} from 'react-admin';
import { connect } from 'react-redux';
import {push} from 'connected-react-router';
import withStyles from '@material-ui/core/styles/withStyles';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import {API_URL} from '../config';
import {loadAccessToken} from '../lib/Auth';

const outOfAppModalStyles = {
  dialogContent: {
      textAlign: 'center'
  },
  dialogPaperWidthMd: {
      width: "100%"
  },
  extractButton: {
      margin: '10px'
  }
};

const PRODUCT_BULK_UPLOAD_URL = `${API_URL}/products/bulk`;

const BulkCreateModal = withStyles(outOfAppModalStyles)((({classes, basePath, open, onClose, push, ...props}) => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const sendFile = async (event) => {
      const formData = new FormData();
      formData.append("file", file, file.name);

      setLoading(true);
      const response = await fetch(PRODUCT_BULK_UPLOAD_URL, {
          method: 'POST',
          headers: new Headers({
              Authorization: `bearer ${loadAccessToken()}`
          }),
          body: formData
      });
      const result = await response.json();
      if(result.error) {
          alert(`[실패] 파일 양식을 다시 확인해주세요.\n${result.error}`);
      } else {
          const checkFailedResults = result.failedResults.length > 0
          const failedTitle = checkFailedResults && result.failedResults.map(result => result.title).join(',');
          alert(`${result.result.length}건이 정상적으로 등록이 되었습니다.\n${checkFailedResults && '실패 케이스: '}${failedTitle}`);
      }

      setLoading(false);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" classes={{paperWidthMd: classes.dialogPaperWidthMd}}>
        <DialogTitle>Bulk Product Upload</DialogTitle>
        <DialogContent classes={{root: classes.dialogContent}}>
            {loading ?
              <Loading loadingPrimary="Loading" loadingSecondary="waiting for extration..." />
              :
              <SimpleForm toolbar={<Button disabled={!file} variant="contained" className={classes.extractButton} label="Upload" onClick={sendFile} />}>
                <FileInput placeholder="Upload CSV File" accept=".csv" source="File" onChange={setFile}>
                    <FileField source="src" title="title"/>
                </FileInput>
              </SimpleForm>
            }
        </DialogContent>
        <DialogActions>
            <Button disabled={loading} label="Cancel" onClick={onClose} />
        </DialogActions>
    </Dialog>
  )
}))

export default connect(null, {
  push,
})(BulkCreateModal);