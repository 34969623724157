export const COUPON_TYPE = [
  {id: 'COMMON', name: 'COMMON'},
  {id: 'COLLECTION', name: 'COLLECTION'},
];

export const DISCOUNT_TYPE = [
  {id: 'AMOUNT', name: 'AMOUNT'},
  {id: 'RATE', name: 'RATE'},
];

export const STATUS = [
  {id: 'PAUSE', name: 'PAUSE'},
  {id: 'USE', name: 'USE'},
];

export const USER_FILTER = [
  {id: 'NONE', name: 'NONE'},
  {id: 'NOT_BOUGHT', name: 'NOT_BOUGHT'},
];
