import isURL from 'validator/es/lib/isURL';

export const maxValue = (max, message = 'Too big') =>
    value => value && value > max ? message : undefined;
export const minValue = (min, message = 'Too small') =>
    value => value && value < min ? message : undefined;

/* react-admin validation format */
export const validateUrl = ({allowEmpty, ...otherOptions} = {}, message = 'Invalid URL') => {
  return (value) => {
    if(allowEmpty && !value) { return undefined } // Empty is ok
    return value && isURL(value, {require_protocol: true, ...otherOptions}) ? undefined : message;
  }
}

export const BRAND_SCORE_MAX = 2;
export const BRAND_SCORE_MIN = -10;
export const validateBrandScore = [
  minValue(BRAND_SCORE_MIN, `Min value is ${BRAND_SCORE_MIN}`),
  maxValue(BRAND_SCORE_MAX, `Max value is ${BRAND_SCORE_MAX}`)
];