import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import { AutocompleteInput } from 'react-admin';
import { Field } from 'react-final-form';

import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles, createStyles } from '@material-ui/core/styles';

import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';

const OldMassiveAutocompleteInput = ({choices, defaultValue, optionValue, suggestionLimit = 100, ...props}) => {
  const [inputText, setInputText] = useState(defaultValue || '');
  const [suggestions, setSuggestions] = useState([]);
  useEffect(() => {
    const initSuggestions = () => {
      if(choices) {
        const inputLowerText = inputText.toLowerCase();
        const newSuggestions = choices.filter((choicesRow) => {
          const rowValue = choicesRow[optionValue].toLowerCase();
          return rowValue.includes(inputLowerText);
        });
        return newSuggestions.length <= suggestionLimit 
          ? newSuggestions
          : newSuggestions.concat(choices).slice(0, suggestionLimit);
      }
      return [];
    }

    setSuggestions(initSuggestions());
  }, [choices]);
  
  return <AutocompleteInput choices={suggestions} optionValue={optionValue}
            onSuggestionsFetchRequested={({value, reason}) => {
              // recommended: implementing 'debounce'
              // reason types: 'input-changed' 'suggestion-selected' 'input-focused' 'input-changed' 'suggestions-revealed' 'escape-pressed'
              if(reason === 'input-changed') { 
                const inputLowerText = value.toLowerCase();
                const newSuggestions = choices.filter((choicesRow) => {
                    const rowValue = choicesRow[optionValue].toLowerCase();
                    return rowValue.includes(inputLowerText);
                });
                setInputText(value);
                setSuggestions(newSuggestions.slice(0, suggestionLimit));
              }
            }
        } options={{ // this option to fix popper position bug
          suggestionsContainerProps: { 
            modifiers: {
              flip: {
                enabled: false,
              },
              preventOverflow: {
                enabled: true,
                boundariesElement: 'scrollParent',
              },
            }
          }
        }} {...props}/>
};


/* For reducing low performance when render massive list(suggestions) */
/** Reference 
 * react-admin:       https://marmelab.com/react-admin/doc/2.8/Inputs.html
 *                    https://marmelab.com/react-admin/doc/2.8/Inputs.html#writing-your-own-input-component
 *                    https://github.com/marmelab/react-admin/blob/v2.8.3/packages/ra-ui-materialui/src/input/AutocompleteInput.js
 * meterial-ui:       https://v1.material-ui.com/demos/autocomplete/#react-autosuggest
 * redux-form:        https://redux-form.com/6.0.0-alpha.4/docs/faq/customcomponent.md/
 *                    https://redux-form.com/6.5.0/examples/material-ui/
 * react-autosuggest: https://github.com/moroshko/react-autosuggest
 */
const styles = theme => {
  return createStyles({
    root: {
    },
    container: {
      position: 'relative',
    },
    suggestionsContainerOpen: {
      position: 'absolute',
      zIndex: 2,
      marginTop: theme.spacing.unit,
      left: 0,
      right: 0,
    },
    suggestion: {
      display: 'block',
    },
    suggestionsList: {
      margin: 0,
      padding: 0,
      listStyleType: 'none',
    },
    suggestionsPaper: {maxHeight: 240, overflow: 'auto', position: 'absolute', zIndex: 10},
    divider: {
      height: theme.spacing.unit * 2,
    },
  })
};
class MassiveAutosuggest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      suggestions: this.props.suggestions || [],
    };
  }

  getSuggestions = (value) => {
    const { suggestions = [], suggestionLimit = 100, optionValue = 'name' } = this.props;
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    let count = 0;
  
    return inputLength === 0
      ? suggestions.slice(0, suggestionLimit)
      : suggestions.filter(suggestion => {
          const keep =
          count < suggestionLimit && suggestion[optionValue].toLowerCase().slice(0, inputLength) === inputValue;
  
          if (keep) {
            count += 1;
          }
  
          return keep;
        });
  }

  getSuggestionValue = (suggestion) => {
    const { optionValue = 'name' } = this.props;
    return suggestion[optionValue];
  }

  renderSuggestion = (suggestion, { query, isHighlighted }) => {
    const { optionValue = 'name' } = this.props;
    const matches = match(suggestion[optionValue], query);
    const parts = parse(suggestion[optionValue], matches);
    
    return (
      <MenuItem selected={isHighlighted} component="div">
        <div>
          {parts.map((part, index) => {
            return part.highlight ? (
              <span key={String(index)} style={{ fontWeight: 500 }}>
                {part.text}
              </span>
            ) : (
              <strong key={String(index)} style={{ fontWeight: 300 }}>
                {part.text}
              </strong>
            );
          })}
        </div>
      </MenuItem>
    );
  }

  handleSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
    if(this.props.onSuggestionSelected) {
      this.props.onSuggestionSelected({ suggestion, suggestionValue, suggestionIndex, sectionIndex, method });
    }
  }
  handleSuggestionsFetchRequested = ({ value, reason }) => {
    this.setState({
      suggestions: this.getSuggestions(value),
    });
  };

  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  handleChange = (event, { newValue }) => {
    this.props.input.onChange(newValue);
  };

  handleFocus = () => {
    const { input } = this.props;
    input && input.onFocus && input.onFocus();
  };

  renderInputComponent = (inputProps) => {
    const { classes, inputRef = () => {}, ref, ...other } = inputProps;
  
    return (
      <TextField
        label={this.props.label}
        InputProps={{
          inputRef: node => {
            ref(node);
            inputRef(node);
          },
          classes: {
            input: classes.input,
          },
        }}
        {...other}
      />
    );
  }

  render() {
    // console.log('MAI props', this.props);
    const {
      alwaysRenderSuggestions,
      classes = {},
      isRequired,
      label,
      resource,
      source,
      className,
      options,

      /* react-admin */
      input,
      meta,

      /* custom */
      placeholder,
      ...rest
    } = this.props;

    const autosuggestProps = {
      renderInputComponent: this.renderInputComponent,
      suggestions: this.state.suggestions,
      onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
      onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
      onSuggestionSelected: this.handleSuggestionSelected,
      getSuggestionValue: this.getSuggestionValue,
      renderSuggestion: this.renderSuggestion,
      shouldRenderSuggestions: () => true
    };

    return (
      <div className={[classes.root, className].join(' ')}>
        <Autosuggest
          {...autosuggestProps}
          inputProps={{
            classes,
            placeholder: placeholder,
            value: this.props.input.value,
            onChange: this.handleChange,
            onFocus: this.handleFocus
          }}
          theme={{
            container: classes.container,
            suggestionsContainerOpen: classes.suggestionsContainerOpen,
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion,
          }}
          renderSuggestionsContainer={options => {
            return (
              <Paper {...options.containerProps} square className={classes.suggestionsPaper} >
                {options.children}
              </Paper>
            )
          }}
        />
      </div>
    );
  }
}

MassiveAutosuggest.propTypes = {
  classes: PropTypes.object.isRequired,
  suggestions: PropTypes.array.isRequired
};

const MassiveAutocomplete = withStyles(styles)(MassiveAutosuggest);
const MassiveAutocompleteReactAdminAdapter = ({source, ...others}) => {
  return (
    <span>
      <Field name={source} component={MassiveAutocomplete} {...others}/>
    </span>
  );
}

export {
  MassiveAutocomplete as StandaloneMassiveAutocomplete
};
export default MassiveAutocompleteReactAdminAdapter;