import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {BrowserRouter as Router} from 'react-router-dom'
import {Provider} from 'react-redux'
import App from './App';
import * as serviceWorker from './serviceWorker';
import {getServerMode} from './utils/util';

ReactDOM.render(
    <Router>
        <App/>
    </Router>
    , document.getElementById('root'));

// Set Site Header
const defaultTitle = document.title;
const simplifiedHostname = getServerMode();
document.title = `${defaultTitle} [${simplifiedHostname}]`;

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

