import React, { useState } from 'react';
import {
    Button,
    DateTimeInput,
    DeleteButton,
    Edit,
    FormDataConsumer,
    ImageField,
    ImageInput,
    Labeled,
    NumberInput,
    SaveButton,
    SimpleForm,
    TextInput,
    required,
    Toolbar,
    FunctionField,
    TextField,
    BooleanInput
} from "react-admin"
import { useForm } from 'react-final-form';

import { CollectionSellerList } from './SellerCollectionCreate';
import { CollectionSellerAdderModal } from './SellerCollectionCreate';
import {getOptimizedImageUrl, getResizedURL} from '../utils/util';
import useForceUpdate from '../hooks/useForceUpdate';
import { sellerCollectionUrl } from '../lib/url';
import { WEBAPP_URL } from '../config';

const styles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    collectionSellerFrame: {
        width: '100%'
    },
}

/**
 * Sub-component for a toolbar.
 * @param props the react admin Toolbar component properties
 * @return {JSX.Element}
 * @constructor
 */
function CustomToolbar(props) {
    return (
        <Toolbar {...props} style={styles.toolbar}>
            <SaveButton undoable={false} />
            <DeleteButton undoable={true} />
        </Toolbar>)
};

/**
 * Component for editing a seller collection.
 * @param props the react admin Edit component properties
 * @return {JSX.Element}
 * @constructor
 */
export default function SellerCollectionEdit(props) {
    const [isOpenSellerAdderModal, setIsOpenSellerAdderModal] = useState(false);
    const forceUpdate = useForceUpdate();

    return (
        <Edit {...props}>
            <SimpleForm action={false} toolbar={<CustomToolbar />}>
                <TextInput source="id" disabled />
                <TextInput multiline source="title" label="Title" />
                <DateTimeInput source="featured_from" lael="Featured From" validate={required()} />
                <DateTimeInput source="featured_until" lael="Featured Until" />
                <NumberInput source="priority" label="Priority" validate={required()} />
                <TextInput source="tab_name" label="Tab Name" validate={required()} />
                <TextInput source="sub_title" label="Subtitle" />
                <TextInput multiline source="description" label="Description" />
                <BooleanInput source="is_random" defaultValue={false} />
                <ImageInput source="image" accept="image/*" multiple={false} format={image => {
                    if (image && image.url) {
                        const url = image.url;
                        const isBlobUrl = typeof url === 'string' && url.match(/^blob:/);
                        return isBlobUrl ? { url } : { url: getResizedURL(url, 'width620') };
                    } else {
                        return image;
                    }
                }}>
                    <ImageField source="url" />
                </ImageInput>
                <ImageInput source="icon_image" accept="image/*" multiple={false} validate={required()} format={image => {
                    if (image && image.url) {
                        const url = image.url;
                        const isBlobUrl = typeof url === 'string' && url.match(/^blob:/);
                        return isBlobUrl ? { url } : { url: getResizedURL(url, 'width620') };
                    } else {
                        return image;
                    }
                }}>
                    <ImageField source="url" />
                </ImageInput>
                <TextField label="Icon url" source="resizedSmallIconImage.url" />
                <FormDataConsumer>
                    {({ formData }) => formData.image?.url !== undefined && (
                        <NumberInput source="aspect_ratio" step={0.0000000000000001} validate={required()} />
                    )}
                </FormDataConsumer>

                <br/>
                <h3>셀러뱃지</h3>
                <BooleanInput source="is_badge" defaultValue={false} />
                <TextInput multiline source="short_desc" label="Short Description" />
                <ImageInput label="icon(svg only)" source="icon" accept="image/svg+xml" multiple={false} format={image => {
                    if (image && image.url) {
                        const url = image.url;
                        const isBlobUrl = typeof url === 'string' && url.match(/^blob:/);
                        return isBlobUrl ? { url } : { url: getOptimizedImageUrl(url) };
                    } else {
                        return image;
                    }
                }}>
                    <ImageField source="url" />
                </ImageInput>
                <br/>

                <Labeled label="Seller Collection Sellers" />
                <Button variant="contained" label="ADD SELLERS" onClick={() => setIsOpenSellerAdderModal(true)} />
                <FormDataConsumer>
                    {({ formData }) => {
                        const form = useForm();
                        return (<CollectionSellerAdderModal open={isOpenSellerAdderModal} onAdd={(newAddedSellers) => {
                            const newMySeller = [...newAddedSellers];
                            formData.mySeller && newMySeller.push(...formData.mySeller)
                            const nonDuplicatedSellers = _.uniqBy(newMySeller, 'id');
                            form.change('mySeller', nonDuplicatedSellers)
                            forceUpdate();
                        }}
                            onClose={() => setIsOpenSellerAdderModal(false)}
                        />)
                    }}
                </FormDataConsumer>
                <FormDataConsumer>
                    {({ formData }) => {
                        const form = useForm();
                        const onDeleteSeller = (toBeDeletedSeller) => {
                            const exceptedMe = formData.mySeller.filter((seller) => seller.id !== toBeDeletedSeller.id);
                            form.change('mySeller', exceptedMe);
                        }
                        return (
                            <CollectionSellerList style={styles.collectionSellerFrame} sellers={formData.mySeller || []} onDeleteSeller={onDeleteSeller} />
                        )
                    }}
                </FormDataConsumer>

                {/* Webapp and content feed links */}
                <FunctionField
                    label="Deep Link"
                    render={record => (
                        <a href={sellerCollectionUrl(WEBAPP_URL, record)} target="_blank">
                            {sellerCollectionUrl(WEBAPP_URL, record).toString()}
                        </a>
                    )}
                />
            </SimpleForm>
        </Edit>
    )
}
