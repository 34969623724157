import React from 'react';
import {
  AutocompleteInput,
  BooleanField,
  Datagrid,
  DateField,
  Filter,
  List,
  ReferenceField,
  ReferenceInput,
  TextField,
  TextInput,
} from 'react-admin';
import {withStyles} from '@material-ui/core';
import UserLinkField from '../components/UserLinkField';

// component styling
const styles = {};

const rowStyle = (record, index, defaultStyle = {}) => {
  if (!record.is_active)
    return { ...defaultStyle, backgroundColor: '#808080' };
  return defaultStyle;
};

function ReferralCodeFilter(props) {
  return (
    <Filter {...props}>
      <ReferenceInput
        label="추천인"
        source="user_id"
        reference="users"
        alwaysOn>
        <AutocompleteInput optionText="username" />
      </ReferenceInput>
      <TextInput label="초대코드" source="code" alwaysOn />
    </Filter>
  );
}
/**
 * Component for a list of referral codes.
 * @param classes
 * @param props the react admin List component properties
 * @return {JSX.Element}
 * @constructor
 */
function ReferralCodeList({classes, ...props}) {
  // build the UI elements
  return (
    <List
      {...props}
      bulkActionButtons={false}
      filters={<ReferralCodeFilter />}
      sort={{field: 'id', order: 'DESC'}}>
      <Datagrid rowClick={'show'} rowStyle={rowStyle}>
        <TextField source="id" />
        <ReferenceField
          label="추천인"
          source="user_id"
          reference="users"
          link="show">
          <UserLinkField />
        </ReferenceField>
        <TextField label="초대 코드" source="code" />
        <BooleanField label="직접 발행 여부" source="is_custom" />
        <DateField label="생성일" source="created_at" showTime />
        <BooleanField label="사용 가능 여부" source="is_active" />
      </Datagrid>
    </List>
  );
}

export default withStyles(styles)(ReferralCodeList);
