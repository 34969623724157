import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    SelectInput,
    TextInput,
    Filter,
    ReferenceField,
    DateField,
    DateInput,
} from 'react-admin';
import withStyles from "@material-ui/core/styles/withStyles";
import rowStyle from '../orders/rowStyle';
import UserLinkField from '../components/UserLinkField';

export const statusChoices = [
    { value: 'completed', name: 'Completed' },
    { value: 'cancelled', name: 'Cancelled' },
    { value: 'settled', name: 'Settled' },
]

// component styling
const styles = {
    image: {
        '& img': {
            width: 80,
            height: 106.6667, // same 3 by 4 aspect ratio as in the mobile app
            objectFit: 'cover'
        }
    },
    description: {
        display: 'block',
        maxWidth: 300,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    }
};

/**
 * Sub-component for filtering a list of offlineOrders.
 * @param props the react admin Filter component properties
 * @return {JSX.Element}
 * @constructor
 */
function OfflineOrderFilter(props) {
    return (
        <Filter {...props}>
            <TextInput source='purchase_id' label="Purchase id" alwaysOn />
            <DateInput source='created_at' alwaysOn />
            <TextInput source="product_name" alwaysOn />
            <TextInput label="seller_id" source="seller_id" alwaysOn />
            <TextInput label="seller name" source="seller_name" alwaysOn />
            <TextInput label="buyer_id" source="buyer_id" alwaysOn />
            <TextInput label="buyer name" source="buyer_name" alwaysOn />
            <SelectInput source="status" choices={statusChoices} optionValue="value" allowEmpty={true} alwaysOn />
        </Filter>
    );
}

/**
 * Component for a list of offlineOrders.
 * @param props the react admin List component properties
 * @return {JSX.Element}
 * @constructor
 */
function OfflineOrderList({ classes, ...props }) {
    // build the UI elements
    return (
        <List {...props} filters={<OfflineOrderFilter />} sort={{ field: 'id', order: 'DESC' }}>
            <Datagrid rowClick="edit" rowStyle={rowStyle}>
                <TextField source="id" />
                <TextField label="purchase_id" source="purchase_id" />
                <TextField label="product name" source="product_name" />
                <ReferenceField label="Seller" source="seller_id" reference="users" link="show">
                    <UserLinkField />
                </ReferenceField>
                <ReferenceField label="Buyer" source="buyer_id" reference="users" link="show">
                    <UserLinkField />
                </ReferenceField>
                <TextField label="price" source="price" />
                <TextField label="status" source="status" />
                <DateField label="paid At" source="created_at" showTime="true" />
                <TextField label="cancelled by" source="cancelled_by" />
                <DateField label="cancelled At" source="cancelled_at" showTime="true" />
            </Datagrid>
        </List>
    );
}

export default withStyles(styles)(OfflineOrderList);