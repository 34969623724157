function saveAccessToken(accessToken) {
  return localStorage.setItem('access_token', accessToken);
}

function loadAccessToken() {
  return localStorage.getItem('access_token');
}

function removeAccessToken() {
  return localStorage.removeItem('access_token');
}

function saveUsername(username) {
  return localStorage.setItem('username', username);
}

function loadUsername() {
  return localStorage.getItem('username');
}

function removeUsername() {
  return localStorage.removeItem('username');
}

export {
  saveAccessToken,
  loadAccessToken,
  removeAccessToken,
  saveUsername,
  loadUsername,
  removeUsername
};
