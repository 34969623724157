// in src/users.js
import React, {Fragment, Component} from 'react';
import {Route} from 'react-router';
import {
    List,
    Datagrid,
    TextField,
    EmailField,
    TextInput,
    maxLength,
    minLength,
    Filter,
    Edit,
    ShowButton,
    SimpleForm,
    DateField,
    BooleanField,
    RichTextField,
    ArrayInput,
    SimpleFormIterator,
    ReferenceInput,
    SelectInput,
    EditController,
    SaveButton,
    Toolbar,
    CreateController,
    Create,
    NumberInput,
    BulkDeleteButton,
    ImageInput,
    ImageField,
    AutocompleteInput,
    ReferenceArrayInput,
    AutocompleteArrayInput,
    ArrayField,
    SingleFieldList,
    ChipField,
    FunctionField
} from 'react-admin';

import { validateBrandScore } from "../lib/validator";
import { getResizedURL } from '../utils/util';
import { withStyles } from '@material-ui/core';
import { brandUrl } from '../lib/url';
import { WEBAPP_URL } from '../config';

// component styling
const styles = {
    image: {
        '& img': {
            width: 80,
            height: 80,
            objectFit: 'contain'
        }
    }
};

const BrandFilter = (props) => (
    <Filter {...props}>
        <TextInput label="name" source="name" alwaysOn/>
        <TextInput label="name_kr" source="name_kr" alwaysOn/>
        <ReferenceInput source="style_id" reference="styles" label="Style" alwaysOn>
            <SelectInput optionText="name"/>
        </ReferenceInput>
    </Filter>
)

const CustomToolbar = props => (
    <Toolbar {...props}>
        <SaveButton disabled={props.pristine} undoable={false}/>
    </Toolbar>
);
export const BrandList = withStyles(styles)(({classes, ...props}) => (
    <List {...props} bulkActionButtons={<BulkDeleteButton undoable={false}/>}  filters={<BrandFilter/>} sort={{field: 'id', order: 'DESC'}}>
        <Datagrid
            rowClick="edit"
        >
            <TextField source="name"/>
            <TextField source="name_kr"/>
            <ImageField source="resizedSmallLogo" label="Logo" sortable={false} className={classes.image}/>
            <ArrayField source="styles" sortable={false}>
                <SingleFieldList linkType={false}>
                    <ChipField source="name" />
                </SingleFieldList>
            </ArrayField>
            <TextField source="score" />
        </Datagrid>
    </List>
))

export const BrandEdit = props => (
    <Edit {...props} undoable={false}>
        <SimpleForm toolbar={<CustomToolbar/>}>
            <TextInput source="name"/>
            <TextInput source="name_kr"/>
            <ReferenceArrayInput source="style_ids" label="Styles" reference="styles" allowEmpty>
                <AutocompleteArrayInput/>
            </ReferenceArrayInput>
            <TextInput multiline source="description" validate={[minLength(1), maxLength(65535)]}/>
            <ImageInput source="logo" accept="image/jpeg,image/png,image/svg+xml" multiple={false} format={image => {
                if (typeof image === 'string') {
                    return image.match(/^blob:/) ? {url: image} : {url: getResizedURL(image, 'width620')};
                } else {
                    return image;
                }
            }}>
                <ImageField source="url"/>
            </ImageInput>
            <NumberInput label="score" source="score" validate={validateBrandScore}/>

            {/* Webapp and content feed links */}
            <FunctionField
                label="Deep Link"
                render={record => (
                    <a href={brandUrl(WEBAPP_URL, record.name)} target="_blank">
                        {brandUrl(WEBAPP_URL, record.name).toString()}
                    </a>
                )}
            />
            <FunctionField
                label="Atom Feed"
                render={record => (
                    <a href={brandUrl(WEBAPP_URL, record.name) + '/feed.atom'} target="_blank">
                        {brandUrl(WEBAPP_URL, record.name) + '/feed.atom'}
                    </a>
                )}
            />
            <FunctionField
                label="Google Shopping Feed"
                render={record => (
                    <a href={brandUrl(WEBAPP_URL, record.name) + '/googleshopping.xml'} target="_blank">
                        {brandUrl(WEBAPP_URL, record.name) + '/googleshopping.xml'}
                    </a>
                )}
            />
        </SimpleForm>
    </Edit>
)

export const BrandCreate = props => (
    <Create {...props}>
        <SimpleForm toolbar={<CustomToolbar/>} redirect="list">
            <ArrayInput
                label="name"
                source="list">
                <SimpleFormIterator disableReordering>
                    <TextInput source="name" label="Name"/>
                    <TextInput source="name_kr" label="Name Kr"/>
                    <ReferenceInput source="style_id" label="Style" reference="styles">
                        <AutocompleteInput />
                    </ReferenceInput>
                    <TextInput multiline source="description" label="Description" validate={[minLength(1), maxLength(65535)]}/>
                    <NumberInput label="score" source="score" validate={validateBrandScore}/>
                </SimpleFormIterator>
            </ArrayInput>
        </SimpleForm>
    </Create>
)
