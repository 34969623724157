import React from 'react';
import Chip from '@material-ui/core/Chip';
import {translate} from 'react-admin';

const styles = {
    main: {display: 'flex', flexWrap: 'wrap'},
    chip: {margin: 4},
};

const SizeField = ({record}) => {
    return (
        <span style={styles.main}>
        {
            record.size_values && record.size_values.map(keyword =>
                <Chip
                    key={keyword.name ? keyword.name : keyword}
                    style={styles.chip}
                    label={keyword.name ? keyword.name : keyword}
                />
            )
        }
    </span>
    );
}


SizeField.defaultProps = {
    addLabel: true,
    source: "keywords"
};

export default SizeField
