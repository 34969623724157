import {withStyles} from "@material-ui/core";
import {
    Datagrid, DateField, DateInput, Filter,
    FunctionField,
    List,
    ReferenceField, SelectInput, ShowButton,
    TextField, TextInput
} from "react-admin";
import React from "react";
import UserLinkField from "../components/UserLinkField";

export const OTHER_PLATFORM = [
    { id: 'bunjang', name: '번개장터' },
    { id: 'joongna', name: '중고나라' },
    { id: 'smartstore', name: '스마트스토어' },
];

export const platformApplyStatusChoices = [
    {id: 'pending', name: 'pending'},
    {id: 'progress', name: 'progress'},
    {id: 'completed', name: 'completed'},
    {id: 'rejected', name: 'rejected'},
    {id: 'failed', name: 'failed'},
    {id: 'approved', name: 'approved'},
    {id: 'uploaded', name: 'uploaded'},
];
// component styling
const styles = {

}

function BulkProductFilter(props) {
    return (
        <Filter {...props}>
            <TextInput label="후루츠셀러ID" source="seller_id" alwaysOn />
            <TextInput label="셀러 네임" source="seller_name" alwaysOn />
            <SelectInput source="platform" choices={OTHER_PLATFORM} alwaysOn />
            <SelectInput source="status" choices={platformApplyStatusChoices} alwaysOn />
            <DateInput source="scheduled_start" label='스케쥴 시작'  alwaysOn/>
            <DateInput source="scheduled_end" label='스케쥴 종료' alwaysOn/>
        </Filter>
    )

}

function BulkProductList({ classes, ...props }) {
    return (
        <List {...props} filters={<BulkProductFilter />} sort={{field: 'id', order: 'DESC'}}>
            <Datagrid rowClick={"edit"}>
                <TextField source="id" />
                <ReferenceField label="Seller" source="seller_id" reference="users" link="show">
                    <UserLinkField/>
                </ReferenceField>
                <FunctionField
                    label="플랫폼명"
                    render={record => {
                        const platform = OTHER_PLATFORM.find(platform => platform.id === record.platform);
                        return platform ? platform.name : record.platform;
                    }}
                />
                <TextField source="status" label='진행상태'/>
                <DateField source="applied_at" label='등록 시간' showTime />
                <DateField source="scheduled_start" label='스케쥴 시작' showTime />
                <DateField source="scheduled_end" label='스케쥴 종료' showTime />
                <ShowButton />
            </Datagrid>
    </List>
    )
}

export default withStyles(styles)(BulkProductList);
