// in src/users.js
import React, { Fragment, Component } from 'react';
import { Route } from 'react-router';
import Drawer from '@material-ui/core/Drawer';
import {
    List,
    Datagrid,
    TextField,
    TextInput,
    Filter,
    AutocompleteInput,
    ReferenceInput,
    ReferenceField,
    DateField,
    RichTextField
} from 'react-admin';
import StarRatingField from '../components/StarRatingField'
import ReviewEdit from "./ReviewEdit"

const ReviewFilter = (props) => (
    <Filter {...props}>
        <ReferenceInput source="user_id" reference="users" label="구매자 유저네임" alwaysOn >
            <AutocompleteInput optionText="username"/>
        </ReferenceInput>
        <ReferenceInput source="product_id" reference="products" alwaysOn label={"상품 제목"}>
            <AutocompleteInput optionText="title"/>
        </ReferenceInput>
        <TextInput source="product_id" alwaysOn label={'상품 아이디'}/>
        <TextInput source="text" alwaysOn label={'리뷰 내용'}/>
    </Filter>
);

class _ReviewList extends Component {
    render() {
        const props = this.props;
        return (
            <Fragment>
                <List
                    {...props}
                    filters={<ReviewFilter />}
                    perPage={25}
                    sort={{field: 'id', order: 'DESC'}}
                >
                    <Datagrid
                        rowClick="edit"
                    >
                        <TextField source="id"/>
                        <ReferenceField
                            reference="users"
                            source="user_id"
                            label="구매자 유저네임"
                            link="show">
                            <TextField source="username"/>
                        </ReferenceField>
                        <ReferenceField
                            reference="products"
                            source="product_id"
                            label="상품 제목"
                            link="edit">
                            <TextField source="title"/>
                        </ReferenceField>
                        <StarRatingField label="별점"/>
                        <RichTextField label="리뷰 내용" source="text"/>
                        <DateField label="작성일" source="createdAt"/>
                    </Datagrid>
                </List>
                <Route path="/reviews/:id">
                    {({history,match}) => {
                        const isMatch = match && match.params && match.params.id
                        const id = (match && match.params) ? match.params.id : null
                        return (
                            <Drawer
                                variant="persistent"
                                open={isMatch}
                                anchor="right"
                                onClose={this.handleClose}
                            >
                                {/* To avoid any errors if the route does not match, we don't render at all the component in this case */}
                                {isMatch ? (
                                    <ReviewEdit
                                        id={id}
                                        onCancel={() => this.handleClose(history)}
                                        {...props}
                                    />
                                ) : null}
                            </Drawer>
                        );
                    }}
                </Route>
            </Fragment>
        );
    }

    handleClose = (history) => {
        history.push('/reviews');
    };
}

export const ReviewList = _ReviewList
