import React from 'react';
import {
  AutocompleteInput,
  Datagrid,
  DateField,
  DateInput,
  Filter,
  List,
  ReferenceField,
  ReferenceInput,
  TextField,
} from 'react-admin';
import {withStyles} from '@material-ui/core';
import UserLinkField from '../components/UserLinkField';

// component styling
const styles = {};

function RecommendStickerFilter(props) {
  return (
    <Filter {...props}>
      <ReferenceInput
        label="추천인"
        source="user_id"
        reference="users"
        alwaysOn>
        <AutocompleteInput optionText="username" />
      </ReferenceInput>
      <DateInput source="createdAt_from" label="시작일" alwaysOn />
      <DateInput source="createdAt_until" label="종료일" alwaysOn />
    </Filter>
  );
}
/**
 * Component for a list of referral codes.
 * @param classes
 * @param props the react admin List component properties
 * @return {JSX.Element}
 * @constructor
 */
function RecommendStickerList({classes, ...props}) {
  // build the UI elements
  return (
    <List
      {...props}
      bulkActionButtons={false}
      filters={<RecommendStickerFilter />}
      sort={{field: 'id', order: 'DESC'}}>
      <Datagrid rowClick={'show'}>
        <TextField source="id" />
        <ReferenceField
          label="추천인"
          source="from_user_id"
          reference="users"
          link="show">
          <UserLinkField />
        </ReferenceField>
        <TextField label="발급 조건" source="action_type" />
        <TextField label="사용 여부" source="status" />
        <ReferenceField
          label="친구"
          source="recommendee.user_id"
          reference="users"
          link="show">
          <UserLinkField />
        </ReferenceField>
        <ReferenceField
          label="주문 정보"
          source="order_id"
          reference="orders"
          link="show">
          <TextField source="product.title" />
        </ReferenceField>
        <DateField label="생성일" source="created_at" showTime />
      </Datagrid>
    </List>
  );
}

export default withStyles(styles)(RecommendStickerList);
