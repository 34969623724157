import {API_URL} from '../config';
import {loadAccessToken} from '../lib/Auth'

/**
 * Return paginated items in array
 * */
export const paginate = (array, page, perPage) => {
  const startOffset = page * perPage;
  const paginatedArray = array.slice(startOffset, (page + 1) * perPage);
  return paginatedArray;
}

export const downloadImage = async (imageUrl, imageName) => {
  const isS3UploadedImage = imageUrl.includes(getImageServerUrl());
  if(isS3UploadedImage) {
    await downloadImageFromServer(imageUrl, imageName)
  } else {
    downloadOrLinkURI(imageUrl, imageName)
  }
}

export const downloadImageFromServer = async (imageUrl, fileName) => {
  const res = await fetch(`${API_URL}/download/image/origin?imageUrl=${imageUrl}`, {
    method: 'GET',
    headers: new Headers({
      Authorization: `bearer ${loadAccessToken()}`
    }),
  });

  if(!res.ok) {
    const error = await res.json();
    const message = error?.message || JSON.stringify(error);
    return alert(message);
  }

  const blob = await res.blob();
  const blobUrl = URL.createObjectURL(blob);
  downloadOrLinkURI(blobUrl, fileName)
}

export const downloadOrLinkURI = (uri, name) => {
    const link = document.createElement("a");
    link.href = uri;
    link.setAttribute('download', name);
    // if uri is external url sometime cannot download image, but open link on new tab instead
    link.setAttribute('target', '_blank');
    link.setAttribute('rel', 'noopener noreferrer');

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export const textEllipsis = (text, maxLength = 80, symbol = "...") => {
  if(text.length <= maxLength) {
      return text;
  }

  const omittedText = `${text.substring(0, maxLength)}${symbol}`
  return omittedText;
}

// for cache server mode
let serverMode;
export const getServerMode = () => {
  if(serverMode) {
    return serverMode;
  }
  // localhost, staging, production
  const serverModeByUrl = window.location.hostname.replace(/pikil-admin-page\.([^.]+)\.fruitsfamily\.com/i, '$1');
  serverMode = serverModeByUrl;
  return serverMode;
}

export const getImageServerUrl = () => {
  const serverMode = getServerMode();
  let subDomain
  switch(serverMode) {
    case 'localhost':
    case 'staging':
      subDomain = 'staging';
      break;
    case 'production':
      subDomain = 'production';
      break;
    default:
      throw Error('Server Mode by url is wrong. (To be localhost, staging or production)') ;
  }

  return `https://image.${subDomain}.fruitsfamily.com`;
}

export const getResizedURL = (url,size) => {
  if(!url) return ''
  if(!url.includes('origin/')) return url;

  const newUrl = new URL(getImageServerUrl());
  newUrl.pathname = new URL(url).pathname.replace('/private/', '/public/').replace('/origin/',`/resized%40${size}/`); //%40 = @
  return newUrl.toString();
}

export const getOptimizedImageUrl = (url) => {
  if(!url) return ''
  if(!url.includes('origin/')) return url;

  const newUrl = new URL(getImageServerUrl());
  newUrl.pathname = new URL(url).pathname.replace('/private/', '/public/').replace('/origin/',`/optimized/`); //%40 = @
  return newUrl.toString();
}

export const convertCommaNumber = (param) => {
  if (param === 0) return "0";
  if (!param) return "";

  return `${param
      .toString()
      .replace(/,/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
};
