const {seoURIComponent} = require('./text');

/**
 * Generate a canonical url for a brand.
 * @param baseUrl the server base url
 * @param brandName the brand name
 * @return {URL} the discover product url for the given brand
 */
function brandUrl(baseUrl, brandName) {
    return new URL(
        `${baseUrl}brand/${encodeURIComponent(brandName)}`
    );
}

/**
 * Generate a canonical url for a collection.
 * @param baseUrl the server base url
 * @param collection the collection
 * @return {URL} the url for the given collection
 */
function collectionUrl(baseUrl, collection) {
    const collectionId = parseInt(collection.id, 10);

    return new URL(
        `${baseUrl}collection/${encodeURIComponent(collectionId.toString(36))}/${seoURIComponent(collection.title)}`
    );
}

/**
 * Generate a canonical url for a category.
 * @param baseUrl the server base url
 * @param category the category
 * @return {URL} the discover product url for the given category
 */
function discoverProductUrl(baseUrl, category) {
    return new URL(
        `${baseUrl}discover/product/${encodeURIComponent(category.name)}`
    );
}

/**
 * Generate a canonical url for a post.
 * @param baseUrl the server base url
 * @param post the post
 * @return {URL} the url for the given post
 */
 function postUrl(baseUrl, post) {
    const postId = parseInt(post.id, 10);

    return new URL(
        `${baseUrl}post/${encodeURIComponent(postId.toString(36))}`
    );
}

/**
 * Generate a canonical url for a product.
 * @param baseUrl the server base url
 * @param product the product
 * @return {URL} the url for the given product
 */
function productUrl(baseUrl, product) {
    const productId = parseInt(product.id, 10);

    return new URL(
        `${baseUrl}product/${encodeURIComponent(productId.toString(36))}/${seoURIComponent(product.title)}`
    );
}

/**
 * Generate a canonical url for a seller.
 * @param baseUrl the server base url
 * @param seller the seller
 * @return {URL} the url for the given seller
 */
function sellerUrl(baseUrl, seller) {
    const sellerId = parseInt(seller.id, 10);

    return new URL(
        `${baseUrl}seller/${encodeURIComponent(sellerId.toString(36))}/${seoURIComponent(seller.user.username)}`
    );
}

/**
 * Generate a canonical url for a style.
 * @param baseUrl the server base url
 * @param style the style
 * @return {URL} the url for the given style
 */
 function styleUrl(baseUrl, style) {
    const styleId = parseInt(style.id, 10);

    return new URL(
        `${baseUrl}style/${encodeURIComponent(styleId.toString(36))}/${seoURIComponent(style.name)}`
    );
}

/**
 * Generate a canonical url for a sellerCollection.
 * @param baseUrl the server base url
 * @param sellerCollection the sellerCollection
 * @return {URL} the url for the given sellerCollection
 */
 function sellerCollectionUrl(baseUrl, sellerCollection) {
     const sellerCollectionId = parseInt(sellerCollection.id, 10);

    return new URL(
        `${baseUrl}sellertag/${encodeURIComponent(sellerCollectionId.toString(36))}`
    );
}

exports.brandUrl = brandUrl;
exports.collectionUrl = collectionUrl;
exports.sellerCollectionUrl = sellerCollectionUrl;
exports.discoverProductUrl = discoverProductUrl;
exports.postUrl = postUrl;
exports.productUrl = productUrl;
exports.sellerUrl = sellerUrl;
exports.styleUrl = styleUrl;
