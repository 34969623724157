import {
  AutocompleteInput,
  Datagrid,
  DateField,
  DateTimeInput,
  Filter,
  List,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
  TextInput,
} from 'react-admin';
import React from 'react';
import UserLinkField from '../components/UserLinkField';
import {useCheckMessagePermission} from '../hooks/useCheckMessagePermission';

const READ_STATUS_CHOICES = [
  {id: true, name: '읽음'},
  {id: false, name: '안읽음'},
];

const MESSAGE_TYPE_CHOICES = [
  {id: true, name: '시스템 메시지'},
  {id: false, name: '일반 메시지'},
];

function MessageFilter(props) {
  return (
    <Filter {...props}>
      <DateTimeInput source="createdAt_start" label="검색 시작 시간" alwaysOn />
      <DateTimeInput source="createdAt_end" label="검색 종료 시간" alwaysOn />
      <TextInput source="room_id" label="방 번호" alwaysOn />
      <ReferenceInput
        source="from_id"
        reference="users"
        label="보낸사람"
        alwaysOn>
        <AutocompleteInput optionText="username" />
      </ReferenceInput>
      <ReferenceInput
        source="to_id"
        reference="users"
        label="받은사람"
        alwaysOn>
        <AutocompleteInput optionText="username" />
      </ReferenceInput>
      <SelectInput
        source="is_read"
        label="읽음 여부"
        choices={READ_STATUS_CHOICES}
        alwaysOn
      />
      <SelectInput
        source="is_system_message"
        label="시스템 메시지 여부"
        choices={MESSAGE_TYPE_CHOICES}
        alwaysOn
      />
    </Filter>
  );
}

function MessageList(props) {
  const {isAllowed} = useCheckMessagePermission();

  if (!isAllowed) return null;

  return (
    <List
      {...props}
      filters={<MessageFilter />}
      sort={{field: 'id', order: 'DESC'}}
      bulkActionButtons={false}>
      <Datagrid>
        <TextField source="id" />
        <TextField source="text" label="메세지" />
        <TextField source="room_id" />
        <ReferenceField source="from_id" reference="users" label="보낸사람">
          <UserLinkField />
        </ReferenceField>
        <ReferenceField source="to_id" reference="users" label="받은사람">
          <UserLinkField />
        </ReferenceField>
        <DateField source="createdAt" label="발송시간" showTime={true} />
      </Datagrid>
    </List>
  );
}

export default MessageList;
