import React, {Fragment, useState} from 'react';
import {
    Button,
    CreateButton,
    Datagrid,
    DateField,
    DateInput, ExportButton,
    Filter,
    ImageField,
    List,
    NumberField,
    SelectInput,
    TextField,
    TextInput, TopToolbar
} from 'react-admin';
import {withStyles} from '@material-ui/core';
import BannerLogoModal from '../components/BannerLogoModal';

// component styling
const styles = {
    image: {
        '& img': {
            maxWidth: 270,
            maxHeight: 90,
            backgroundColor: 'lightgrey',
            backgroundSize: '4px 4px',
            backgroundPosition: '0 0, 2px 2px',
            backgroundImage: 'linear-gradient(45deg, grey 25%, transparent 25%, transparent 75%, grey 75%, grey), linear-gradient(45deg, grey 25%, transparent 25%, transparent 75%, grey 75%, grey)'
        }
    }
};

/**
 * Sub-component for filtering a list of sections.
 * @param props the react admin Filter component properties
 * @returns {JSX.Element}
 * @constructor
 */
function SectionFilter(props) {
    return (
        <Filter {...props}>
            <DateInput source="activeAt" alwaysOn />
            <SelectInput
                source="type"
                choices={[
                    {id: 'BANNER', name: 'BANNER'},
                    {id: 'BRAND', name: 'BRAND'},
                    {id: 'COLLECTION', name: 'COLLECTION'},
                    {id: 'COLLECTION_PRODUCT', name: 'COLLECTION_PRODUCT'},
                    {id: 'FOLLOWING', name: 'FOLLOWING'},
                    {id: 'PRODUCT', name: 'PRODUCT'},
                    {id: 'SHORTCUT', name: 'SHORTCUT'},
                    {id: 'STYLE', name: 'STYLE'},
                    {id: 'RECENTLY_PRODUCT', name: 'RECENTLY_PRODUCT'}
                ]}
                alwaysOn
            />
            <SelectInput
                source="device"
                choices={[
                    {id: 'MOBILE', name: 'MOBILE'},
                    {id: 'WEB', name: 'WEB'}
                ]}
                alwaysOn
            />
            <TextInput source="title" alwaysOn />
            <TextInput source="description" alwaysOn />
        </Filter>
    );
}

/**
 * Component for a list of sections.
 * @param props the react admin List component properties
 * @return {JSX.Element}
 * @constructor
 */
function SectionList({classes, ...props}) {
    const [isLogoModalOpen, setIsLogoModalOpen] = useState(false);

    // build the UI elements
    return (
        <Fragment>
        <List bulkActionButtons={false} filters={<SectionFilter />} sort={{field: 'featuredUntil', order: 'DESC'}}
              actions={
                  <TopToolbar>
                      <Button label="Banner Logo 등록" style={{color: 'red'}} onClick={() => setIsLogoModalOpen(true)}></Button>
                      <CreateButton basePath={props.basePath}/>
                      <ExportButton props/>
                  </TopToolbar>
              }
              {...props}>
            <Datagrid rowClick="edit">
                <DateField source="featuredFrom" />
                <DateField source="featuredUntil" />
                <TextField source="type" />
                <TextField source="title" />
                <TextField source="description" />
                <ImageField source="image" className={classes.image} />
                <ImageField source="backgroundImage" className={classes.image} />
                <TextField source="device" />
                <NumberField source="position" />
                <NumberField source="priority" />
            </Datagrid>
        </List>
            <BannerLogoModal open={isLogoModalOpen} onClose={() => setIsLogoModalOpen(false)}/>
        </Fragment>
    );
}

export default withStyles(styles)(SectionList);
