import React from 'react';
import {
    BooleanInput,
    DateField,
    Edit,
    EmailField,
    ReferenceField,
    required,
    SimpleForm
} from 'react-admin';

import UserLinkField from "../components/UserLinkField";

/**
 * Component for editing an admin user.
 * @param props the react admin Edit component properties
 * @return {JSX.Element}
 * @constructor
 */
export default function UserAdminEdit(props) {
    return (
        <Edit {...props}>
            <SimpleForm actions={false}>
                <ReferenceField source="user_id" reference="users">
                    <UserLinkField/>
                </ReferenceField>
                <ReferenceField label="Email" source="user_id" reference="users">
                    <EmailField source="email"/>
                </ReferenceField>
                <BooleanInput source="is_admin" validate={required()}/>
                <BooleanInput source="is_system" validate={required()}/>
                <DateField source="createdAt" showTime/>
                <DateField source="updatedAt" showTime/>
            </SimpleForm>
        </Edit>
    );
}
