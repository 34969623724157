import React, {Fragment, Component} from 'react';
import {useState} from 'react';
import {
    Button,
    CreateButton,
    Loading,
    useLocale
} from 'react-admin';
import { connect } from 'react-redux';
import {push} from 'connected-react-router';
import withStyles from '@material-ui/core/styles/withStyles';

import MuiTextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import { validateUrl } from '../lib/validator';
import { loadAccessToken } from '../lib/Auth';
import {API_URL} from '../config';

const outOfAppModalStyles = {
  dialogContent: {
      textAlign: 'center'
  },
  dialogPaperWidthMd: {
      width: "100%"
  },
  extractButton: {
      margin: '10px'
  }
};

const productCreationPathname = `products/create`;
const PRODUCT_METADATA_REQUEST_URL = `${API_URL}/products/scrap/metadata`;

const OutOfAppExtractionModal = withStyles(outOfAppModalStyles)((({classes, basePath, open, onClose, push, ...props}) => {
  const [externalUrl, setExternalUrl] = useState('');
  const [urlErrorMessage, setUrlErrorMessage] = useState('');
  const [isExtractionLoading, setIsExtractionLoading] = useState(false);
  
  const onChangeUrlInput = (event) => {
      const validator = validateUrl({allowEmpty: true});
      const newValue = event.target.value;

      const invalidationMessage = validator(newValue);
      setUrlErrorMessage(invalidationMessage);
      setExternalUrl(newValue)
  };
  const extractUrl = async (event) => {
    const encodedExternalUrl = encodeURIComponent(externalUrl);
    const metadataRequestUrl = new URL(PRODUCT_METADATA_REQUEST_URL);
    metadataRequestUrl.search = new URLSearchParams({product_url: encodedExternalUrl});

    setIsExtractionLoading(true);
    try {
      const response = await fetch(metadataRequestUrl.toString(), {
        headers: new Headers({
          'content-type' : 'application/json',
          Authorization: `bearer ${loadAccessToken()}`
        })
      });
      const extractionData = await response.json();
      /* link to product create page */
      push({
        pathname: `/${productCreationPathname}`,
        state: { 
          productMetadata: extractionData.metadata,
        }
      });
    } catch (err) {
      throw err
    } finally {
      setIsExtractionLoading(false);
    }
    
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" classes={{paperWidthMd: classes.dialogPaperWidthMd}}>
        <DialogTitle>Out-of-app data extraction</DialogTitle>
        <DialogContent classes={{root: classes.dialogContent}}>
            {isExtractionLoading ?
              <Loading loadingPrimary="Loading" loadingSecondary="waiting for extration..." />
              :
              <Fragment>
                <MuiTextField fullWidth error={!!urlErrorMessage} placeholder="Enter URL" value={externalUrl} onChange={(event) => onChangeUrlInput(event)} helperText={urlErrorMessage} />
                <Button variant="contained" className={classes.extractButton} label="Extract" onClick={extractUrl} />
              </Fragment>
            }
        </DialogContent>
        <DialogActions>
            <Button label="Cancel" onClick={onClose} />
        </DialogActions>
    </Dialog>
  )
}))

export default connect(null, {
  push,
})(OutOfAppExtractionModal);