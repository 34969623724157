import React from 'react';
import Avatar from '@material-ui/core/Avatar';

const ProductImageField = ({ record, size }) => {
    return record && record?.images && (
    <Avatar
        src={`${record.resizedSmallImages[0].url}?size=${size}x${size}`}
        size={size=80}
        style={{ width: size, height: size,borderRadius: 0  }}
    />)
};

ProductImageField.defaultProps = {
    size: 36,
};

export default ProductImageField;
