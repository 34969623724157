import { useEffect } from 'react';
import {MESSAGE_PERMISSION_MEMBERS} from "../constants/Permissions";
import { loadUsername } from '../lib/Auth';

export const useCheckMessagePermission = () => {
    const username = loadUsername();

    useEffect(() => {
        if (!username || !MESSAGE_PERMISSION_MEMBERS.includes(username)) {
            alert('접근 권한이 없습니다.');
        }
    }, [username]);

    return { 
        isAllowed: username && MESSAGE_PERMISSION_MEMBERS.includes(username) 
    };
};
