import React from "react";
import {
  SimpleForm,
  required,
  SelectInput,
  Edit,
  DateField,
  TextField,
  ReferenceField,
  Toolbar,
  SaveButton,
} from "react-admin";
import UserLinkField from "../components/UserLinkField";

const STATUS = [
  { id: "USABLE", name: "USABLE" },
  { id: "USED", name: "USED" },
  { id: "DELETED", name: "DELETED" },
];

const CustomToolbar = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton />
    </Toolbar>
  );
};

/**
 * Component for editing a user coupon
 * @param props the react admin Create component properties
 * @return {JSX.Element}
 * @constructor
 */
export default function UserCouponEdit(props) {
  return (
    <Edit {...props}>
      <SimpleForm toolbar={<CustomToolbar />}>
        <TextField source="id" />
        <ReferenceField reference="users" source="user_id">
          <UserLinkField />
        </ReferenceField>
        <SelectInput source="status" choices={STATUS} validate={required()} />
        <DateField source="start_datetime" />
        <DateField source="end_datetime" />
      </SimpleForm>
    </Edit>
  );
}
