import React from 'react';
import {PriorityHigh} from '@material-ui/icons';

const styles = {
  padding: '5px',
  fontSize: '0.8rem',
  borderRadius: '3px 3px 3px 3px',
  color: '#9F6000',
  backgroundColor: '#FEEFB3'
}
const WraningText = ({children, WarnIcon = PriorityHigh, ...restProps}) => {
  return (
    <span style={styles} {...restProps}><WarnIcon />{children}</span>
  );
};

export default React.memo(WraningText);