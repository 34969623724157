import React from 'react';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';

/* Make pagenation page number list (like, [4, 5, 6, 7, 8]) */
function makePageRanges(currentPage, minPage, maxPage, showPageCount) {
  const ranges = [];

  const allPageCount = maxPage - minPage + 1;
  if(allPageCount <= showPageCount) {
    for(let i = minPage; i <= maxPage; i++) {
      ranges.push(i);
    }
    return ranges;
  }

  const halfShowPageCount = Math.floor(showPageCount / 2);
  if(currentPage - halfShowPageCount >= minPage) {
    const remainNextPagesCount = maxPage - currentPage;
    const startOffset = remainNextPagesCount < halfShowPageCount ? halfShowPageCount - remainNextPagesCount : 0;
    const rangeStart = currentPage - halfShowPageCount - startOffset;
    for(let i = rangeStart; ranges.length < showPageCount && i <= maxPage; i++ ) {
      ranges.push(i);
    }
  } else {
    const rangeStart = minPage;
    for(let i = rangeStart; ranges.length < showPageCount && i <= maxPage; i++) {
      ranges.push(i);
    }
  }

  return ranges;
}


function TablePaginationActions({page, onChangePage, rowsPerPage, count, showPageRangeCount = 5, ...restProps}) {
  const pageRange = Math.ceil(count / rowsPerPage); // pages 0 ~ pageRange - 1
  const minPage = 0;
  const maxPage = pageRange - 1;
  const pageNumberRanges = makePageRanges(page, minPage, maxPage, showPageRangeCount)

  const handleFirstPageButtonClick = event => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = event => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = event => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = event => {
    onChangePage(
      event,
      Math.max(0, Math.ceil(count / rowsPerPage) - 1),
    );
  };
  const handlePageNumberClick = event => {
    const page = Number(event.target.innerText.trim());
    onChangePage(event, page - 1);
  }

  const { classes, theme } = restProps;

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="First Page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="Previous Page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      {
        pageNumberRanges.map((pageNumber => {
          const isActivePage = pageNumber === page;
          return (
            <Button 
              variant="text" 
              onClick={handlePageNumberClick}
              style={{color: isActivePage ? 'black' : 'gray'}}
              key={pageNumber}
              >
              {pageNumber + 1}
            </Button>
          )
        }))
      }
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Next Page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Last Page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

export default TablePaginationActions;