import React from 'react';
import {
    Edit,
    SimpleForm,
    ReferenceField,
    SelectInput,
    TextField
} from 'react-admin';

import UserLinkField from '../components/UserLinkField';
import { statusChoices } from './OfflineOrderList';

export default function OfflineOrderEdit(props) {
    return (
        <Edit {...props}>
            <SimpleForm actions={false}>
                <TextField source="id" disabled />
                <ReferenceField label="Seller" source="seller_id" reference="users" link="show">
                    <UserLinkField />
                </ReferenceField>
                <ReferenceField label="Buyer" source="buyer_id" reference="users" link="show">
                    <UserLinkField />
                </ReferenceField>
                <TextField label="purchase id" source="purchase_id" disabled/>
                <TextField source="product_name" disabled/>
                <SelectInput source="status" choices={statusChoices} optionValue="value" />
                <TextField source="created_at" disabled />
                <TextField source="updated_at" disabled />
                <TextField source="deleted_at" disabled />
            </SimpleForm>
        </Edit>
    )
}