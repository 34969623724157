import {withStyles} from '@material-ui/core';
import {
  AutocompleteInput,
  Create,
  ReferenceInput,
  required,
  SimpleForm, TextInput,
} from 'react-admin';
import React from 'react';

// component styling
const styles = {};

/**
 * Component for editing a referral code.
 * @param classes
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function RecommendeeCreate({classes, ...props}) {
  return (
    <Create {...props}>
      <SimpleForm action={false}>
        <div>초대 코드를 통해 친구로 등록해준다.</div>
        <TextInput source={"referral_code"} label={"초대 코드"} validate={required()} />
        <ReferenceInput
          label="친구 (친구로 등록할 사용자)"
          source="user_id"
          reference="users"
          validate={required()}>
          <AutocompleteInput optionText="username" />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
}

export default withStyles(styles)(RecommendeeCreate);
