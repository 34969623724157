import React, { useState } from "react";
import {
  SimpleForm,
  AutocompleteArrayInput,
  ReferenceArrayInput,
  ReferenceInput,
  AutocompleteInput,
  Loading,
  Button,
} from "react-admin";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import {loadAccessToken} from '../lib/Auth';
import {API_URL} from '../config';

const outOfAppModalStyles = {
  dialogContent: {
    textAlign: "center",
  },
  dialogPaperWidthMd: {
    width: "100%",
  },
  extractButton: {
    margin: "10px",
  },
};

/**
 * Modal for creating a user coupon
 * @param props the react admin Create component properties
 * @return {JSX.Element}
 * @constructor
 */
const UserCouponCreateModal = withStyles(outOfAppModalStyles)(({
  classes,
  basePath,
  open,
  onClose,
  push,
  ...props
}) => {
  const [loading, setLoading] = useState(false);
  const [couponId, setCouponId] = useState(null);
  const [userList, setUserList] = useState(null);

  const createUserCoupon = async() => {
    const url = `${API_URL}/user-coupons`;
    const body = JSON.stringify({couponId, userList})

    setLoading(true);
    const response = await fetch(url, {
      method: 'POST',
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `bearer ${loadAccessToken()}`
      }),
      body,
    });
    const { result } = await response.json();
    if (result) {
      alert('유저 쿠폰이 생성되었습니다.');
      setLoading(false);
      setCouponId(null);
      setUserList(null);
      onClose();
    } else {
      alert('유저 쿠폰 생성이 실패했습니다.');
      setLoading(false);
      setCouponId(null);
      setUserList(null);
    }

  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      classes={{ paperWidthMd: classes.dialogPaperWidthMd }}
    >
      <DialogTitle>유저 쿠폰 생성</DialogTitle>
      <DialogContent classes={{ root: classes.dialogContent }}>
        {loading ? (
          <Loading
            loadingPrimary="Loading"
            loadingSecondary="waiting for extration..."
          />
        ) : (
          <SimpleForm
            toolbar={
              <Button
                disabled={!couponId || !userList}
                variant="contained"
                className={classes.extractButton}
                label="Create"
                onClick={createUserCoupon}
              />
            }
          >
            <ReferenceInput reference="coupons" source="couponId" onChange={setCouponId}>
              <AutocompleteInput optionText="title" />
            </ReferenceInput>
            <ReferenceArrayInput
              source="userList"
              label="Users"
              reference="users"
              allowEmpty
              onChange={setUserList}
            >
              <AutocompleteArrayInput optionText="username" />
            </ReferenceArrayInput>
          </SimpleForm>
        )}
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} label="Cancel" onClick={onClose} />
      </DialogActions>
    </Dialog>
  );
});

export default connect(null, {
  push,
})(UserCouponCreateModal);
