import {connect} from "react-redux";
import {push} from "connected-react-router";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {Button, Confirm} from "react-admin";
import DialogActions from "@material-ui/core/DialogActions";
import React, {useState} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {Input, InputLabel} from "@material-ui/core";
import {API_URL} from "../config";
import {loadAccessToken} from "../lib/Auth";

const notificationModalStyles = {}

/**
 * Component for push testing modal
 */
const NotificationTestModal = withStyles(notificationModalStyles)((({formData, classes, basePath, open, onClose, push, ...props}) => {
    const [username, setUsername] = useState('');
    const [isConfirmOpen, setConfirmOpen] = useState(false);

    const closeConfirm = () => {
        setConfirmOpen(false);
    }

    const openConfirm = () => {
        if (username === '') {
            alert('Username을 입력해주세요.');
            return;
        }
        setConfirmOpen(true);
    }

    const handleOnSend = async () => {
        closeConfirm();

        if (username !== '') {
            const res = await fetch(`${API_URL}/utils/send-push`, {
                method: 'POST',
                headers: new Headers({
                    'content-type': 'application/json',
                    Authorization: `bearer ${loadAccessToken()}`
                }),
                body: JSON.stringify({
                    ...formData,
                    image: formData.resizedSmallImage,
                    username: username,
                })
            });
            const result = await res.json();
            if (result.result) {
                alert('푸시 전송이 완료되었습니다.');
                onClose();
            } else {
                alert(result.error);
            }
        }
    }

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" classes={{paperWidthMd: classes.dialogPaperWidthMd}}>
            <DialogTitle>Push Test</DialogTitle>
            <DialogContent>
                    <InputLabel>UserName</InputLabel>
                    <Input value={username} onChange={(event) => {
                        setUsername(event.target.value);
                    }} />
            </DialogContent>
            <DialogActions>
                <Button label="Send" onClick={openConfirm} />
                <Button label="Cancel" onClick={onClose} />
            </DialogActions>
            <Confirm
                isOpen={isConfirmOpen}
                title={`${username}님에게 푸시를 보내시겠습니까?`}
                onConfirm={handleOnSend}
                onClose={closeConfirm}
            />
        </Dialog>
    );
}))

export default connect(null, {
    push,
})(NotificationTestModal);
