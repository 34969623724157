import React, {useMemo} from 'react';
import {
    AutocompleteInput,
    Datagrid,
    DateField,
    DateInput,
    Filter,
    List,
    NumberInput,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    TextField,
    TextInput
} from 'react-admin';
import {withStyles} from '@material-ui/core';
import ReactJson from 'react-json-view';

import UserLinkField from '../components/UserLinkField';

const actionChoices = [
    {value: 'insert', name: 'Insert'},
    {value: 'update', name: 'Update'},
    {value: 'upsert', name: 'Upsert'},
    {value: 'delete', name: 'Delete'},
];

const typeChoices = [
    {value: 'audit', name: 'audit'},
    {value: 'block', name: 'block'},
    {value: 'brand', name: 'brand'},
    {value: 'brand_stat', name: 'brand_stat'},
    {value: 'category', name: 'category'},
    {value: 'chatRoom', name: 'chatRoom'},
    {value: 'collection', name: 'collection'},
    {value: 'collection_product', name: 'collection_product'},
    {value: 'collection_viewlog', name: 'collection_viewlog'},
    {value: 'delivery_address', name: 'delivery_address'},
    {value: 'follow', name: 'follow'},
    {value: 'follow_brand', name: 'follow_brand'},
    {value: 'guide_collection', name: 'guide_collection'},
    {value: 'guide_collection_seller', name: 'guide_collection_seller'},
    {value: 'guide_collection_viewlog', name: 'guide_collection_viewlog'},
    {value: 'like', name: 'like'},
    {value: 'login_audit', name: 'login_audit'},
    {value: 'message', name: 'message'},
    {value: 'notification', name: 'notification'},
    {value: 'notification_check', name: 'notification_check'},
    {value: 'onboarding_lookbook', name: 'onboarding_lookbook'},
    {value: 'order', name: 'order'},
    {value: 'offline_order', name: 'offline_order'},
    {value: 'order_check', name: 'order_check'},
    {value: 'password_reset_key', name: 'password_reset_key'},
    {value: 'phone_identification', name: 'phone_identification'},
    {value: 'post', name: 'post'},
    {value: 'post_brand', name: 'post_brand'},
    {value: 'post_comment', name: 'post_comment'},
    {value: 'post_viewlog', name: 'post_viewlog'},
    {value: 'post_vote', name: 'post_vote'},
    {value: 'product', name: 'product'},
    {value: 'product_bump', name: 'product_bump'},
    {value: 'product_discount', name: 'product_discount'},
    {value: 'product_ranking', name: 'product_ranking'},
    {value: 'product_stats', name: 'product_stats'},
    {value: 'product_viewlog', name: 'product_viewlog'},
    {value: 'report', name: 'report'},
    {value: 'review', name: 'review'},
    {value: 'searched_product_keyword', name: 'searched_product_keyword'},
    {value: 'section', name: 'section'},
    {value: 'seller', name: 'seller'},
    {value: 'seller_account', name: 'seller_account'},
    {value: 'seller_stats', name: 'seller_stats'},
    {value: 'seller_viewlog', name: 'seller_viewlog'},
    {value: 'settlement', name: 'settlement'},
    {value: 'size_type', name: 'size_type'},
    {value: 'size_value', name: 'size_value'},
    {value: 'style', name: 'style'},
    {value: 'subcategory', name: 'subcategory'},
    {value: 'user', name: 'user'},
    {value: 'user_admin', name: 'user_admin'},
    {value: 'user_chatRoom', name: 'user_chatRoom'},
    {value: 'user_secret', name: 'user_secret'},
    {value: 'user_stats', name: 'user_stats'},
    {value: 'brand_styles', name: 'brand_styles'},
];

// component styling
const styles = {
    date: {
        whiteSpace: 'nowrap'
    },
    description: {
        display: 'block',
        maxWidth: 300,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    }
};

/**
 * Subcomponent for the data json preview.
 * @param record The audit log entry.
 * @constructor
 */
export function AuditViewer({record}) {
    const changedData = useMemo(
        () => Object.fromEntries(
            Object.entries(record.data)
                .filter(([key]) => !record.changed || record.changed.includes(key))
        ),
        [record.data]
    );
    return (
        <ReactJson src={changedData} name={record.auditableType}/>
    );
}

/**
 * Subcomponent for filtering a list of audit logs.
 * @param props the react admin Filter component properties
 * @return {JSX.Element}
 * @constructor
 */
function AuditFilter(props) {
    return (
        <Filter {...props}>
            <DateInput source="createdAt_from" label="created from" alwaysOn/>
            <DateInput source="createdAt_until" label="created until" alwaysOn/>
            <NumberInput source='user_id'label="User id" alwaysOn/>
            <ReferenceInput source="user_id" reference="users" alwaysOn>
                <AutocompleteInput optionText="username"/>
            </ReferenceInput>
            <SelectInput source="auditable_type" label="Type" choices={typeChoices} optionValue="value" allowEmpty={true} alwaysOn/>
            <SelectInput source="action" choices={actionChoices} optionValue="value" allowEmpty={true} alwaysOn/>
            <TextInput source="ip_address" alwaysOn/>
            <TextInput source="user_agent" alwaysOn/>
        </Filter>
    )
}

/**
 * Component for a list of audits.
 * @param props the react admin List component properties
 * @return {JSX.Element}
 * @constructor
 */
function AuditList({classes, ...props}) {
    return (
        <List {...props} bulkActionButtons={false} filters={<AuditFilter/>} sort={{field: 'createdAt', order: 'DESC'}}>
            <Datagrid rowClick="show" expand={<AuditViewer/>}>
                <DateField label="Date" source="createdAt" showTime className={classes.date}/>
                <ReferenceField source="user_id" reference="users" allowEmpty>
                    <UserLinkField/>
                </ReferenceField>
                <TextField label="Type" source="auditableType" className={classes.description}/>
                <TextField label="Id" source="auditableId" className={classes.description}/>
                <TextField source="action" className={classes.description}/>
                <TextField source="ipAddress" className={classes.description}/>
                <TextField source="userAgent" className={classes.description}/>
            </Datagrid>
        </List>
    )
}

export default withStyles(styles)(AuditList);