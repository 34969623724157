import Button from '@material-ui/core/Button';
import { useCallback } from 'react';
import { loadAccessToken } from '../lib/Auth';
import { API_URL } from '../config';
import PropTypes from 'prop-types';
import {useNotify} from "react-admin";

/**
 * 검색 사전 요청 버튼
 */
const SearchDictionaryRequestButton = ({text}) => {
    const notify = useNotify();

    const handleOnClick = useCallback(async (event) => {
        event.stopPropagation();

        const n8nSendUrl = `${API_URL}/n8n/send`;

        try {
            const body = JSON.stringify({
                type: 'ADD-WORD',
                text: text
            });
            const response = await fetch(n8nSendUrl, {
                method: 'POST',
                headers: new Headers({
                    Authorization: `Bearer ${loadAccessToken()}`,
                    'Content-Type': 'application/json',
                }),
                body
            });

            const result = await response.json();
            if (result.error) {
                notify(`요청에 실패하였습니다. 에러: ${result.error}`, { type: 'error' });
            } else {
                notify(`${text} 검색 사전 요청 목록에 등록하였습니다.`, { type: 'success' });
            }
        } catch (error) {
            notify(`요청에 실패하였습니다. 에러: ${error.message}`, { type: 'error' });
        }
    }, [text]);

    return <Button variant="contained" style={{width: 200, height: 30}} onClick={handleOnClick}>검색 사전 등록 요청</Button>;
};

SearchDictionaryRequestButton.propTypes = {
    text: PropTypes.string.isRequired
};

export default SearchDictionaryRequestButton;