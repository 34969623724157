import React from 'react';
import {
    BooleanField,
    Datagrid,
    EmailField,
    List,
    ReferenceField
} from 'react-admin';

import UserLinkField from "../components/UserLinkField";

/**
 * Component for a list of admin users.
 * @param props the react admin List component properties
 * @return {JSX.Element}
 * @constructor
 */
export default function UserAdminList({classes, ...props}) {
    // build the UI elements
    return (
        <List bulkActionButtons={false} sort={{field: 'user_id', order: 'DESC'}} {...props}>
            <Datagrid rowClick="edit">
                <ReferenceField source="user_id" reference="users">
                    <UserLinkField/>
                </ReferenceField>
                <ReferenceField source="user_id" reference="users">
                    <EmailField source="email"/>
                </ReferenceField>
                <BooleanField source="is_admin"/>
                <BooleanField source="is_system"/>
            </Datagrid>
        </List>
    );
}
