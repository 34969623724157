import React from 'react';


/* Show only host */
const FormattableTextField = ({ source, record = {}, basePath, format, cellClassName, ...props }) => {
  const originText = record[source];
  const formattedText = typeof format === 'function' ? format(originText) : originText;

  return (
    <sapn {...props}>
      {formattedText}
    </sapn>
  );
};


export default FormattableTextField;
