export const base64toBlob = (data, contentType) => {
  const byteString = atob(data);
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const byteArray = new Uint8Array(arrayBuffer);

  for (let i = 0; i < byteString.length; i++) {
      byteArray[i] = byteString.charCodeAt(i);
  }

  return new Blob([byteArray], {type: contentType});
};

