import React from 'react';
import {
    Datagrid,
    Filter,
    ImageField,
    List,
    TextField,
    TextInput,
    NumberField
} from 'react-admin';
import {withStyles} from "@material-ui/core";

// component styling
const styles = {
    image: {
        '& img': {
            width: 80,
            height: 80, // same 3 by 4 aspect ratio as in the mobile app
            objectFit: 'contain'
        }
    }
};

/**
 * Sub-component for filtering a list of styles.
 * @param props the react admin Filter component properties
 * @return {JSX.Element}
 * @constructor
 */
function StyleFilter (props) {
    return (
        <Filter {...props}>
            <TextInput label="name" source="name" alwaysOn/>
        </Filter>
    );
}

/**
 * Component for a list of styles.
 * @param props the react admin List component properties
 * @return {JSX.Element}
 * @constructor
 */
function StyleList({classes, ...props}) {
    // build the UI elements
    return (
        <List bulkActionButtons={false} filters={<StyleFilter/>} sort={{ field: 'sort_order', order: 'ASC' }} {...props}>
            <Datagrid rowClick="edit">
                <TextField source="name"/>
                <ImageField source="resizedSmallImage" label="Image" sortable={false} className={classes.image}/>
                <NumberField source="brands" sortable={false}/>
                <NumberField source="sort_order"/>
            </Datagrid>
        </List>
    );
}

export default withStyles(styles)(StyleList);
