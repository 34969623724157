import React from 'react';
import {
    Edit,
    TextInput,
    maxLength,
    minLength,
    required,
    SimpleForm,
    ImageField,
    ImageInput,
    NumberInput,
    FunctionField, BooleanInput,
} from 'react-admin';
import {getResizedURL} from "../utils/util";
import {WEBAPP_URL} from "../config";
import {styleUrl} from '../lib/url';

/**
 * Component for editing a style.
 * @param props the react admin Edit component properties
 * @return {JSX.Element}
 * @constructor
 */
export default function StyleEdit(props) {
    return (
        <Edit {...props}>
            <SimpleForm actions={false}>
                <TextInput source="name" validate={[required(), minLength(1), maxLength(255)]}/>
                <TextInput multiline source="description" validate={[required(), minLength(1), maxLength(65535)]}/>
                <BooleanInput source="is_visible" validate={required()}/>
                <NumberInput source="sort_order" validate={[required()]}/>
                <ImageInput source="image" accept="image/*" multiple={false} validate={required()} format={image => {
                    if (typeof image === 'string') {
                        return image.match(/^blob:/) ? {url: image} : {url: getResizedURL(image, 'width620')};
                    } else {
                        return image;
                    }
                }}>
                    <ImageField source="url"/>
                </ImageInput>
                <TextInput source="createdAt" disabled/>
                <TextInput source="updatedAt" disabled/>

                {/* Webapp and content feed links */}
                <FunctionField
                    label="Deep Link"
                    render={record => (
                        <a href={styleUrl('fruitsfamily://', record)} target="_blank">
                            {styleUrl('fruitsfamily://', record).toString()}
                        </a>
                    )}
                />
                <FunctionField
                    label="Atom Feed"
                    render={record => (
                        <a href={styleUrl(WEBAPP_URL, record) + '/feed.atom'} target="_blank">
                            {styleUrl(WEBAPP_URL, record) + '/feed.atom'}
                        </a>
                    )}
                />
                <FunctionField
                    label="Google Shopping Feed"
                    render={record => (
                        <a href={styleUrl(WEBAPP_URL, record) + '/googleshopping.xml'} target="_blank">
                            {styleUrl(WEBAPP_URL, record) + '/googleshopping.xml'}
                        </a>
                    )}
                />
            </SimpleForm>
        </Edit>
    );
}
