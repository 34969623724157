import React, {useState, useCallback, useEffect, useRef, Fragment} from 'react';
import styled from 'styled-components';

import {randomSimpleUID} from '../utils/basic';

import './FileInput.css';


const NativeFileInput = styled.input.attrs({
  type: 'file'
})`
  display: ${props => props.hidden ? 'hidden' : 'block'}
`;

const FileInputFrame = styled.div`
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  width: 380px;
  height: 200px;

  outline: 2px dashed #92b0b3;
  outline-offset: ${props => props.isDragover ? "-15px" : "-10px"};
  font-size: 1rem;
  color: ${props => props.isDragover ? "white" : "#363636"};
  background-color: ${props => props.isDragover ? "#e0dcd1" : "#E3E2DF"};

  transition: all .15s ease-in-out;
  transition-property: background-color, outline-offset;

  &:hover {
    cursor: pointer;
  }
`;


const addPropertyAllFiles = (fileList, propName, propValue) => {
  const length = fileList.length;

  for(let i = 0; i < length; i++) {
    fileList[i][propName] = propValue
  }
};

const blockEvent = (e) => {
  e.stopPropagation();
  e.preventDefault();
}

const checkValidAccept = (fileType, accept) => {
  if(!accept) return true;
  return fileType.includes(accept.replace(/\*/g, ""));
}

const FileInput = ({multiple = true, values, setValues, accept, ...restProps}) => {
  const fileInputRef = useRef(null);

  const [isDragover, setIsDragover] = useState(false);
  const [myFiles, mySetFiles] = useState([]);
  const files = values || myFiles;
  const setFiles = setValues || mySetFiles;

  
  const dragOver = useCallback((e) => {
    blockEvent(e);
    setIsDragover(true);
  }, []);
  const dragLeave = useCallback((e) => {
    blockEvent(e);
    setIsDragover(false);
  }, []);

  const uploadFilesByDrag = (e) => {
    blockEvent(e);
    dragLeave(e);
    const newFiles = e.dataTransfer.files;

    for(let i = 0; i < newFiles.length; i++) {
      const file = newFiles[i];
      if(!checkValidAccept(file.type, accept)) {
        return false;
      }
      file.preview = URL.createObjectURL(file);
      file.uid = randomSimpleUID();
    }
    // console.log('dnd uploadFiles', newFiles);
    setFiles([
      ...files,
      ...newFiles
    ]);
  };
  const uploadFilesByFileInput = (e) => {
    const newFiles = fileInputRef.current.files;
    for(let i = 0; i < newFiles.length; i++) {
      const file = newFiles[i];
      file.preview = URL.createObjectURL(file);
      file.uid = randomSimpleUID();
    }
    
    setFiles([
      ...files,
      ...newFiles
    ]);
  };
  const onClick = useCallback((e) => {
    fileInputRef.current.click();
  });

  useEffect(() => {
    if(restProps.onChange) {
      restProps.onChange(files);
    }
  }, [files]);

  return (<FileInputFrame isDragover={isDragover}
    onDragOver={dragOver}
    onDragLeave={dragLeave}
    onDrop={uploadFilesByDrag}
    onClick={onClick}
  >
    <div>
      { isDragover 
        ? "Drop here!"
        : "Drag & Drop the files"
      }
    </div>
    <NativeFileInput ref={fileInputRef} hidden multiple={multiple} onChange={uploadFilesByFileInput} onClick={(event) => event.target.value = null} accept={accept} />
  </FileInputFrame>);
}



export default FileInput;