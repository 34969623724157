import React from 'react';
import {useRecordContext} from 'react-admin';

const ImageGallery = ({source}) => {
  const record = useRecordContext();

  if (!record || !record[source]) return null;

  const handleImageClick = url => {
    window.open(url, '_blank');
  };

  return (
    <div style={{display: 'flex', flexWrap: 'wrap', gap: '10px'}}>
      {record[source].map((image, index) => (
        <img
          key={index}
          src={image.url}
          alt={`Image ${index + 1}`}
          style={{
            width: '200px',
            height: 'auto',
            objectFit: 'cover',
            cursor: 'pointer',
          }}
          onClick={() => handleImageClick(image.url)}
        />
      ))}
    </div>
  );
};

export default ImageGallery;
