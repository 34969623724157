import React from 'react';
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import { DateTimePicker as MaterialDateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';

const materialTheme = createMuiTheme({
  overrides: {
    // MuiPickersToolbar: {
    //   toolbar: {
    //     backgroundColor: lightBlue.A200,
    //   },
    // },
    // MuiPickersCalendarHeader: {
    //   switchHeader: {
    //     // backgroundColor: lightBlue.A200,
    //     // color: "white",
    //   },
    // },
    // MuiPickersDay: {
    //   day: {
    //     color: lightBlue.A700,
    //   },
    //   isSelected: {
    //     backgroundColor: lightBlue["400"],
    //   },
    //   current: {
    //     color: lightBlue["900"],
    //   },
    // },
    MuiPickersModal: {
      dialog: {
        width: '100%'
      }
    },
  },
});

export const DateTimePicker = ({utils, ...props}) => {
  return (
    <MuiThemeProvider theme={materialTheme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <MaterialDateTimePicker {...props} />
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  );
}

 