import React from 'react';
import {useState} from 'react';
import {
    Button, ImageField, ImageInput,
    Loading, SimpleForm,
} from 'react-admin';
import { connect } from 'react-redux';
import {push} from 'connected-react-router';
import withStyles from '@material-ui/core/styles/withStyles';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import {API_URL} from '../config';
import {loadAccessToken} from '../lib/Auth';

const outOfAppModalStyles = {
    dialogContent: {
        textAlign: 'center'
    },
    dialogPaperWidthMd: {
        width: "100%"
    },
    extractButton: {
        margin: '10px'
    }
};

const ADD_BANNER_LOGO_URL = `${API_URL}/banner-logo`;

const BannerLogo = withStyles(outOfAppModalStyles)((({classes, basePath, open, onClose, push, ...props}) => {
    const [image, setImage] = useState(null);
    const [loading, setLoading] = useState(false);

    const sendImage = async () => {
        const formData = new FormData();
        formData.append('image', image, image.name);

        setLoading(true);
        const response = await fetch(ADD_BANNER_LOGO_URL, {
            method: 'POST',
            headers: new Headers({
                Authorization: `bearer ${loadAccessToken()}`
            }),
            body: formData
        });
        const data = await response.json();
        if (data?.result) {
            alert('배너 로고 등록에 성공하였습니다.')
            setLoading(false);
        } else {
            alert(data?.error);
            setLoading(false);
        }
    };

    const closeFn = () => {
        onClose();
    }

    return (
        <Dialog open={open} onClose={closeFn} maxWidth="md" classes={{paperWidthMd: classes.dialogPaperWidthMd}}>
            <DialogTitle>배너 로고 등록</DialogTitle>
            <DialogContent classes={{root: classes.dialogContent}}>
                {loading ?
                    <Loading loadingPrimary="Loading" loadingSecondary="waiting for extration..." />
                    :
                    <SimpleForm toolbar={<Button disabled={!image} variant="contained" className={classes.extractButton} label='등록' onClick={sendImage} />}>
                        <ImageInput source="image" accept="image/png" multiple={false} onChange={setImage}>
                            <ImageField source="url" title="title"/>
                        </ImageInput>
                    </SimpleForm>
                }
            </DialogContent>
            <DialogActions>
                <Button disabled={loading} label="Cancel" onClick={closeFn} />
            </DialogActions>
        </Dialog>
    )
}))

export default connect(null, {
    push,
})(BannerLogo);
