import React from 'react';
import { Link } from 'react-admin';

import UsernameProfileField from '../components/UsernameProfileField';

const UserLinkField = props => (
    props.record && props.record.id 
    ? (<Link to={`/users/${props.record.id}/show`}>
        <UsernameProfileField {...props} />
    </Link>)
    : null
);

UserLinkField.defaultProps = {
    source: 'id',
    addLabel: true,
};

export default UserLinkField;
