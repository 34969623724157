import React from 'react';

import { Card, CardActions, CardContent, CardHeader, CardMedia } from '@material-ui/core';
import { Avatar } from '@material-ui/core';
import PropTypes from 'prop-types';

import DefaultAvatarImage from "../assets/images/profileDefault2.png"

const styles = {
    card: {
        border: 1,
        textAlign: 'center',
        alignItems: 'left',
        width: 240,
        paddingTop: 30,
        marginTop: 10,
    },
    media: {
        width: 100,
        maxHeight: 80,
        alignItems: 'center',
    },
    avatar: {
        width: 36,
        height: 36,
        overflow: 'hidden',
        margin: '0 auto',
    }
};

/**
 * Sub-component for a seller card.
 * @param props the react admin seller collection component properties
 * @return {JSX.Element}
 * @constructor
 */
export default function SimpleSellerCard(props) {
    const { seller } = props;

    return (
        <Card style={styles.card}>
            <Avatar
                src={seller.user.resizedSmallImage ?? DefaultAvatarImage}
                size={36}
                style={styles.avatar}
            />
            <CardHeader
                titleTypographyProps={{
                    noWrap: true
                }}
                title={`ID: ${seller.id}`}
                subheader={seller.user.nickname}
            />
            <CardMedia
                style={styles.media}
                title={seller.nickname}
            />
            <CardActions>
                {props.actions}
            </CardActions>
        </Card>
    );
}

SimpleSellerCard.propTypes = {
    props: PropTypes.object.isRequired
};
