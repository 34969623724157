import {withStyles} from '@material-ui/core';
import {
  AutocompleteInput,
  Create,
  ReferenceInput,
  required,
  SimpleForm,
  TextInput,
} from 'react-admin';
import React from 'react';

// component styling
const styles = {};

/**
 * Component for editing a referral code.
 * @param classes
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function ReferralCodeCreate({classes, ...props}) {
  return (
    <Create {...props}>
      <SimpleForm action={false}>
        <div>{`[커스텀 코드 생성]`}</div>
        <div>
          커스텀 초대 코드를 수동으로 생성하여 특정 사용자에게 발급할 수
          있습니다.
        </div>
        <ReferenceInput
          source="user_id"
          reference="users"
          validate={required()}>
          <AutocompleteInput optionText="username" />
        </ReferenceInput>
        <TextInput source="referral_code" label="커스텀 코드" required={true} />
      </SimpleForm>
    </Create>
  );
}

export default withStyles(styles)(ReferralCodeCreate);
