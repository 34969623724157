export const arrayMove = (arr, from, to) => {
  const value = arr[from];
  arr.splice(from, 1);
  arr.splice(to, 0, value);
  return arr;
}

export const randomBase36 = () => {
  return Math.random().toString(36).substr(2);
}
export const randomSimpleUID = () => {
  const now = new Date();
  return `${randomBase36()}-${randomBase36()}-${now.valueOf()}`;
}