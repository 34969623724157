import React from 'react';
import {
    ArrayField,
    AutocompleteInput,
    BooleanField,
    ChipField,
    Datagrid,
    DateField, DateInput,
    Filter,
    ImageField,
    List,
    NumberField,
    ReferenceField,
    ReferenceInput,
    ReferenceManyField,
    SingleFieldList,
    TextField,
    TextInput,
} from 'react-admin';
import {withStyles} from "@material-ui/core";

import UserLinkField from "../components/UserLinkField";

// component styling
const styles = {
    image: {
        '& img': {
            width: 80,
            height: 106.6667, // same 3 by 4 aspect ratio as in the mobile app
            objectFit: 'cover'
        }
    },
    description: {
        display: 'block',
        maxWidth: 300,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    }
};

/**
 * Sub-component for filtering a list of posts.
 * @param props the react admin Filter component properties
 * @return {JSX.Element}
 * @constructor
 */
function PostFilter (props) {
    return (
        <Filter {...props}>
            <DateInput source="createdAt_from" label="created from" alwaysOn />
            <DateInput source="createdAt_until" label="created until" alwaysOn />
            <ReferenceInput source="brand_id" reference="brands" perPage={100} sort={{field: 'name', order: 'ASC'}} alwaysOn>
                <AutocompleteInput />
            </ReferenceInput>
            <TextInput source="description" alwaysOn/>
            <ReferenceInput source="user_id" reference="users" alwaysOn>
                <AutocompleteInput optionText="username" />
            </ReferenceInput>
        </Filter>
    );
}

/**
 * Component for a list of posts.
 * @param props the react admin List component properties
 * @return {JSX.Element}
 * @constructor
 */
function PostList({classes, ...props}) {
    // build the UI elements
    return (
        <List bulkActionButtons={false} filters={<PostFilter />} sort={{field: 'id', order: 'DESC'}} {...props}>
            <Datagrid rowClick="edit">
                <ImageField source="resizedSmallImages.0.url" label="Image" sortable={false} className={classes.image} />
                <BooleanField source="is_visible"/>
                <ReferenceField source="user_id" reference="users">
                    <UserLinkField/>
                </ReferenceField>
                <TextField source="description" className={classes.description}/>
                <ArrayField source="brands" sortable={false}>
                    <SingleFieldList linkType={false}>
                        <ChipField source="name" />
                    </SingleFieldList>
                </ArrayField>
                <NumberField source="view_count"/>
                <NumberField source="score"/>
                <DateField source="createdAt"/>
                <ReferenceManyField target="post_id" reference="reports" label="Reports" sortable={false}
                                    linkType="list">
                    <SingleFieldList>
                        <ChipField source="reason" />
                    </SingleFieldList>
                </ReferenceManyField>
            </Datagrid>
        </List>
    );
}

export default withStyles(styles)(PostList);
