import React from 'react';
import AvatarField from './AvatarField';

const FullNameField = ({ record = {}}) => {
    return (
    <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
        <AvatarField record={record} size={48} source="resizedSmallImage" />
        &nbsp;<h3>{record.username}</h3>
    </div>
)};

export default FullNameField;
