import React from 'react';
import {useState} from 'react';
import {
    AutocompleteInput,
    Button, ImageField, ImageInput,
    Loading, ReferenceInput, SimpleForm,
} from 'react-admin';
import { connect } from 'react-redux';
import {push} from 'connected-react-router';
import withStyles from '@material-ui/core/styles/withStyles';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import {API_URL} from '../config';
import {loadAccessToken} from '../lib/Auth';

const outOfAppModalStyles = {
    dialogContent: {
        textAlign: 'center'
    },
    dialogPaperWidthMd: {
        width: "100%"
    },
    extractButton: {
        margin: '10px'
    }
};

const ADD_IMAGE_URL = (collection_id) => `${API_URL}/collections/${collection_id}/image`;

const AddImageCollectionModal = withStyles(outOfAppModalStyles)((({classes, basePath, open, onClose, push, ...props}) => {
    const [image, setImage] = useState(null);
    const [collectionId, setCollectionId] = useState(null);
    const [loading, setLoading] = useState(false);

    const sendImage = async () => {
        const formData = new FormData();
        formData.append("image", image);

        setLoading(true);
        const url = ADD_IMAGE_URL(collectionId);
        const response = await fetch(url, {
            method: 'PUT',
            headers: new Headers({
                Authorization: `bearer ${loadAccessToken()}`
            }),
            body: formData
        });
        const { result, errorMsg } = await response.json();
        if (result) {
            alert('Collection 이미지 변경에 성공하였습니다.');
            setLoading(false);
            setCollectionId(null);
            setImage(null);
            onClose();
        } else {
            alert(errorMsg);
            setLoading(false);
            setCollectionId(null);
            setImage(null);
        }
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" classes={{paperWidthMd: classes.dialogPaperWidthMd}}>
            <DialogTitle>Add Image in Collection</DialogTitle>
            <DialogContent classes={{root: classes.dialogContent}}>
                {loading ?
                    <Loading loadingPrimary="Loading" loadingSecondary="waiting for extration..." />
                    :
                    <SimpleForm toolbar={<Button disabled={!image || !collectionId} variant="contained" className={classes.extractButton} label="Upload" onClick={sendImage} />}>
                        <ReferenceInput reference={"collections"} source={"collection_id"} onChange={setCollectionId}>
                            <AutocompleteInput optionText="title" />
                        </ReferenceInput>
                        <ImageInput source="image" accept="image/*" multiple={false} onChange={setImage}>
                            <ImageField source="url" title="title"/>
                        </ImageInput>
                    </SimpleForm>
                }
            </DialogContent>
            <DialogActions>
                <Button disabled={loading} label="Cancel" onClick={onClose} />
            </DialogActions>
        </Dialog>
    )
}))

export default connect(null, {
    push,
})(AddImageCollectionModal);
