import React from 'react';
import {BooleanField} from 'react-admin';

const BooleanNumField = ({ record, source, ...restProps }) => {
  const booleanValue = !!record[source];
  return (
    <BooleanField record={{[source]:booleanValue}} source={source} {...restProps} />
  );
};

export default BooleanNumField;
