import React from 'react';
import {
    ImageInput,
    ImageField,
    Create,
    TextInput,
    maxLength,
    minLength,
    required,
    SimpleForm,
    NumberInput
} from 'react-admin';

/**
 * Component for creating a style.
 * @param props the react admin Create component properties
 * @return {JSX.Element}
 * @constructor
 */
export default function StyleCreate(props) {
    return (
        <Create {...props}>
            <SimpleForm actions={false}>
                <TextInput source="name" validate={[required(), minLength(1), maxLength(255)]}/>
                <TextInput multiline source="description" validate={[required(), minLength(1), maxLength(65535)]}/>
                <NumberInput source="sort_order" validate={[required()]}/>
                <ImageInput source="image" accept="image/*" multiple={false} validate={required()}>
                    <ImageField/>
                </ImageInput>
            </SimpleForm>
        </Create>
    );
}
