import React, {useState, Fragment} from 'react';
import {
    ArrayField,
    AutocompleteInput,
    Button,
    Datagrid,
    DateField,
    ExportButton,
    Filter, Link,
    List,
    ReferenceField,
    ReferenceInput, SelectInput, SingleFieldList,
    TextField,
    TopToolbar,
} from 'react-admin';
import {withStyles} from '@material-ui/core';
import UserCouponCreateModal from './UserCouponCreateModal';
import UserLinkField from '../components/UserLinkField';

// component styling
const styles = {};

const statusChoices = [
    { value: "USABLE", name: "USABLE" },
    { value: "USED", name: "USED" },
    { value: "DELETED", name: "DELETED" },
];

const OrderUrlField = ({ record, source }) => {
    return <Link to={`/orders/${record[source]}`}>{record[source]}</Link>;
};

function UserCouponFilter(props) {
  return (
    <Filter {...props}>
      <ReferenceInput source="userId" reference="users" alwaysOn>
        <AutocompleteInput optionText="username" />
      </ReferenceInput>
      <ReferenceInput reference="coupons" source="coupon_id" alwaysOn>
        <AutocompleteInput optionText="title" />
      </ReferenceInput>
      <SelectInput source="status" choices={statusChoices} optionValue="value" allowEmpty={true} alwaysOn/>
    </Filter>
  );
}

/**
 * Component for a list of coupons.
 * @param props the react admin List component properties
 * @return {JSX.Element}
 * @constructor
 */
function UserCouponList({classes, ...props}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  // build the UI elements
  return (
    <Fragment>
      <List
        bulkActionButtons={false}
        filters={<UserCouponFilter />}
        sort={{field: 'id', order: 'DESC'}}
        {...props}
        actions={
          <TopToolbar>
            <Button
              basePath={props.basePath}
              onClick={() => setIsModalOpen(true)}
              label={'Create'}
            />
            <ExportButton props />
          </TopToolbar>
        }>
        <Datagrid rowClick={'edit'}>
            <TextField source="id" />
            <ReferenceField
            label="User"
            source="user_id"
            reference="users"
            link="show">
              <UserLinkField />
          </ReferenceField>
          <ReferenceField reference="coupons" source="coupon_id">
            <TextField source="title" />
          </ReferenceField>
          <TextField source="status" />
          <DateField source="start_datetime" />
          <DateField source="end_datetime" />
            <ArrayField source="used_coupons" label="주문번호">
                <SingleFieldList>
                    <OrderUrlField source="order_id" />
                </SingleFieldList>
            </ArrayField>
        </Datagrid>
      </List>
      <UserCouponCreateModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </Fragment>
  );
}

export default withStyles(styles)(UserCouponList);
