// in src/users.js
import React, { Component } from 'react';
import Drawer from '@material-ui/core/Drawer';
import {
    List,
    Datagrid,
    TextField,
    TextInput,
    Edit,
    SimpleForm,
    ArrayInput,
    SimpleFormIterator,
    SelectInput,
    EditController,
    SaveButton,
    Toolbar,
    CreateController,
    Create,
    BulkDeleteButton,
    NumberInput,
    FunctionField
} from 'react-admin';
import SubCategoryField from "../components/SubCategoryField"
import SizeField from "../components/SizeField"
import { API_URL, WEBAPP_URL } from "../config";
import { loadAccessToken } from '../lib/Auth';
import { discoverProductUrl } from '../lib/url';


const OrderDesciprtion = props => {
    const desciprtionStyle = {
        backgroundColor: '#FCF4A3',
        borderRadius: '9px',
        padding: '16px 12px',
        color: '#787276',
    }
    return <h5 style={desciprtionStyle} {...props}>Sort order가 낮은 번호부터 노출됩니다. (예: W = sort_order: 1, M = sort_order: 2의 경우 W -> M 순으로 노출됩니다)</h5>
}

const CustomToolbar = props => (
    <Toolbar {...props}>
        <SaveButton disabled={props.pristine} undoable={false}/>
    </Toolbar>
);
export const CategoryList = props => {
    return (<List {...props} bulkActionButtons={<BulkDeleteButton undoable={false}/>} sort={{field: 'id', order: 'DESC'}}>   
        <Datagrid
            rowClick="edit"
        >
            <TextField source="name"/>
            <SubCategoryField label="subcategories"/>
            <TextField source="sort_order" />
        </Datagrid>
    </List>)
}

export const SizeList = props => (
    <List {...props} bulkActionButtons={<BulkDeleteButton undoable={false}/>}>
        <Datagrid
            rowClick="edit"
        >
            <TextField source="name"/>
            <SizeField/>
        </Datagrid>
    </List>
)

export const SizeEdit = props => (
    <Edit {...props} undoable={false}>
        <SimpleForm toolbar={<CustomToolbar/>}>
            <TextInput source="name"/>
            <h2>✅ <br />sort_order는 낮은 숫자일 수록 우선순위가 높으며, 소수점, 중복 값 사용이 불가합니다.</h2>
            <ArrayInput
                label="size_values"
                source="size_values">
                <SimpleFormIterator disableReordering>
                    <TextInput source="name" label="Name"/>
                    <TextInput source="secondary_name" label="Secondary Name"/>
                    <NumberInput source="sort_order" label="Sort Order"/>
                </SimpleFormIterator>
            </ArrayInput>
        </SimpleForm>
    </Edit>
)

export const SizeCreate = props => (
    <Create {...props}>
        <SimpleForm toolbar={<CustomToolbar/>}>
            <TextInput source="name"/>
            <ArrayInput
                label="size_values"
                source="size_values">
                <SimpleFormIterator disableReordering>
                    <TextInput source="name" label="Name"/>
                    <TextInput source="secondary_name" label="Secondary Name"/>
                    <NumberInput source="sort_order" label="Sort Order"/>
                </SimpleFormIterator>
            </ArrayInput>
        </SimpleForm>
    </Create>
)

const genderChoices = [
    { id: 'unisex', name: 'unisex' },
    { id: 'men', name: 'men' },
    { id: 'women', name: 'women' },
];
export class CategoryEdit extends Component {
    state = {
        choices: [],
    };

    async componentDidMount () {
        const res = await fetch(`${API_URL}/sizes`, {
            method: 'GET',
            headers: new Headers({
                'content-type' : 'application/json',
                Authorization: `bearer ${loadAccessToken()}`
            }),
        })
        const {result: payload} = await res.json()
        this.setState({choices: payload})
    }

    render() {
        return <EditController {...this.props} undoable={false} redirect="list">
            {controllerProps => {
                return controllerProps.record ? (
                    <SimpleForm {...controllerProps} toolbar={<CustomToolbar/>}>
                        <TextInput source="name"/>
                        <NumberInput source="sort_order"/>
                        <SelectInput choices={genderChoices} source="gender"/>
                        <OrderDesciprtion />
                        <ArrayInput
                            label="subcategories"
                            source="subcategories">
                            <SimpleFormIterator disableReordering>
                                <TextInput source="name" label="Name"/>
                                <NumberInput source="sort_order" label="Sort Order"/>
                                <SelectInput choices={this.state.choices} source="size_type_id" label="Size Type Id" resettable/>
                                <SelectInput choices={genderChoices} source="gender" label="Gender"/>
                            </SimpleFormIterator>
                        </ArrayInput>

                        {/* Webapp and content feed links */}
                        <FunctionField
                            label="Deep Link"
                            render={record => (
                                <a href={discoverProductUrl(WEBAPP_URL, record)} target="_blank">
                                    {discoverProductUrl(WEBAPP_URL, record).toString()}
                                </a>
                            )}
                        />
                        <FunctionField
                            label="Atom Feed"
                            render={record => (
                                <a href={discoverProductUrl(WEBAPP_URL, record) + '/feed.atom'} target="_blank">
                                    {discoverProductUrl(WEBAPP_URL, record) + '/feed.atom'}
                                </a>
                            )}
                        />
                        <FunctionField
                            label="Google Shopping Feed"
                            render={record => (
                                <a href={discoverProductUrl(WEBAPP_URL, record) + '/googleshopping.xml'} target="_blank">
                                    {discoverProductUrl(WEBAPP_URL, record) + '/googleshopping.xml'}
                                </a>
                            )}
                        />
                    </SimpleForm>
                ) : null
            }}
        </EditController>
    }
}

export class CategoryCreate extends Component {

    render() {
        return <CreateController {...this.props} undoable={false} redirect="list">
            {controllerProps => {
                return controllerProps.record ? (
                    <SimpleForm {...controllerProps} toolbar={<CustomToolbar/>}>
                        <TextInput label="category name" source="name"/>
                        <NumberInput label="Sort order" source="sort_order" />
                        <SelectInput choices={genderChoices} source="gender"/>
                        <OrderDesciprtion />
                    </SimpleForm>
                ) : null
            }}
        </CreateController>
    }
}