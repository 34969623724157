import Button from '@material-ui/core/Button';
import { useCallback } from 'react';
import { loadAccessToken } from '../lib/Auth';
import { API_URL } from '../config';
import PropTypes from 'prop-types';
import {useNotify, useRecordContext} from "react-admin";

/**
 * 상품 indexing 요청 버튼
 * Datagrid 내부에서 사용될 때 record prop이 자동으로 주입됩니다
 */
const RequestProductIndexingButton = () => {
  const record = useRecordContext();
  const notify = useNotify();

  const handleOnClick = useCallback(async (event) => {
    event.stopPropagation();

    const productIndexingUrl = `${API_URL}/es/products/${record.id}/product-indexing`;

    try {
      const response = await fetch(productIndexingUrl, {
        method: 'POST',
        headers: new Headers({
          Authorization: `Bearer ${loadAccessToken()}`,
          'Content-Type': 'application/json',
        }),
      });

      const result = await response.json();
      if (result.error) {
        notify(`인덱싱에 실패하였습니다: ${result.error}`, { type: 'error' });
      } else {
        notify(`${record.id} 상품 인덱싱에 성공하였습니다.`, { type: 'success' });
      }
    } catch (error) {
      notify(`인덱싱 요청에 실패하였습니다. 에러: ${error.message}`, { type: 'error' });
    }
  }, [record]);

  return <Button variant="contained" style={{width: 140, height: 30}} onClick={handleOnClick}>상품 인덱싱</Button>;
};

RequestProductIndexingButton.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }),
};

export default RequestProductIndexingButton;