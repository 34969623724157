import React from 'react';
import {
    AutocompleteInput,
    DateField,
    DateTimeInput,
    Edit,
    FormDataConsumer,
    ImageField,
    ImageInput,
    maxLength,
    minLength,
    minValue,
    NumberInput,
    ReferenceInput,
    required,
    SelectInput,
    SimpleForm,
    TextInput
} from 'react-admin';
import {JsonInput} from 'react-admin-json-view';

import {validateUrl} from '../lib/validator';
import {getResizedURL} from '../utils/util';

// workaround for maximum update depth exceeded error, cf https://github.com/marmelab/react-admin/issues/5593
const DEFAULT_SHORTCUTS = [];
const DEFAULT_FILTER = {};

/**
 * Component for editing an section.
 * @param props the react admin Edit component properties
 * @return {JSX.Element}
 * @constructor
 */
export default function SectionEdit(props) {
    return (
        <Edit
            {...props}
            transform={data => ({
                // fix for FF-3101 Filter value can't be removed
                // this is caused by React Admin 3 sanitize behaviour,
                // cf https://marmelab.com/react-admin/doc/3.19/CreateEdit.html#setting-empty-values-to-null
                ...data,
                filter: data.filter ?
                    Object.fromEntries(
                        Object.entries(data.filter)
                            .filter(([, value]) => value !== null)
                    ) :
                    data.filter,
                shortcuts: data.shortcuts ?
                    data.shortcuts.map(
                        shortcut => Object.fromEntries(
                            Object.entries(shortcut)
                                .filter(([, value]) => value !== null)
                        )
                    ) :
                    data.shortcuts
            })}
        >
            <SimpleForm>
                {/* common fields */}
                <FormDataConsumer>
                    {({formData, ...rest}) => (
                        <SelectInput
                            source="type"
                            choices={[
                                {id: 'BANNER', name: 'BANNER'},
                                {id: 'BRAND', name: 'BRAND'},
                                {id: 'COLLECTION', name: 'COLLECTION'},
                                {id: 'COLLECTION_PRODUCT', name: 'COLLECTION_PRODUCT'},
                                {id: 'FOLLOWING', name: 'FOLLOWING'},
                                {id: 'PRODUCT', name: 'PRODUCT'},
                                {id: 'SHORTCUT', name: 'SHORTCUT'},
                                {id: 'STYLE', name: 'STYLE'},
                                {id: 'RECENTLY_PRODUCT', name: 'RECENTLY_PRODUCT'}
                            ]}
                            validate={required()}
                            disabled={!!formData.type}
                        />
                    )}
                </FormDataConsumer>
                <SelectInput
                    source="device"
                    choices={[
                        {id: 'MOBILE', name: 'MOBILE'},
                        {id: 'WEB', name: 'WEB'}
                    ]}
                    validate={required()}
                />
                <DateTimeInput source="featuredFrom" validate={required()} />
                <DateTimeInput source="featuredUntil" />
                <NumberInput source="position" min={0} validate={[required(), minValue(0)]} />
                <NumberInput source="priority" step={0.1} defaultValue={0} validate={required()} />
                <TextInput source="title" validate={[minLength(1), maxLength(255)]} />
                <TextInput source="subtitle" validate={[minLength(1), maxLength(255)]} />
                <TextInput
                    source="link"
                    type="url"
                    validate={[
                        validateUrl({
                            allowEmpty: true,
                            protocols: ['https', 'fruitsfamily'],
                            require_protocol: true,
                            require_host: false,
                            require_tld: false
                        }),
                        minLength(1),
                        maxLength(2084)
                    ]}
                />
                <TextInput source="description" multiline={true} validate={[minLength(1), maxLength(65535)]} />

                {/* banner section */}
                <FormDataConsumer>
                    {({formData, ...rest}) => formData.type === 'BANNER' && (<>
                        <NumberInput source="aspectRatio" step={0.0000000000000001} validate={required()} />
                        <ImageInput
                            source="image"
                            accept="image/svg+xml"
                            multiple={false}
                            format={image => {
                                if (typeof image === 'string') {
                                    return image.match(/^blob:/) ? {url: image} : {url: getResizedURL(image, 'width620')};
                                } else {
                                    return image;
                                }
                            }}
                            validate={[required(), minLength(1), maxLength(2084)]}
                        >
                            <ImageField source="url" />
                        </ImageInput>
                        <ImageInput
                            source="backgroundImage"
                            accept="image/jpeg,image/png"
                            multiple={false}
                            format={image => {
                                if (typeof image === 'string') {
                                    return image.match(/^blob:/) ? {url: image} : {url: getResizedURL(image, 'width620')};
                                } else {
                                    return image;
                                }
                            }}
                            validate={[minLength(1), maxLength(2084)]}
                        >
                            <ImageField source="url" />
                        </ImageInput>
                    </>)}
                </FormDataConsumer>

                {/* analyticsName */}
                <FormDataConsumer>
                    {({formData, ...rest}) => ['COLLECTION_PRODUCT', 'FOLLOWING', 'PRODUCT'].includes(formData.type) && (
                        <TextInput source="analyticsName" validate={[required(), minLength(1), maxLength(255)]} />
                    )}
                </FormDataConsumer>

                {/* filter */}
                <FormDataConsumer>
                    {({formData, ...rest}) => ['BRAND', 'COLLECTION_PRODUCT', 'PRODUCT', 'STYLE'].includes(formData.type) && (
                        <JsonInput
                            source="filter"
                            defaultValue={DEFAULT_FILTER}
                            validate={required()}
                            reactJsonOptions={{
                                name: false,
                                displayArrayKey: false,
                                displayDataTypes: false,
                                displayObjectSize: false,
                                enableClipboard: false,
                                quotesOnKeys: false
                            }}
                            helperText="⚠️ Invalid values can crash the app."
                        />
                    )}
                </FormDataConsumer>

                {/* sort */}
                <FormDataConsumer>
                    {({formData, ...rest}) => ['BRAND', 'PRODUCT', 'STYLE'].includes(formData.type) && (
                        <TextInput
                            source="sort"
                            validate={[required(), minLength(1), maxLength(255)]}
                            helperText="⚠️ Invalid values can crash the app."
                        />
                    )}
                </FormDataConsumer>

                {/* limit */}
                <FormDataConsumer>
                    {({formData, ...rest}) => ['BRAND', 'STYLE'].includes(formData.type) && (
                        <NumberInput source="limit" defaultValue={20} min="1" validate={[required(), minValue(1)]} />
                    )}
                </FormDataConsumer>

                {/* shortcuts */}
                <FormDataConsumer>
                    {({formData, ...rest}) => formData.type === 'SHORTCUT' && (
                        <JsonInput
                            source="shortcuts"
                            defaultValue={DEFAULT_SHORTCUTS}
                            validate={required()}
                            reactJsonOptions={{
                                name: false,
                                displayArrayKey: false,
                                displayDataTypes: false,
                                displayObjectSize: false,
                                enableClipboard: false,
                                quotesOnKeys: false
                            }}
                            helperText="⚠️ Invalid values can crash the app."
                        />
                    )}
                </FormDataConsumer>

                {/* collection */}
                <FormDataConsumer>
                    {({formData, ...rest}) => formData.type === 'COLLECTION_PRODUCT' && (
                        <ReferenceInput source="collectionId" reference="collections" validate={required()}>
                            <AutocompleteInput optionText="title"/>
                        </ReferenceInput>
                    )}
                </FormDataConsumer>

                {/* metadata */}
                <DateField source="createdAt" showTime />
                <DateField source="updatedAt" showTime />
            </SimpleForm>
        </Edit>
    );
}
