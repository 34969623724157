import React, {Fragment, useCallback, useState} from 'react';
import {
  Button,
  CreateButton,
  Datagrid,
  DateField,
  ExportButton,
  List,
  NumberField,
  TextField,
  TopToolbar,
} from 'react-admin';
import {withStyles} from '@material-ui/core';
import IssueCsvCouponModal from '../components/IssueCsvCouponModal';

// component styling
const styles = {
  imagePreview: {
    '& img': {
      maxWidth: '270px',
      maxHeight: '90px',
    },
  },
};

/**
 * Component for a list of coupons.
 * @param props the react admin List component properties
 * @return {JSX.Element}
 * @constructor
 */
function CouponList({classes, ...props}) {
  const [toggleCsvModal, setToggleCsvModal] = useState(false);

  const openCsvModal = useCallback(() => setToggleCsvModal(true), []);
  // build the UI elements
  return (
    <>
      <List
        bulkActionButtons={false}
        sort={{field: 'id', order: 'DESC'}}
        actions={
          <TopToolbar>
            <CreateButton basePath={props.basePath} />
            <ExportButton props />
            <Button
              label="CSV 쿠폰 발급"
              style={{color: 'green'}}
              onClick={openCsvModal}></Button>
          </TopToolbar>
        }
        {...props}>
        <Datagrid rowClick={'edit'}>
          <TextField source="id" />
          <TextField source="title" />
          <TextField source="coupon_type" />
          <TextField source="discount_type" />
          <NumberField source="discount_amount" />
          <DateField source="start_datetime" />
          <DateField source="end_datetime" />
          <TextField source="status" />
        </Datagrid>
      </List>
      <IssueCsvCouponModal
        open={toggleCsvModal}
        onClose={() => setToggleCsvModal(false)}
      />
    </>
  );
}

export default withStyles(styles)(CouponList);
