
/* Covert file object to react-admin file object format */
export const transformFile = (file, {source = 'url', title = 'title'} = {}) => {
  if (!(file instanceof File)) {
    return file;
  }

  const preview = file.preview || URL.createObjectURL(file);
  const transformedFile = {
    rawFile: file,
    [source]: preview,
    [title]: file.name
  }

  return transformedFile;
};