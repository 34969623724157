const rowStyle = (record, index, defaultStyle = {}) => {
    if (record.status === 'cancelled')
        return { ...defaultStyle, backgroundColor: '#fdd' };
    if (record.status === 'complete')
        return { ...defaultStyle, backgroundColor: '#dfd' };
    if (record.status === 'shipping')
        return { ...defaultStyle, backgroundColor: '#daa' };
    return defaultStyle;
};

export default rowStyle;