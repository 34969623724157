import {withStyles} from '@material-ui/core';
import {
  DeleteButton,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
} from 'react-admin';
import UserLinkField from '../components/UserLinkField';
import React from 'react';

// component styling
const styles = {};

function RecommendStickerShow({classes, ...props}) {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="id" />
        <ReferenceField
          label="추천인"
          source="from_user_id"
          reference="users"
          link="show">
          <UserLinkField />
        </ReferenceField>
        <TextField label="발급 조건" source="action_type" />
        <TextField label="사용 여부" source="status" />
        <ReferenceField
          label="친구"
          source="recommendee.user_id"
          reference="users"
          link="show">
          <UserLinkField />
        </ReferenceField>
        <ReferenceField
          label="주문 정보"
          source="order_id"
          reference="orders"
          link="show">
          <TextField source="product.title" />
        </ReferenceField>
        <DeleteButton />
      </SimpleShowLayout>
    </Show>
  );
}

export default withStyles(styles)(RecommendStickerShow);
