import React from 'react';
import {useState} from 'react';
import {
    Button, ImageField, ImageInput,
    Loading, SelectInput, SimpleForm,
} from 'react-admin';
import { connect } from 'react-redux';
import {push} from 'connected-react-router';
import withStyles from '@material-ui/core/styles/withStyles';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import {API_URL} from '../config';
import {loadAccessToken} from '../lib/Auth';

const sizeChoices = [
    {id: 'big', name: 'big'},
    {id: 'medium', name: 'medium'},
    {id: 'small', name: 'small'},
];

const outOfAppModalStyles = {
    dialogContent: {
        textAlign: 'center'
    },
    dialogPaperWidthMd: {
        width: "100%"
    },
    extractButton: {
        margin: '10px'
    }
};

const ADD_IMAGE_URL = `${API_URL}/collections/images`;

const ExportImageUrlModal = withStyles(outOfAppModalStyles)((({classes, basePath, open, onClose, push, ...props}) => {
    const [images, setImages] = useState(null);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [size, setSize] = useState('big');

    const sendImage = async () => {
        const formData = new FormData();
        for (const image of images) {
            formData.append('images', image, image.name);
        }
        formData.append('size', size);

        setLoading(true);
        const response = await fetch(ADD_IMAGE_URL, {
            method: 'POST',
            headers: new Headers({
                Authorization: `bearer ${loadAccessToken()}`
            }),
            body: formData
        });
        const { result, errorMsg } = await response.json();
        if (result) {
            setData(result);
            setLoading(false);
            setImages(null);
            setSize('big');
        } else {
            alert(errorMsg);
            setLoading(false);
            setImages(null);
            setSize('big');
        }
    };

    const changeSize = ({target: {value}}) => {
        setSize(value);
    }

    const closeFn = () => {
        setData([]);
        onClose();
    }

    return (
        <Dialog open={open} onClose={closeFn} maxWidth="md" classes={{paperWidthMd: classes.dialogPaperWidthMd}}>
            <DialogTitle>이미지 Public URL 추출</DialogTitle>
            <DialogContent classes={{root: classes.dialogContent}}>
                {loading ?
                    <Loading loadingPrimary="Loading" loadingSecondary="waiting for extration..." />
                    :
                    <SimpleForm toolbar={<Button disabled={!images} variant="contained" className={classes.extractButton} label='Export' onClick={sendImage} />}>
                        <ImageInput source="images" accept="image/*" multiple={true} onChange={setImages}>
                            <ImageField source="url" title="title"/>
                        </ImageInput>
                        <SelectInput label='사이즈' source={'size'} choices={sizeChoices} onChange={changeSize}/>
                        {data?.length > 0 &&
                            <div>
                                <h2>추출된 URL</h2>
                                {data.map(image => <p>{image}</p>)}
                            </div>
                        }
                    </SimpleForm>
                }
            </DialogContent>
            <DialogActions>
                <Button disabled={loading} label="Cancel" onClick={closeFn} />
            </DialogActions>
        </Dialog>
    )
}))

export default connect(null, {
    push,
})(ExportImageUrlModal);
