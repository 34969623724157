import React from 'react';
import { Loading } from 'react-admin';
import PropTypes from 'prop-types';

import withStyles from '@material-ui/core/styles/withStyles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const styles = {
  card: {
    textAlign: 'left',
    minWdith: 240,
    maxWidth: 240,
  },
  cardHeader: {
    '& > .MuiCardHeader-content': {
      flex: '1 1 auto',
      width: '100%',
    },
    padding: '0.4rem',
  },
  cardHeaderTitle: {
    overflow: 'hidden',
    fontSize: '0.8rem'
  },
  cardSubheader: {
    fontSize: '0.5rem'
  },
  cardContent: {
    padding: '0.3rem 0.2rem 0 0.2rem'
  },
  cardAction: {
    width: '100%'
  },
  media: {
    height: 240,
  },
  
  /* custom */
  statusText: {
    display: 'inline-block',
    float: 'right',
    padding: '1px 5px',
  },
  descriptionText: {
    marginTop: '0.6rem',
    clear: 'both'
  },
  sold: {
    border: '1px solid',
    borderRadius: '4px',
    color: 'white',
    backgroundColor: '#ec2f4b',
  },
  selling: {}
};

function SimpleProductCard(props) {
  const [imgloaded, setImgLoaded] = React.useState(false);

  const handleImageLoad = () => {
    setImgLoaded(true);
  }

  const { classes, product } = props;
  return (
    <Card className={props.className || classes.card}>
      <CardHeader
          className={classes.cardHeader}
          classes={{title: classes.cardHeaderTitle, subheader: classes.cardSubheader}}
          title={product.title}
          titleTypographyProps={{
            noWrap: true
          }}
          subheader={`ID: ${product.id}`}
      />
      <CardMedia
        className={classes.media}
        title={product.title}
      >
        { !imgloaded && <Loading loadingPrimary="" loadingSecondary="" /> }
        <img
          style={{visibility: imgloaded ? 'visible' : 'hidden'}}
          className={classes.media}
          src={product.resizedSmallImages[0].url}
          onLoad={handleImageLoad}
        />
      </CardMedia>
      <CardContent className={classes.cardContent}>
        <Typography variant="caption" className={[classes.statusText, product.status === 'selling' ? classes.selling : classes.sold]}>
          {product.status}
        </Typography>
        <Typography component="p" noWrap className={classes.descriptionText}>
          {product.description}
        </Typography>
      </CardContent>
      <CardActions className={classes.cardAction}>
        {props.actions}
      </CardActions>
    </Card>
  );
}

SimpleProductCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SimpleProductCard);