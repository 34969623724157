export const BANK_LIST = [
    {
        name: '한국은행',
        code: '001',
    },
    {
        name: 'KDB산업은행', // name used by the current UI
        // name: '산업은행', // nicepay documentation
        code: '002',
    },
    {
        name: '기업은행',
        code: '003',
    },
    {
        name: '국민은행',
        code: '004',
    },
    {
        name: '외환은행',
        code: '005',
    },
    {
        name: '수협은행', // current UI
        // name: '수협중앙회', // nicepay
        code: '007',
    },
    {
        name: '수출입은행',
        code: '008',
    },
    {
        name: '농협은행', // current UI
        // name: '농협중앙회', // nicepay documentation
        code: '011',
    },
    {
        name: '농협회원조합',
        code: '012',
    },
    {
        name: '우리은행',
        code: '020',
    },
    {
        name: 'SC제일은행', // current UI 
        // name: 'SC은행', // nicepay documentation
        code: '023',
    },
    {
        name: '서울은행',
        code: '026',
    },
    {
        name: '씨티은행', // current UI
        // name: '한국씨티은행', // nicepay documentation
        code: '027',
    },
    {
        name: '대구은행',
        code: '031',
    },
    {
        name: '부산은행',
        code: '032',
    },
    {
        name: '광주은행',
        code: '034',
    },
    {
        name: '제주은행',
        code: '035',
    },
    {
        name: '전북은행',
        code: '037',
    },
    {
        name: '경남은행',
        code: '039',
    },
    {
        name: '새마을금고', // current UI
        // name: '새마을금고연합회', // nicepay
        code: '045',
    },
    {
        name: '신협', // current UI
        // name: '신협중앙회', // nicepay
        code: '048',
    },
    {
        name: '상호저축은행',
        code: '050',
    },
    {
        name: '기타', // current UI
        // name: '기타 외국계은행', // nicepay
        code: '051',
    },
    {
        name: '모건스탠리은행',
        code: '052',
    },
    {
        name: 'HSBC은행',
        code: '054',
    },
    {
        name: '도이치은행',
        code: '055',
    },
    {
        name: '알비에스피엘씨은행',
        code: '056',
    },
    {
        name: '제이피모간체이스은행',
        code: '057',
    },
    {
        name: '미즈호코퍼레이트은행',
        code: '058',
    },
    {
        name: '미쓰비시도쿄UFJ은행',
        code: '059',
    },
    {
        name: 'BOA',
        code: '060',
    },
    {
        name: '비엔피파리바은행',
        code: '061',
    },
    {
        name: '중국공상은행',
        code: '062',
    },
    {
        name: '중국은행',
        code: '063',
    },
    {
        name: '산림조합',
        code: '064',
    },
    {
        name: '대화은행',
        code: '065',
    },
    {
        name: '우체국', // current UI
        // name: '정보통신부 우체국', // nicepay
        code: '071',
    },
    {
        name: '신용보증기금',
        code: '076',
    },
    {
        name: '기술신용보증기금',
        code: '077',
    },
    {
        name: 'KEB하나은행', // current UI
        // name: '하나은행', // nicepay
        code: '081',
    },
    {
        name: '신한은행',
        code: '088',
    },
    {
        name: '케이뱅크',
        code: '089',
    },
    {
        name: '카카오뱅크',
        code: '090',
    },
    {
        name: '토스뱅크',
        code: '092',
    },
    {
        name: '한국주택금융공사',
        code: '093',
    },
    {
        name: '서울보증보험',
        code: '094',
    },
    {
        name: '경찰청',
        code: '095',
    },
    {
        name: '금융결제원',
        code: '099',
    },
    {
        name: '동양종합금융증권',
        code: '209',
    },
    {
        name: '현대증권',
        code: '218',
    },
    {
        name: '미래에셋증권',
        code: '230',
    },
    {
        name: '대우증권',
        code: '238',
    },
    {
        name: '삼성증권',
        code: '240',
    },
    {
        name: '한국투자증권',
        code: '243',
    },
    {
        name: 'NH투자증권',
        code: '247',
    },
    {
        name: '교보증권',
        code: '261',
    },
    {
        name: '하이투자증권',
        code: '262',
    },
    {
        name: '에이치엠씨투자증권',
        code: '263',
    },
    {
        name: '키움증권',
        code: '264',
    },
    {
        name: '이트레이드증권',
        code: '265',
    },
    {
        name: '에스케이증권',
        code: '266',
    },
    {
        name: '대신증권',
        code: '267',
    },
    {
        name: '솔로몬투자증권',
        code: '268',
    },
    {
        name: '한화증권',
        code: '269',
    },
    {
        name: '하나대투증권',
        code: '270',
    },
    {
        name: '신한금융투자',
        code: '278',
    },
    {
        name: '동부증권',
        code: '279',
    },
    {
        name: '유진투자증권',
        code: '280',
    },
    {
        name: '메리츠증권',
        code: '287',
    },
    {
        name: '엔에이치투자증권',
        code: '289',
    },
    {
        name: '부국증권',
        code: '290',
    },
    {
        name: '신영증권',
        code: '291',
    },
    {
        name: '엘아이지투자증권',
        code: '292',
    },
]
