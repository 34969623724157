import React from 'react';
import {Datagrid, DateField, Filter, List, NumberField, ReferenceField, TextField, TextInput } from 'react-admin';

import UserLinkField from "../components/UserLinkField";

/**
 * Sub-component for filtering a list of settlements.
 * @param props the react admin Filter component properties
 * @return {JSX.Element}
 * @constructor
 */
function SettlementFilter (props) {
    return (
        <Filter {...props}>
            <TextInput source="date" alwaysOn/>
            <TextInput source="status" alwaysOn/>
            <TextInput source="seq" alwaysOn/>
        </Filter>
    );
}

/**
 * Component for a list of settlements.
 * @param props the react admin List component properties
 * @return {JSX.Element}
 * @constructor
 */
export default function SettlementList({...props}) {
    // build the UI elements
    return (
        <List bulkActionButtons={false} filters={<SettlementFilter/>} sort={{field: 'id', order: 'DESC'}} {...props}>
            <Datagrid rowClick="show">
                <DateField source="createdAt"/>
                <TextField source="date"/>
                <TextField label="SEQ" source="seq"/>
                <NumberField source="amount" options={{style: 'currency', currency: 'KRW'}}/>
                <ReferenceField label="Seller" source="order.seller.user_id" reference="users">
                    <UserLinkField/>
                </ReferenceField>
                <TextField source="status"/>
                <TextField source="error"/>
            </Datagrid>
        </List>
    );
}
