import React from 'react';
import {
    AutocompleteArrayInput,
    AutocompleteInput,
    BooleanInput,
    Edit,
    FunctionField,
    Labeled,
    TextInput,
    maxLength,
    minLength,
    ReferenceArrayInput,
    ReferenceInput,
    required,
    SimpleForm
} from 'react-admin';

import {SortableImageInput} from "../components/ImageInput";
import {postUrl} from '../lib/url';
import {getResizedURL} from "../utils/util";

/**
 * Component for editing a posts.
 * @param props the react admin Edit component properties
 * @return {JSX.Element}
 * @constructor
 */
export default function PostEdit(props) {
    return (
        <Edit {...props}>
            <SimpleForm actions={false}>
                <TextInput source="id" disabled/>
                <ReferenceInput source="user_id" reference="users">
                    <AutocompleteInput optionText="username"/>
                </ReferenceInput>
                <BooleanInput source="is_visible" validate={required()}/>
                <TextInput multiline source="description" validate={[required(), minLength(1), maxLength(65535)]}/>
                <ReferenceArrayInput source="brand_ids" label="Brands" reference="brands">
                    <AutocompleteArrayInput/>
                </ReferenceArrayInput>
                <Labeled label="Images"/>
                <SortableImageInput
                    source="images"
                    accept="image/*"
                    previewUrlFormat={url => {
                        const isBlobUrl = typeof url === 'string' && url.match(/^blob:/);
                        return isBlobUrl ? url : getResizedURL(url, 'width620');
                    }}
                />
                <TextInput source="score" disabled/>
                <TextInput source="view_count" disabled/>
                <TextInput source="createdAt" disabled/>
                <TextInput source="updatedAt" disabled/>
                <TextInput source="deletedAt" disabled/>

                {/* Webapp and content feed links */}
                <FunctionField
                    label="Deep Link"
                    render={record => (
                        <a href={postUrl('fruitsfamily://', record)} target="_blank">
                            {postUrl('fruitsfamily://', record).toString()}
                        </a>
                    )}
                />
            </SimpleForm>
        </Edit>
    );
}
