import {
    CreateButton,
    Datagrid, DateField,
    DateTimeInput,
    Filter,
    List,
    SelectInput,
    TextField, TextInput,
    TopToolbar
} from "react-admin";
import rowStyle from "../orders/rowStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import React from "react";

export const notificationStatusChoices = [
    {id: 'scheduled', name: 'scheduled'},
    {id: 'sending', name: 'sending'},
    {id: 'pause', name: 'pause'},
    {id: 'sent', name: 'sent'},
    {id: 'failed', name: 'failed'},
];

export const notificationPushTypeChoices = [
    { id: 'AD', name: 'AD'},
    { id: 'CONTENTS', name: 'CONTENTS'}
];

const styles = {

}

/**
 * Sub-component for filtering a list of Notifications.
 * @param props the react admin Filter component properties
 * @return {JSX.Element}
 * @constructor
 */
function NotificationFilter(props) {
    return (
        <Filter {...props}>
            <DateTimeInput source='send_time' label="Send Time" alwaysOn />
            <TextInput source="name" label="Name" alwaysOn />
            <TextInput source="title" label="Title" alwaysOn />
            <SelectInput source="status" choices={notificationStatusChoices} alwaysOn />
        </Filter>
    );
}

/**
 * Component for a list of Notifications.
 * @param props the react admin List component properties
 * @return {JSX.Element}
 * @constructor
 */
function NotificationList({ ...props }) {
    // build the UI elements
    return (
        <List {...props} filters={<NotificationFilter />} actions={
            <TopToolbar>
                <CreateButton basePath={props.basePath}/>
            </TopToolbar>
        } sort={{ field: 'id', order: 'DESC' }}>
            <Datagrid rowClick="edit" rowStyle={rowStyle}>
                <DateField label="send_time" source="send_time" showTime />
                <TextField label="name" source="name" />
                <TextField label="title" source="title" />
                <TextField label="status" source="status" />
                <TextField label="user_group" source="target_user_group_type" />
                <TextField label="push_type" source="push_type" />
            </Datagrid>
        </List>
    );
}

export default withStyles(styles)(NotificationList);
