import React from 'react';
import {
    Datagrid,
    DateField,
    DateInput,
    Filter,
    ImageField,
    List,
    NumberField,
    TextField,
    TextInput
} from "react-admin";
import { withStyles } from "@material-ui/core";

// component styling
const styles = {
    imagePreview: {
        '& img': {
            maxWidht: '270px',
            maxHeight: '90px'
        }
    },
};

/**
 * Sub-component for filtering a list of seller collections.
 * @param props the react admin Filter component properties
 * @returns {JSX.Element}
 * @constructor
 */
function SellerCollectionFilter(props) {
    return (
        <Filter {...props}>
            <DateInput source="active_date" label="Active Date" alwaysOn />
            <TextInput source="search" label="Search" alwaysOn />
        </Filter>
    )
}

/**
 * Component for a list of seller collections.
 * @param props the react admin List component properties
 * @return {JSX.Element}
 * @constructor
 */
function SellerCollectionList({ classes, ...props }) {
    // build the UI elements
    return (
        <List bulkActionButtons={true} filters={<SellerCollectionFilter />} sort={{ field: "featured_until", order: "DESC" }} {...props}>
            <Datagrid rowClick={"edit"}>
                <TextField source="id" />
                <DateField source="featured_from" />
                <DateField source="featured_until" />
                <NumberField source="priority" />
                <ImageField source="resizedSmallImage.url" label="image" sortable={false} className={classes.imagePreview} />
                <TextField source="title" />
                <TextField source="tab_name" />
            </Datagrid>
        </List>
    )
}

export default withStyles(styles)(SellerCollectionList)