import {withStyles} from '@material-ui/core';
import {
  Button,
  Datagrid,
  DateField,
  ReferenceField,
  ReferenceManyField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  useShowController,
} from 'react-admin';
import React, {useState} from 'react';
import UserLinkField from '../components/UserLinkField';
import {API_URL} from "../config";
import {loadAccessToken} from "../lib/Auth";

// component styling
const styles = {};

// 초대코드 active 상태 변경
const ChangeReferralCodeActiveButton = ({ record }) => {
  const [isActive, setIsActive] = useState(record.is_active);

  const handleOnClick = async () => {
    if (window.confirm(`${isActive ? '코드 비활성화' : '코드 활성화'} 하시겠습니까?`)) {
      const response = await fetch(`${API_URL}/referral_codes/${record.id}`, {
        method: 'PUT',
        headers: new Headers({
          'Content-Type': 'application/json', // Content-Type 헤더 추가
          Authorization: `bearer ${loadAccessToken()}`
        }),
        body: JSON.stringify({
          is_active: !isActive
        })
      });

      if (response.status === 200) {
        alert('변경되었습니다.');
        setIsActive(!isActive);
      } else {
        alert('변경에 실패했습니다.');
      }
    }
  }

  return <Button variant="contained" label={isActive ? '코드 비활성화' : '코드 활성화'} onClick={handleOnClick} />
}

/**
 * Component for showing a referral code.
 * @param classes
 * @param props
 * @returns {React.JSX.Element|null}
 * @constructor
 */
function ReferralCodeShow({classes, ...props}) {
  const {record} = useShowController(props);

  if (!record) return null; // 데이터가 로드되지 않았을 때 처리

  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="초대 코드">
          <TextField source="code" />
          <ChangeReferralCodeActiveButton />
        </Tab>
        <Tab label="해당 초대 코드로 가입된 사용자">
          <ReferenceManyField
            reference="recommendees"
            label="해당 초대 코드로 가입된 사용자"
            filter={{code: record.code}}
            perPage={50}
            target={''}>
            <Datagrid>
              <ReferenceField
                label="친구"
                source="user_id"
                reference="users"
                link="show">
                <UserLinkField />
              </ReferenceField>
              <DateField label="생성일" source="created_at" showTime />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="스티커">
          <ReferenceManyField
            reference="recommend-stickers"
            label="스티커 리스트"
            filter={{user_id: record.user_id}}
            perPage={50}
            target={''}>
            <Datagrid>
              <TextField source="id" />
              <TextField label="발급조건" source="action_type" />
              <TextField label="사용여부" source="status" />
              <DateField label="생성일" source="created_at" showTime />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
}

export default withStyles(styles)(ReferralCodeShow);
