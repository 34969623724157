import {withStyles} from "@material-ui/core";
import {
    Edit,
    NumberInput,
    required,
    SelectInput,
    SimpleForm,
    TextInput
} from "react-admin";
import React from "react";
import {OTHER_PLATFORM, platformApplyStatusChoices} from "./BulkProductList";

// component styling
const styles = {

}

function BulkProductEdit({ classes, ...props }) {
    return (
        <Edit {...props}>
            <SimpleForm action={false}>
                <TextInput source="id" disabled />
                <TextInput source="seller_id" label="대상 셀러 아이디" required={true} />
                <TextInput source="platform_seller_id" label="플랫폼 셀러 ID" required={true} />
                <SelectInput source="platform" label="플랫폼 명" choices={OTHER_PLATFORM} required={true} />
                <TextInput source="platform_seller_url" label="URL" required={true} />
                <SelectInput source='status' label={"진행 상태"} choices={platformApplyStatusChoices} validate={required()} />
                <NumberInput source="shipping_fee" label="배송비" required={true} />
                <NumberInput source="shipping_fee_island" label="도서산간배송비" required={true} />
                <TextInput source="smartstore_key" label="네이버 스마트스토어 KEY" />
                <TextInput source="smartstore_secret_key" label="네이버 스마트스토어 SECRET KEY" />
            </SimpleForm>
        </Edit>
    )
}

export default withStyles(styles)(BulkProductEdit);
