import React, {useEffect, useState} from 'react';
import {
  SimpleForm,
  TextInput,
  DateTimeInput,
  required,
  SelectInput,
  useInput,
} from 'react-admin';
import {API_URL} from '../config';
import {loadAccessToken} from '../lib/Auth';
import withStyles from '@material-ui/core/styles/withStyles';

const IMAGE_TYPE = [
  {
    name: 'WARNING',
    value:
      'https://pikil-production.s3.ap-northeast-2.amazonaws.com/public/config/service-info/yellow-warning-icon.png',
  },
  {
    name: 'ERROR',
    value:
      'https://pikil-production.s3.ap-northeast-2.amazonaws.com/public/config/service-info/red-warning-icon.png',
  },
];

const ImageSelectInput = props => {
  const {
    input: {value, onChange},
    meta: {touched, error},
  } = useInput(props);

  return (
    <div>
      <SelectInput
        {...props}
        choices={IMAGE_TYPE}
        optionText="name"
        optionValue="value"
      />
      {touched && error && <span>{error}</span>}
      {value && (
        <div>
          <img
            src={value}
            alt="selected_image"
            style={{maxWidth: '100px', marginTop: '10px'}}
          />
        </div>
      )}
    </div>
  );
};

// 스타일 정의
const styles = {
  fullTextInput: {
    width: '100%',
  },
  sectionTitle: {
    width: '100%',
    fontSize: 20,
    fontWeight: 'bold',
    paddingTop: 8,
    paddingBottom: 8,
    borderBottom: '0.5px solid #000',
  },
  image: {
    maxWidth: '100%',
    height: 'auto',
  },
};

/**
 * 서비스 점검 안내 및 최소 버전 저장
 * @param {Object} props - 컴포넌트 속성
 * @returns {JSX.Element} 서비스 정보 수정 컴포넌트
 */
const ServiceInfoEdit = ({classes}) => {
  // 상태 관리
  const [initialValues, setInitialValues] = useState({
    title: '',
    message: '',
    description: '',
    start_datetime: '',
    end_datetime: '',
    image: '',
    label: '',
    min: '',
  });

  // 컴포넌트 마운트 시 데이터 로드
  useEffect(() => {
    fetchServiceInfo();
  }, []);

  /**
   * API 호출하여 서비스 정보를 가져오는 함수
   */
  const fetchServiceInfo = () => {
    fetch(`${API_URL}/fruits-service-info`, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${loadAccessToken()}`,
      }),
    })
      .then(response => response.json())
      .then(data => {
        setInitialValues({
          title: data.notice.title || '',
          message: data.notice.message || '',
          description: data.notice.description || '',
          start_datetime: data.notice.start_datetime || '',
          end_datetime: data.notice.end_datetime || '',
          image: data.notice.image || '',
          label: data.notice.label || '',
          min: data.version.min || '',
        });
      })
      .catch(error => {
        console.error('Failed to fetch data', error);
      });
  };

  /**
   * 서비스 정보를 저장하는 함수
   * @param {Object} data - 폼 데이터
   */
  const handleSave = data => {
    const body = {
      notice: {
        title: data.title,
        message: data.message,
        description: data.description,
        start_datetime: data.start_datetime,
        end_datetime: data.end_datetime,
        image: data.image,
        label: data.label,
      },
      version: {
        min: data.min,
      },
    };

    // API 호출하여 데이터를 저장
    fetch(`${API_URL}/fruits-service-info`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${loadAccessToken()}`,
      }),
      body: JSON.stringify(body),
    })
      .then(response => response.json())
      .then(_ => {
        alert('저장되었습니다.');
        window.location.reload();
      })
      .catch(error => {
        console.error('Failed to save data', error);
      });
  };

  /**
   * 최소버전 유효성 검사 함수
   * @param {string} value - 입력된 값
   * @returns {string|undefined} 오류 메시지 또는 undefined
   */
  const validateVersion = value => {
    const versionRegex = /^\d+\.\d+\.\d+$/;
    if (!value || !versionRegex.test(value)) {
      return '올바른 버전 형식(예: 0.0.0)을 입력해주세요.';
    }
    return undefined;
  };

  return (
    <SimpleForm initialValues={initialValues} save={handleSave}>
      <div className={classes.sectionTitle}>서비스 공지사항</div>
      <TextInput source="title" label="제목" validate={required()} />
      <TextInput
        source="description"
        label="설명"
        multiline
        className={classes.fullTextInput}
        validate={required()}
      />
      <TextInput
        source="message"
        label="메시지"
        multiline
        className={classes.fullTextInput}
      />
      <DateTimeInput
        source="start_datetime"
        label="시작 날짜/시간"
        validate={required()}
      />
      <DateTimeInput
        source="end_datetime"
        label="종료 날짜/시간"
        validate={required()}
      />
      <ImageSelectInput source="image" label="이미지 선택" />
      <TextInput source="label" label="로그 추적을 위한 Label" />
      <div className={classes.sectionTitle}>앱 서비스 최소버전</div>
      <TextInput
        source="min"
        label="최소버전"
        validate={[required(), validateVersion]}
      />
    </SimpleForm>
  );
};

export default withStyles(styles)(ServiceInfoEdit);
