const multipartProcess = (data) => {
    let body = new FormData()
    for (const prop in data) {
        const value = data[prop]
        console.log('VALUE : ',value)
        if (!value) continue
        if (value.hasOwnProperty('rawFile')) {  //rawFile이 있을 때
            body.append(prop, value.rawFile)
        } else if (typeof value === 'object') { //Object나 Array일 때
            if(Array.isArray(value) && value[0] && value[0].hasOwnProperty('rawFile') && !value[0].ignoreRawFile){
                value.map(val => {
                    body.append(prop, val.rawFile, val.rawFile.name)
                })
            }else{
                if (value instanceof Date) { // Date 타입일때만
                    body.append(prop, value);
                } else {
                    body.append(prop, JSON.stringify(value));
                }
            }
        } else {
            body.append(prop, value);
        }
    }
    for (var pair of body.entries()) {
        console.log(pair[0]+ ', ' ,pair[1]);
    }
    return body
}
export default multipartProcess
