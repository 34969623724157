import React, {useState, useEffect, Fragment} from 'react';
import {FormDataConsumer} from 'react-admin';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import styled from 'styled-components';
import {Field, useForm} from 'react-final-form';

import { arrayMove } from '../utils/basic';
import { downloadImage } from '../utils/util';
import { transformFile } from '../utils/react-admin';
import FileInput from './FileInput';

import LinkIcon from '../assets/icons/link.svg';

/* Image uploader */
const Preview = styled.div`
  padding: 5px;
  display: flex;
  flex-flow: row wrap;
`;
const PreviewItem = styled.div`
  position: relative;
  display: inline-block;
  background-color: white;
  padding: 14px;
  margin: 5px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  &:hover {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    & > .preview-item__button--delete, & > .preview-item__button--download {
      visibility: visible;
    }
  }
`;
const PreviewImage = styled.img`
  max-height: 10rem;
  user-select: none;
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  pointer-events: none;
`;
const DeleteButtonWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  top: 0;
  right: 0;
  user-select: none;

  padding: 0.4rem;
  &:hover {
    cursor: pointer;
    transform: scale(1.1);
  }
`;

const DeleteButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  border-radius: 50%;
  background-color: #e91d63;
  padding: 3px;
  color: white;
  font-size: 0.8rem;
  content: 'X';
  width: 18px;
  height: 18px;
`;

const ImageDownloadButtonWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 3px;
  padding: 3px;
  visibility: hidden;

  &:hover {
    cursor: pointer;
    transform: scale(1.1);
  }
  & > .download-icon {
    width: 24px;
    height: 24px;
    font-size: 1.2rem;
  }
`


const PreviewImageElement = SortableElement(({image, idx, previewUrlFormat, onDeleteItem, downloadOrLink = true}) => {
  const imageUrl = image.preview || image.url;
  const imageName = imageUrl.split('/').pop();
  const formattedImageUrl = typeof previewUrlFormat === 'function' ? previewUrlFormat(imageUrl) : imageUrl;
  return (<PreviewItem>
    <PreviewImage src={formattedImageUrl} draggable={false} />
    <DeleteButtonWrapper className="preview-item__button--delete" onClick={() => onDeleteItem(idx)} >
      <DeleteButton >X</DeleteButton>
    </DeleteButtonWrapper>

    { downloadOrLink &&
      <ImageDownloadButtonWrapper className="preview-item__button--download" onClick={() => downloadImage(formattedImageUrl, imageName)}>
        <img src={LinkIcon} className="download-icon" />
      </ImageDownloadButtonWrapper>
    }
  </PreviewItem>);
});

const PreviewImageList = SortableContainer(({images, previewUrlFormat, onDeleteItem}) => {
  return (
    <Preview>
      { images.map((image, index) => {
        return (
          <PreviewImageElement key={JSON.stringify(image)} index={index} idx={index} image={image} previewUrlFormat={previewUrlFormat} onDeleteItem={onDeleteItem}/>
        )
      })}
    </Preview>
  );
});

export const ImageInput = ({showPreview = true, initialValue, accept, previewUrlFormat, ...restProps}) => {
  const [files, setFiles] = useState(initialValue || []);
  const onDeletePreviewImage = (imageIndex) => {
    const newFiles = [...files];
    newFiles.splice(imageIndex, 1);
    setFiles(newFiles);
  };
  const onSortEnd = ({oldIndex, newIndex}) => {
    arrayMove(files, oldIndex, newIndex);
    setFiles([...files]);
  };

  useEffect(() => {
    if(!files.length && initialValue) {
      setFiles(initialValue);
    }
  }, [initialValue]);
  useEffect(() => {
    if(restProps.onChange && files !== initialValue) {
      restProps.onChange(files);
    }
  }, [files, initialValue]);

  return (
    <Fragment>
      <FileInput values={files} setValues={setFiles} accept={accept} />
      { showPreview && 
        <PreviewImageList helperClass='preview-image-list' images={files} previewUrlFormat={previewUrlFormat} onDeleteItem={onDeletePreviewImage} onSortEnd={onSortEnd} axis="xy" />
      }
    </Fragment>
  );
}

export const ImageInputForReactAdmin = ({title = 'title', separateRawFiles = true, rawFilesSourceName, ...restProps}) => {
  return (
    <FormDataConsumer>
      { () => {
        const form = useForm();
        const onChange = (imageFiles) => {
          const reactAdminImageFiles = imageFiles.map(imageFile => transformFile(imageFile, {title}));
          /* Rawfile is user uploaded file, does not previous uploaded image*/
          const onlyRawFiles = reactAdminImageFiles
            .filter(imageFile => imageFile.rawFile instanceof File)
            .map(imageFile => ({...imageFile})); // shallow copy
      
          if(separateRawFiles) {
            reactAdminImageFiles.forEach(image=> typeof image === 'object' && (image.ignoreRawFile = true));
          }


          if(restProps?.input) {
            if(restProps.input?.onChange) {
              restProps.input.onChange(reactAdminImageFiles);
            }
            if(separateRawFiles) {
              /* to meet 'multer'(file handling package) form */
              const rawFilesName = rawFilesSourceName || `${restProps.input.name}RawFiles`
              form.change(rawFilesName, onlyRawFiles)
            }
          }
        };

        return (
          <ImageInput onChange={onChange} {...restProps} initialValue={restProps?.meta?.initial} />
        )
      }}
      
    </FormDataConsumer>
  )
}

export const Dummy = () => {
  return (<div></div>)
}
export const SortableImageInput = ({source, separateRawFiles, rawFilesSourceName, ...restProps}) => {
  return (
    <Fragment>
      <Field name={source} component={ImageInputForReactAdmin} separateRawFiles={separateRawFiles} rawFilesSourceName={rawFilesSourceName} {...restProps} />
      <Field name={rawFilesSourceName || `${source}RawFiles`} component={Dummy} {...restProps} />
    </Fragment>
  )
};

export default ImageInput;