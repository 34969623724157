import {withStyles} from "@material-ui/core";
import {
    Datagrid,
    EditButton, ImageField, NumberField, Pagination,
    ReferenceManyField,
    Show,
    Tab, TabbedShowLayout, TextField,
} from "react-admin";
import React from "react";

// component styling
const styles = {
    image: {
        '& img': {
            maxWidht: '60px',
            maxHeight: '60px'
        }
    }
}

function BulkProductShow({ classes, ...props }) {
    return (
        <Show {...props}>
            <TabbedShowLayout>
                <Tab label="등록 정보">
                    <TextField source="id" disabled />
                    <TextField source="seller_id" label="대상 셀러 아이디" />
                    <TextField source="platform_seller_id" label="플랫폼 셀러 ID" />
                    <TextField source="platform" label="플랫폼 명" />
                    <TextField source="platform_seller_url" label="URL" />
                    <TextField source="status" label="진행 상태" />
                    <NumberField source="shipping_fee" label="배송비" />
                    <NumberField source="shipping_fee_island" label="도서산간배송비" />
                    <TextField source="smartstore_key" label="네이버 스마트스토어 KEY" />
                    <TextField source="smartstore_secret_key" label="네이버 스마트스토어 SECRET KEY" />
                </Tab>
                <Tab label="가져온 상품">
                    <ReferenceManyField
                        reference="platform-temp-product"
                        target="apply_id"
                        label="상품 목록"
                        sort={{field: "id", order: "DESC"}}
                        pagination={<Pagination />}
                        perPage={10}
                    >
                        <Datagrid rowClick="edit">
                            <TextField source="id"/>
                            <ImageField source="resizedSmallImages.0.url" label="image" sortable={false} className={classes.image}/>
                            <TextField source="title"/>
                            <TextField source="category"/>
                            <TextField source="brand"/>
                            <TextField source="gender"/>
                            <TextField source="price"/>
                            <TextField source="error"/>
                            <TextField source="status"/>
                            <EditButton/>
                        </Datagrid>
                    </ReferenceManyField>
                </Tab>
            </TabbedShowLayout>
        </Show>
    )
}

export default withStyles(styles)(BulkProductShow);
