import {withStyles} from "@material-ui/core";
import {
    AutocompleteInput,
    Edit, FormDataConsumer,
    NumberInput, ReferenceInput,
    SelectInput,
    SimpleForm, TextField,
    TextInput
} from "react-admin";
import React from "react";
import {useFetch} from "../hooks/useFetch";
import {API_URL} from "../config";
import {CategoriesSelectInput, conditionChoices} from "../products";
import {getResizedURL} from "../utils/util";
import {SortableImageInput} from "../components/ImageInput";

// component styling
const styles = {

}

function CrawledProductEdit({ classes, ...props }) {
    const data = useFetch(`${API_URL}/all_categories`);
    const all_categories = data?.result.all_categories;

    return (
        <Edit {...props}>
            <SimpleForm action={false}>
                <TextInput source="id" disabled />
                <TextField source='error' label='Error' />
                <CategoriesSelectInput all_categories={all_categories} />
                <TextInput source='brand' label='브랜드' disabled />
                <ReferenceInput label='브랜드 변경' source="brand_id" reference="brands" perPage={100} sort={{field: 'name', order: 'ASC'}} allowEmpty>
                    <AutocompleteInput />
                </ReferenceInput>
                <SelectInput source="gender" optionText="name" optionValue="name" choices={[{'name': 'unisex'}, {'name': 'men'}, {'name': 'women'}]}/>
                <SelectInput source="condition" optionValue="value" choices={conditionChoices}/>
                <TextInput label="Title" source="title"/>
                <TextInput multiline label="Description" source="description" />
                <div/>
                <NumberInput label="OriginalPrice" source="original_price"/>
                <NumberInput label="Price" source="price" />
                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        <NumberInput {...rest} label="Shipping_fee" source="shipping_fee" />
                    }
                </FormDataConsumer>
                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        <NumberInput {...rest} label="Shipping_fee_island" source="shipping_fee_island" />
                    }
                </FormDataConsumer>
                <SortableImageInput source="images" accept="image/*" previewUrlFormat={url => {
                    const isBlobUrl = typeof url === 'string' && url.match(/^blob:/);
                    return isBlobUrl ? url : getResizedURL(url, 'width620');
                }} />
            </SimpleForm>
        </Edit>
    )
}

export default withStyles(styles)(CrawledProductEdit);
