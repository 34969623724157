import React from 'react';
import {
    Create, ImageField, ImageInput,
    SimpleForm,
} from 'react-admin';

/**
 * Component for creating Onboarding Lookbook.
 * @param props the react admin Create component properties
 * @return {JSX.Element}
 * @constructor
 */
export default function OnboardingLookbookCreate(props) {
    return (
        <Create {...props}>
            <SimpleForm actions={false}>
                <ImageInput accept="image/*" source="images" multiple={true}>
                    <ImageField/>
                </ImageInput>
            </SimpleForm>
        </Create>
    );
}
