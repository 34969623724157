import React, { useState, useEffect } from 'react';
import { loadAccessToken } from '../../lib/Auth';

export const useFetch = (url, options = {}) => {
  const [data, updateData] = useState(undefined);
  
  useEffect(() => {
    async function fetchData() {
      const defaultOptions = {
        headers: new Headers({
          'content-type' : 'application/json',
          Authorization: `bearer ${loadAccessToken()}`
        }),
      };
      const requestOptions = Object.assign(defaultOptions, options);
      const response = await fetch(url, requestOptions);
      const json = await response.json();
      updateData(json);
    }
    fetchData();
  }, [url]);

  return data;
};
