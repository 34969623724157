import {
    DateField,
    DateTimeInput,
    Edit, FormDataConsumer,
    ImageField,
    ImageInput,
    required,
    SelectInput,
    SimpleForm, TextField,
    TextInput, useRecordContext
} from "react-admin";
import {getResizedURL} from "../utils/util";
import React, {Fragment, useCallback, useState} from "react";
import {notificationPushTypeChoices, notificationStatusChoices} from "./NotificationList";
import NotificationTestModal from "./NotificationTestModal";
import Button from "@material-ui/core/Button";
import {API_URL} from "../config";
import {loadAccessToken} from "../lib/Auth";
import { saveAs } from "file-saver";

//대상자 다운로드
const TargetDownloadButton = () => {
    const record = useRecordContext();

    const handleOnClickDownload = useCallback(async () => {
        // API 요청 로직...
        const response = await fetch(`${API_URL}/push-reservations/${record.id}/csv_targets`, {
            method: 'GET',
            headers: new Headers({
                Authorization: `bearer ${loadAccessToken()}`
            }),
        });

        if (response.status === 200) {
            const blob = await response.blob();
            saveAs(blob, 'partial_push_user_list.csv');
        }
    }, [record]);

    return record?.target_user_group_type === 'partial' ?
        <Button variant="contained" color="primary" onClick={handleOnClickDownload} style={{marginTop: 20}}>
            대상자 다운로드
        </Button> : <></>;
};

/**
 * Component for an Editing of Notifications.
 * @param props
 * @constructor
 */
export default function NotificationEdit(props) {
    const [isTestModalOpen, setTestModalOpen] = useState(false);

    return (
        <Fragment>
            <Edit {...props}>
                <SimpleForm>
                    <TextInput source="name" label={"알림 이름"} validate={required()} />
                    <TextInput source="title" label={"알림 제목"} validate={required()} />
                    <TextInput source="body" label={"알림 내용"} multiline={true} validate={required()} />
                    <ImageInput source="image" accept="image/*" multiple={false} format={image => {
                        if (typeof image === 'string') {
                            return image.match(/^blob:/) ? {url: image} : {url: getResizedURL(image, 'width256')};
                        } else {
                            return image;
                        }
                    }}>
                        <ImageField source='url' />
                    </ImageInput>
                    <TextInput source="type" label={"알림 Type"} validate={required()} disabled={true} defaultValue={'deeplink'} />
                    <TextInput source="sub_type" label={"알림 sub_type"} validate={required()} />
                    <DateTimeInput source="send_time"
                                   validate={required()} />
                    <TextInput source="firebase_label" label={"firebase_label"} validate={required()} />
                    <TextInput source='created_by' label={"생성 id"} disabled/>
                    <SelectInput source='status' label={"상태"} choices={notificationStatusChoices} validate={required()} />
                    <TextField source='target_user_group_type' label={"user_group"} />
                    <DateField source='createdAt' label={"생성일"} showTime />
                    <DateField source='updatedAt' label={"수정일"} showTime />
                    <SelectInput label="push_type" source="push_type" choices={notificationPushTypeChoices}/>
                    <Button variant="outlined" color={"primary"} onClick={() => setTestModalOpen(true)}>테스트 전송</Button>
                    <FormDataConsumer>
                        {({formData}) => {
                            return <NotificationTestModal formData={formData} open={isTestModalOpen} onClose={() => setTestModalOpen(false)}/>
                        }}
                    </FormDataConsumer>
                    <TargetDownloadButton />
                </SimpleForm>
            </Edit>
        </Fragment>
    );
}
