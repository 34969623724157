import React from 'react';
import {
    AutocompleteArrayInput,
    AutocompleteInput,
    BooleanInput,
    Create,
    Labeled,
    maxLength,
    minLength,
    ReferenceArrayInput,
    ReferenceInput,
    required,
    SimpleForm,
    TextInput
} from 'react-admin';

import {SortableImageInput} from "../components/ImageInput";
import {getResizedURL} from "../utils/util";

/**
 * Component for creating a post.
 * @param props the react admin Create component properties
 * @return {JSX.Element}
 * @constructor
 */
export default function PostCreate(props) {
    return (
        <Create {...props}>
            <SimpleForm actions={false}>
                <ReferenceInput source="user_id" reference="users" validate={required()}>
                    <AutocompleteInput optionText="username"/>
                </ReferenceInput>
                <BooleanInput source="is_visible" defaultValue={true} validate={required()}/>
                <TextInput multiline source="description" validate={[required(), minLength(1), maxLength(65535)]}/>
                <ReferenceArrayInput source="brand_ids" label="Brands" reference="brands">
                    <AutocompleteArrayInput/>
                </ReferenceArrayInput>
                <Labeled label="Images"/>
                <SortableImageInput
                    source="images"
                    accept="image/*"
                    previewUrlFormat={url => {
                        const isBlobUrl = typeof url === 'string' && url.match(/^blob:/);
                        return isBlobUrl ? url : getResizedURL(url, 'width620');
                    }}
                />
            </SimpleForm>
        </Create>
    );
}
