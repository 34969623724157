import {withStyles} from '@material-ui/core';
import {
  AutocompleteInput,
  Create,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm, TextInput,
} from 'react-admin';
import React from 'react';

// component styling
const styles = {};

/**
 * Component for editing a referral code.
 * @param classes
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function RecommendStickerCreate({classes, ...props}) {
  return (
    <Create {...props}>
      <SimpleForm action={false}>
        <ReferenceInput
          label="추천인 (스티커를 받을 사용자)"
          source="from_user_id"
          reference="users"
          validate={required()}>
          <AutocompleteInput optionText="username" />
        </ReferenceInput>
        <ReferenceInput
          label="친구 (판매/구매를 한 사용자)"
          source="user_id"
          reference="users"
          validate={required()}>
          <AutocompleteInput optionText="username" />
        </ReferenceInput>
        <TextInput source={"order_id"} label={"주문번호"} validate={required()} />
        <SelectInput
          label="발급 조건 (BUY - 구매 , SELL - 판매)"
          source="action_type"
          optionValue="name"
          choices={[{name: 'BUY'}, {name: 'SELL'}]}
        />
      </SimpleForm>
    </Create>
  );
}

export default withStyles(styles)(RecommendStickerCreate);
