// in src/users.js
import React, {Fragment, useCallback, useEffect, useState} from 'react';
import {
    ArrayField,
    ArrayInput,
    List,
    Button,
    CreateButton,
    Datagrid,
    DeleteButton,
    TextField,
    EmailField,
    Labeled,
    RichTextField,
    TextInput,
    NumberField,
    EditButton,
    ImageInput,
    TabbedForm,
    TopToolbar,
    FormTab,
    ReferenceManyField,
    ImageField,
    Filter,
    Show,
    TabbedShowLayout,
    Tab,
    ReferenceField,
    DateField,
    DateInput,
    DateTimeInput,
    BooleanField,
    Edit,
    FormDataConsumer,
    Create,
    BooleanInput,
    SelectInput,
    SimpleForm,
    SimpleFormIterator,
    ExportButton,
    FunctionField,
    Pagination,
    useRefresh,
    /* Validators */
    required,
    minLength,
    maxLength,
    withDataProvider,
    GET_LIST,
    crudUpdateMany,
    Confirm,
    useRecordContext,
    useRedirect,
} from 'react-admin';
import { useDispatch } from 'react-redux';
import {FormControlLabel, Switch} from '@material-ui/core';

import BooleanNumField from "../components/BooleanNumField";
import StarRatingField from "../components/StarRatingField";
import AvatarField from "../components/AvatarField";
import ProductImageField from "../components/ProductImageField";
import WarningText from "../components/WarningText";
import UserLinkField from '../components/UserLinkField';
import { StandaloneMassiveAutocomplete } from '../components/MassiveAutocompleteInput';
import { BANK_LIST } from "../utils/banks"
import {API_URL, WEBAPP_URL} from "../config";
import { sellerUrl } from '../lib/url';
import dataProvider from '../dataProvider';
import {Table, TableBody, TableCell, TableHead, TableRow} from '@material-ui/core';
import {loadAccessToken} from "../lib/Auth";

const styles = {
  date: {
      whiteSpace: 'nowrap'
  },
  inline: {
      display: 'inline',
      marginRight: 20
  },
  toolbar: {
      display: 'flex',
      justifyContent: 'space-between',
  },
  table: {
      width: 'auto',
      tableLayout: 'fixed',
  },
  tableHead: {
      fontWeight: 'bold',
      backgroundColor: '#f5f5f5',
      width: '100px',
      whiteSpace: 'nowrap',
      border: '1px solid #e0e0e0',
      textAlign: 'center'
  },
  tableCell: {
      textAlign: 'center',
      border: '1px solid #e0e0e0',
      whiteSpace: 'nowrap'
  }
}

const MultilineTextField = ({ source }) => {
  const record = useRecordContext();
  const formattedText = record && record[source]
    ? record[source].match(/.{1,110}/g).join('\n')
    : '';

  return record ? (
      <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word', textAlign: 'left' }}>
          {formattedText}
      </pre>
  ) : null;
};

const UserFilter = (props) => {
    const visibleAndCertifiedChoices = [
        { value: null, name: 'All' },
        { value: true, name: 'True' },
        { value: false, name: 'False' },
    ];
    const statusChoices = [
        { value: null, name: 'All' },
        { value: 'active', name: 'Active' },
        { value: 'left', name: 'Deleted' },
        { value: 'banned', name: 'Banned' },
    ];
    const sellerTypeChoices = [
        { value: null, name: 'All' },
        { value: true, name: '전문 판매자' },
        { value: false, name: '개인 판매자' },
    ]
    const isSellerChoices = [
        { value: 'all', name: 'All' },
        { value: true, name: 'True' },
        { value: false, name: 'False' },
    ]
    const platformChoices = [
        { value: null, name: 'All' },
        { value: 'MOBILE', name: 'Mobile' },
        { value: 'WEB', name: 'Web' },
    ]

    return (
        <Filter {...props}>
            <SelectInput label="상태" source="status" allowEmpty={false} choices={statusChoices} optionValue="value" alwaysOn/>
            <SelectInput label="가입경로" source="platform" allowEmpty={false} choices={platformChoices} optionValue="value" alwaysOn/>
            <SelectInput label="모범셀러" source="is_certified" allowEmpty={false} choices={visibleAndCertifiedChoices} optionValue="value" alwaysOn/>
            <SelectInput source="is_visible" allowEmpty={false} choices={visibleAndCertifiedChoices} optionValue="value" alwaysOn/>
            <TextInput label="id" source="id" alwaysOn/>
            <TextInput label="닉네임" source="nickname" alwaysOn/>
            <TextInput label="유저네임" source="username" alwaysOn/>
            <TextInput label="이메일" source="email" alwaysOn/>
            <TextInput label="전화번호" source="phone" alwaysOn/>
            <TextInput label="instagram" source="instagram" alwaysOn/>
            <SelectInput source="is_business_account" choices={sellerTypeChoices} optionValue="value" />
            <BooleanInput label="Ad Permissions" source="is_allow_ads" defaultValue={false}/>
            <BooleanInput source="ban_chat" defaultValue={false} />
            <BooleanInput source="ban_community" defaultValue={false} />
            <BooleanInput source="ban_marketplace" defaultValue={false} />
            <DateInput label="sign up from" source="createdAt_from" />
            <DateInput label="sign up until" source="createdAt_until" />
            <DateInput label="last active from" source="activeAt_from" />
            <DateInput label="last active until" source="activeAt_until" />
            <SelectInput source="is_seller" allowEmpty={false} choices={isSellerChoices} optionValue="value"/>
        </Filter>
    );
}

const CustomBooleanField = ({ source }) => {
  const record = useRecordContext();
  if (!record || !source) return null;

  const getValue = (record, source) => {
      return source.split('.').reduce((value, key) => value?.[key], record);
  };

  const value = getValue(record, source);

  return (
      <span style={{
          color: value ? '#28a745' : '#dc3545',
          fontWeight: 'bold'
      }}>
          {value ? 'O' : 'X'}
      </span>
  );
};

const AddtoSellerCollectionButton = withDataProvider(({ basePath, selectedIds, guideCollectionId }) => {
    const dispatch = useDispatch();
    function addCollectionSellers() {
        if (!guideCollectionId) {
            alert('collection을 선택해주세요.')
            return;
        }
        dispatch(crudUpdateMany('guide-collection-sellers', selectedIds, { ids: selectedIds, guideCollectionId }, basePath))
    }

    return (
        <Button label="Seller Collection 추가" onClick={addCollectionSellers} />
    )
})

function PostBulkActionButtons(props) {
    const [sellerCollection, setSellerCollection] = useState([]);
    const [sellerCollectionSearchText, setSellerCollectioSearchText] = useState('');
    const [selectedSellerCollection, setSelectedSellerCollection] = useState({});

    async function fetchSellerCollections() {
        const response = await dataProvider(GET_LIST, 'guide-collections', {
            pagination: { page: 1, perPage: 10000000 },
            sort: { field: 'id', order: 'DESC' }
        });
        setSellerCollection(response.data);
    }

    const onSuggestionSelected = useCallback(({ suggestion }) => {
        setSelectedSellerCollection(suggestion);
    }, []);

    useEffect(() => {
        fetchSellerCollections();
    }, []);

    const collectionAdderStyle = {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '5px',
        borderRadius: '3px',
        boxShadow: '0 8px 8px 1px rgba(0,0,0,0.14), 0 6px 10px 5px rgba(0,0,0,0.12), 0 8px 5px -5px rgba(0,0,0,0.3)'
    }
    return (
        <div style={collectionAdderStyle}>
            <StandaloneMassiveAutocomplete optionValue="tab_name" suggestions={sellerCollection}
                onSuggestionSelected={onSuggestionSelected}
                input={{ value: sellerCollectionSearchText, onChange: (newValue) => setSellerCollectioSearchText(newValue) }}
            />
            <AddtoSellerCollectionButton {...props} guideCollectionId={selectedSellerCollection.id} />
        </div>
    )
}

const UserShowActions = ({basePath, data, resource}) => {
    return <TopToolbar>
        <EditButton basePath={basePath} record={data}/>
    </TopToolbar>
}

const UserListActions = ({
    bulkActionButtons,
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    total
}) => (
    <TopToolbar>
        {bulkActionButtons && React.cloneElement(bulkActionButtons, {
            basePath,
            filterValues,
            resource,
            selectedIds,
            onUnselectItems,
        })}
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        }) }
        <CreateButton basePath={basePath} />
        <ExportButton
            disabled={total === 0}
            resource={resource}
            sort={currentSort}
            filter={filterValues}
            exporter={exporter}
            maxResults={1000000}
        />
    </TopToolbar>
);

/**
 * Sold 상품 숨김 처리 버튼
 * @returns {JSX.Element}
 * @constructor
 */
const ProductHideButton = ({ isSoldProduct = false}) => {
    const record = useRecordContext();
    const [isOpen, setIsOpen] = useState(false);

    const closeConfirm = () => {
        setIsOpen(false);
    }

    const handleOnConfirm = async () => {
        closeConfirm();

        const suffixUrl = isSoldProduct ? 'sold' : 'sell';

        const res = await fetch(`${API_URL}/products/hide/${suffixUrl}`, {
            method: 'POST',
            headers: new Headers({
                'content-type': 'application/json',
                Authorization: `bearer ${loadAccessToken()}`
            }),
            body: JSON.stringify({sellerId: record.id})
        });
        if (res.ok) {
            alert('상품이 숨김 처리 되었습니다.');
        } else {
            const errorData = await res.json();
            const errorMessage = errorData.error;
            alert(errorMessage);
        }
    }

    return (
        <>
            <Button style={{ backgroundColor: '#ff0000' }} variant='contained' label={isSoldProduct? '판매완료 상품 숨김' : '판매중 상품 숨김'} onClick={() => {setIsOpen(true)}} />

            {isOpen &&
                <Confirm
                    isOpen={true}
                    title={isSoldProduct? '판매 완료 상품을 모두 숨김 처리 하시겠어요?' : '판매 중인 상품을 모두 숨김 처리 하시겠어요?'}
                    content={<div style={{ color: '#ff0000' }}>숨김 처리가 된 상품은 다시 복구할 수 없습니다.</div>}
                    onConfirm={handleOnConfirm}
                    onClose={closeConfirm}
                />
            }
        </>
    )
}

const RequestIndexingButton = () => {
    const record = useRecordContext();
    const [isOpen, setIsOpen] = useState(false);

    const closeConfirm = () => {
        setIsOpen(false);
    }

    const handleOnConfirm = async() => {
        closeConfirm();
        const res = await fetch(`${API_URL}/es/sellers/product-indexing`, {
            method: 'POST',
            headers: new Headers({
                'content-type': 'application/json',
                Authorization: `bearer ${loadAccessToken()}`
            }),
            body: JSON.stringify({sellerId: record.id})
        });

        if (res.ok) {
            alert('누락 상품 인덱싱 요청에 성공하였습니다.');
        } else {
            const errorData = await res.json();
            const errorMessage = errorData.error;
            alert(errorMessage);
        }
    }

    return (
        <>
            <Button style={{ backgroundColor: 'blue' }} variant='contained' label={'인덱싱 요청'} onClick={() => {setIsOpen(true)}} />

            {isOpen &&
                <Confirm
                    isOpen={true}
                    title={'누락 상품에 대한 인덱싱을 요청하시겠어요?'}
                    content={`해당 셀러(${record.username})의 누락상품에 대해 요청합니다.`}
                    onConfirm={handleOnConfirm}
                    onClose={closeConfirm}
                />
            }
        </>
    )
}

export const UserList = props => (
    <List
        {...props}
        filters={<UserFilter />}
        actions={<UserListActions />}
        sort={{ field: 'id', order: 'DESC' }}
        bulkActionButtons={<PostBulkActionButtons />}
    >
        <Datagrid rowClick="show">
            <TextField source="id"/>
            <AvatarField label="Image" source="resizedSmallImage"/>
            <TextField label="Nickname" source="nickname"/>
            <TextField label="Username" source="username"/>
            <EmailField label="Email" source="email"/>
            <EmailField label="Phone" source="phone"/>
            <BooleanNumField label="Visible" source="seller.is_visible"/>
            <BooleanNumField label="Certified" source="seller.is_certified"/>
            <BooleanNumField label="Business Account" source="seller.is_business_account"/>
            <DateField label="active At" source="activeAt"/>
            <DateField label="Created at" source="createdAt"/>
            <TextField label="Instagram" source="instagram"/>
        </Datagrid>
    </List>
);

const LastPasswordChangeDate = ({ record, label, ...rest }) =>
    record && record.login_type === 'email'
        ? (
            <Labeled label={ label || "Last password change date"}>
                <DateField  source="user_secret.updatedAt" record={record} {...rest}/>
            </Labeled>
        )
        : null;

const ReportTypeField = ({ record }) => {
    const reportTypeCount = record?.report_type_count;
    if (!reportTypeCount) return null;

    let str = '';
    Object.entries(reportTypeCount).forEach(([key, value]) => {
        str += `${key} 신고: ${value}개 `;
    });

    return <span>{str.trim()}</span>;
};


export const UserShow = props => {
    const onSuccess = useRefresh();
    const redirect = useRedirect();

    const handleRowClick = (id) => {
        redirect(`/reports/${id}`);
    };

    return(
    <Show {...props} actions={<UserShowActions/>}>
        <TabbedShowLayout>
            <Tab label="User">
                <Table style={styles.table}>
                   <TableHead>
                    <TableRow>
                      <TableCell style={styles.tableHead}>프로필 이미지</TableCell>
                      <TableCell style={styles.tableHead}>커버 이미지</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableCell style={styles.tableCell}>
                      <ImageField label="resizedSmallImage" source="resizedSmallImage"/>
                    </TableCell>
                    <TableCell style={styles.tableCell}>
                      <ImageField label="resizeBigCoverImage" source="resizeBigCoverImage"/>
                    </TableCell>
                  </TableBody>
                </Table>
                <h3>유저 정보</h3>
                <Table style={styles.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell style={styles.tableHead}>아이디</TableCell>
                      <TableCell style={styles.tableHead}>최근 활동일</TableCell>
                      <TableCell style={styles.tableHead}>유저 네임</TableCell>
                      <TableCell style={styles.tableHead}>닉네임</TableCell>
                      <TableCell style={styles.tableHead}>이메일</TableCell>
                      <TableCell style={styles.tableHead}>전화번호</TableCell>
                      <TableCell style={styles.tableHead}>성별</TableCell>
                      <TableCell style={styles.tableHead}>인스타그램</TableCell>
                      <TableCell style={styles.tableHead}>거래 수</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell style={styles.tableCell}>
                        <TextField label="id" source="id"/>
                      </TableCell>
                      <TableCell style={styles.tableCell}>
                        <DateField  label="activeAt" source="activeAt"/>
                      </TableCell>
                      <TableCell style={styles.tableCell}>
                        <TextField label="username" source="username"/>
                      </TableCell>
                      <TableCell style={styles.tableCell}>
                        <TextField label="nickname" source="nickname"/>
                      </TableCell>
                      <TableCell style={styles.tableCell}>
                        <TextField label="email" source="email"/>
                      </TableCell>
                      <TableCell style={styles.tableCell}>
                        <TextField label="phone" source="phone"/>
                      </TableCell>
                      <TableCell style={styles.tableCell}>
                        <TextField label="gender" source="gender"/>
                      </TableCell>
                      <TableCell style={styles.tableCell}>
                        <TextField label="instagram" source="instagram"/>
                      </TableCell>
                      <TableCell style={styles.tableCell}>
                        <TextField label="거래 수" source="order_count"/>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <h3>상점 설명</h3>
                <Table style={styles.table}>
                  <TableBody>
                      <TableCell style={styles.tableCell}>
                        <MultilineTextField label="bio" source="bio"/>
                      </TableCell>
                  </TableBody>
                </Table>
                <h3>유저 상태</h3>
                <Table style={styles.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell style={styles.tableHead}>상태</TableCell>
                      <TableCell style={styles.tableHead}>모범셀러</TableCell>
                      <TableCell style={styles.tableHead}>is visible</TableCell>

                      <TableCell style={styles.tableHead}>채팅 차단 (ban_chat)</TableCell>
                      <TableCell style={styles.tableHead}>커뮤니티 차단 (ban_community)</TableCell>
                      <TableCell style={styles.tableHead}>마켓 차단 (ban_marketplace)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell style={styles.tableCell}>
                        <TextField label="status" source="status"/>
                      </TableCell>
                      <TableCell style={styles.tableCell}>
                        <CustomBooleanField label="is certified for seller" source="seller.is_certified"/>
                      </TableCell>
                      <TableCell style={styles.tableCell}>
                        <CustomBooleanField label="is visibled" source="seller.is_visible"/>
                      </TableCell>
                      <TableCell style={styles.tableCell}>
                        <CustomBooleanField label="ban_chat" source="ban_chat"/>
                      </TableCell>
                      <TableCell style={styles.tableCell}>
                        <CustomBooleanField label="ban_community" source="ban_community"/>
                      </TableCell>
                      <TableCell style={styles.tableCell}>
                        <CustomBooleanField label="ban_marketplace" source="ban_marketplace"/>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <h3>계정 정보</h3>
                <Table style={styles.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell style={styles.tableHead}>가입경로</TableCell>
                      <TableCell style={styles.tableHead}>로그인 타입</TableCell>
                      <TableCell style={styles.tableHead}>가입일</TableCell>
                      <TableCell style={styles.tableHead}>업데이트일</TableCell>
                      <TableCell style={styles.tableHead}>최근 비밀번호 변경일</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell style={styles.tableCell}>
                        <TextField label="platform" source="platform"/>
                      </TableCell>
                      <TableCell style={styles.tableCell}>
                        <TextField label="login_type" source="login_type"/>
                      </TableCell>
                      <TableCell style={styles.tableCell}>
                        <DateField label="createdAt" source="createdAt"/>
                      </TableCell>
                      <TableCell style={styles.tableCell}>
                        <DateField label="updatedAt" source="updatedAt"/>
                      </TableCell>
                      <TableCell style={styles.tableCell}>
                        <DateField label="updatedAt" source="user_secret.updatedAt"/>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>

                <h3>상품 구매시 사용한 배송지정보</h3>
                <ArrayField source="delivery_addresses">
                    <Datagrid>
                        <TextField label="id" source="id"/>
                        <TextField label="name_tag" source="name_tag"/>
                        <TextField label="recipient_name" source="recipient_name"/>
                        <TextField label="address1" source="address1"/>
                        <TextField label="address2" source="address2"/>
                        <TextField label="postal_code" source="postal_code"/>
                        <BooleanField label="is_island" source="is_island"/>
                        <DateField label="last_used_at" source="last_used_at"/>
                        <DateField label="createdAt" source="createdAt"/>
                    </Datagrid>
                </ArrayField>
                <div style={{
                    position: 'absolute',
                    right: 40,
                    top: 200,
                }}>
                    <FunctionField
                        render={value => `상품: ${value.visible_product_count ?? '0'}개 / 거래: ${value.order_count ?? '0'}개 / 리뷰: ${value.review_count ?? '0'}개`}
                    />
                </div>
            </Tab>
            <Tab label="Shop">
                <Table style={styles.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell style={styles.tableHead}>셀러 ID</TableCell>
                      <TableCell style={styles.tableHead}>셀러 등록일</TableCell>
                      <TableCell style={styles.tableHead}>평점</TableCell>
                      <TableCell style={styles.tableHead}>리뷰수</TableCell>
                      <TableCell style={styles.tableHead}>오프라인 셀러 여부</TableCell>
                      <TableCell style={styles.tableHead}>QR Url</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell style={styles.tableCell}>
                        <TextField label="id" source="id"/>
                      </TableCell>
                      <TableCell style={styles.tableCell}>
                        <DateField label="seller.registeredAt" source="seller.registeredAt"/>
                      </TableCell>
                      <TableCell style={styles.tableCell}>
                        <NumberField label="seller.rating" source="seller.rating"/>
                      </TableCell>
                      <TableCell style={styles.tableCell}>
                        <NumberField label="review_count" source="review_count"/>
                      </TableCell>
                      <TableCell style={styles.tableCell}>
                        <CustomBooleanField label="seller.is_offline_payment" source="seller.is_offline_payment"/>
                      </TableCell>
                      <TableCell style={styles.tableCell}>
                        <TextField label="seller.qr_image" source="seller.qr_image"/>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>

                <h3>계좌 정보</h3>
                <Table style={styles.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell style={styles.tableHead}>은행명</TableCell>
                      <TableCell style={styles.tableHead}>계좌번호</TableCell>
                      <TableCell style={styles.tableHead}>계좌주 명</TableCell>
                      <TableCell style={styles.tableHead}>비즈니스 셀러 여부</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell style={styles.tableCell}>
                        <TextField label="seller.seller_account.bank_name" source="seller.seller_account.bank_name"/>
                      </TableCell>
                      <TableCell style={styles.tableCell}>
                        <TextField label="seller.seller_account.bank_account" source="seller.seller_account.bank_account"/>
                      </TableCell>
                      <TableCell style={styles.tableCell}>
                        <TextField label="seller.seller_account.account_holder_name" source="seller.seller_account.account_holder_name"/>
                      </TableCell>
                      <TableCell style={styles.tableCell}>
                        <CustomBooleanField label="is_business_account" source="is_business_account"/>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <ReferenceManyField
                    reference="reviews"
                    target="seller_id"
                    label="Shop's review list"
                    perPage={50}
                >
                    <Datagrid rowClick="edit">
                        <TextField source="id"/>
                        <ReferenceField
                            reference="users"
                            source="user_id"
                            label="posted by"
                            link="show">
                            <TextField source="username"/>
                        </ReferenceField>
                        <ReferenceField
                            reference="products"
                            source="product_id"
                            label="product"
                            link="edit">
                            <TextField source="title"/>
                        </ReferenceField>
                        <StarRatingField label="Rating"/>
                        <RichTextField label="Text" source="text"/>
                        <DateField label="created at" source="createdAt"/>
                    </Datagrid>
                </ReferenceManyField>
            </Tab>
            <Tab label="Moderation">
                <h3>Admin Notes</h3>
                <ReferenceManyField
                    reference="admin_notes"
                    target="user_id"
                    label={false}
                    sort={{field: "created_at", order: "DESC"}}
                    pagination={<Pagination />}
                    fullWidth={true}
                >
                    <Datagrid>
                        <ReferenceField source="moderatorId" reference="users">
                            <UserLinkField />
                        </ReferenceField>
                        <TextField source="text" />
                        <DateField source="createdAt" showTime />
                        <DeleteButton redirect={false} undoable={false} />
                    </Datagrid>
                </ReferenceManyField>
                <ReferenceManyField
                    reference="admin_notes"
                    target="user_id"
                    label={false}
                    fullWidth={true}
                >
                    <Create onSuccess={onSuccess} {...props}>
                        <SimpleForm>
                            <TextInput multiline label="New Notes" source="text" validate={[required(), minLength(1), maxLength(65535)]} />
                            <TextInput type="hidden" initialValue={props.id} source="userId" label={false} style={{display: 'none'}} />
                        </SimpleForm>
                    </Create>
                </ReferenceManyField>

                <h3>신고 내역</h3>
                <FunctionField
                    addLabel={false}
                    render={record => <ReportTypeField record={record} /> }
                />
                <ReferenceManyField
                    reference="reports-user"
                    target="user_id"
                    label={false}
                    sort={{field: "createdAt", order: "DESC"}}
                    pagination={<Pagination />}
                    fullWidth={true}
                    perPage={50}
                >
                    <Datagrid rowClick={handleRowClick}>
                        <DateField label="신고날짜" source="createdAt"/>
                        <TextField label="신고자" source='reporter_id'/>
                        <TextField label='신고 이유' source='reason'/>
                        <TextField label='신고 타입' source="report_type"/>
                        <TextField label='신고받은 유저' source="user_id"/>
                        <TextField label='신고당한 상품' source="product.title"/>
                        <TextField  label='결정 방안' source="moderationDecision"/>
                        <DateField label="결정날짜" source="moderatedAt"/>
                    </Datagrid>
                </ReferenceManyField>
            </Tab>
            <Tab label="Products">
                <div style={{marginTop: '10px', marginBottom: '10px'}}>
                    <FunctionField
                        addLabel={false}
                        render={value => `상품: ${value.visible_product_count ?? '0'}개 / 숨겨진 상품: ${value.inVisible_product_count ?? '0'}개`}
                    />
                </div>

                <div style={{ display: 'flex', flexDirection: 'row', gap: 16}}>
                    <ProductHideButton />
                    <ProductHideButton isSoldProduct={true}/>
                    <RequestIndexingButton />
                </div>
                <ReferenceManyField
                    reference="products"
                    target="seller_id"
                    label="product list"
                    pagination={<Pagination />}
                    perPage={25}
                >
                    <Datagrid rowClick="show">
                        <TextField label={"아이디"} source="id"/>
                        <ProductImageField label="사진"/>
                        <TextField label="제목" source="title"/>
                        <TextField label="브랜드" source="brand"/>
                        <TextField label="가격" source="price"/>
                        <TextField label="상태" source="status"/>
                        <BooleanField label="노출상태" source="is_visible"/>
                        <DateField label="생성일" source="createdAt"/>
                        <EditButton/>
                    </Datagrid>
                </ReferenceManyField>
            </Tab>
            <Tab label="Orders">
                <ReferenceManyField
                    reference="orders"
                    target="user_id"
                    label="order list"
                    perPage={50}
                >
                    <Datagrid
                        rowClick="show">
                        <TextField source="id"/>
                        <ReferenceField source="seller_id" reference="users">
                            <UserLinkField />
                        </ReferenceField>
                        <ReferenceField
                            reference="products"
                            source="product_id"
                            label="product"
                            link="edit">
                            <TextField source="title"/>
                        </ReferenceField>
                        <TextField source="purchase_id"/>
                        <TextField source="status"/>
                        <DateField label="created at" source="createdAt"/>
                        <EditButton/>
                    </Datagrid>
                </ReferenceManyField>
            </Tab>
            <Tab label="Reviews">
                <ReferenceManyField
                    reference="reviews"
                    target="user_id"
                    label="review list"
                    perPage={50}
                >
                    <Datagrid
                        rowClick="edit">
                        <TextField source="id"/>
                        <ReferenceField
                            reference="products"
                            source="product_id"
                            label="product"
                            link="edit">
                            <TextField source="title"/>
                        </ReferenceField>
                        <StarRatingField label="Rating"/>
                        <RichTextField label="Text" source="text"/>
                        <DateField label="created at" source="createdAt"/>
                        <EditButton/>
                    </Datagrid>
                </ReferenceManyField>
            </Tab>
        </TabbedShowLayout>
    </Show>
)};

export const UserEdit = props => {
    const [isEditMode, setIsEditMode] = useState();
    return (
    <Edit undoable={false} {...props}>
        <TabbedForm redirect="list">
            <FormTab label="User Info">
                <TextInput label="id" source="id"/>
                <ImageInput source="image" accept="image/*" multiple={false} >
                    <ImageField/>
                </ImageInput>
                <ImageInput source="coverImage" accept="image/*" multiple={false} >
                    <ImageField/>
                </ImageInput>
                <TextInput source="email"/>
                <TextInput source="username"/>
                <TextInput source="nickname"/>
                <TextInput source="phone"/>
                <SelectInput source="status" choices={[
                    {
                        id: 'active', name: 'active'
                    },
                    {
                        id: 'left', name: 'left', disabled: true
                    },
                    {
                        id: 'banned', name: 'banned'
                    }
                ]}/>
                <BooleanInput
                    source="ban_chat"
                    defaultValue={false}/>
                <BooleanInput
                    source="ban_community"
                    defaultValue={false}/>
                <BooleanInput
                    source="ban_marketplace"
                    defaultValue={false}/>
                <SelectInput source="gender" choices={[
                    {
                        id: 'male', name: 'male'
                    }, {
                        id: 'female', name: 'female'
                    }
                ]}/>
                <TextInput multiline source="bio"/>
                <h3>Change the log-in type</h3>
                <FormControlLabel
                  control={
                    <Switch
                        checked={isEditMode}
                        onChange={event => setIsEditMode(event.target.checked)}
                    />
                  }
                />
                {isEditMode &&
                    <SelectInput
                        source="login_type"
                        choices={[
                            {id: 'email', name: 'email'},
                            {id: 'apple', name: 'apple', disabled: true},
                            {id: 'facebook', name: 'facebook', disabled: true},
                            {id: 'kakao', name: 'kakao', disabled: true},
                            {id: 'naver', name: 'naver', disabled: true}
                        ]}
                    />
                }
                <FormDataConsumer>
                    {
                        ({formData, ...rest}) => formData.login_type === 'email' &&
                            <div {...rest}>
                                <TextInput
                                    label="password"
                                    source="password"
                                />
                            </div>
                    }
                </FormDataConsumer>
                <br/>
                <h3>상품 구매시 사용한 배송지정보</h3>
                <ArrayField source="delivery_addresses">
                    <Datagrid>
                        <TextField label="id" source="id" />
                        <TextField label="name_tag" source="name_tag" />
                        <TextField label="recipient_name" source="recipient_name" />
                        <TextField label="recipient_phone" source="recipient_phone" />
                        <TextField label="postal_code" source="postal_code" />
                        <TextField label="address1" source="address1" />
                        <TextField label="address2" source="address2" />
                        <BooleanField label="is_island" source="is_island" />
                        <DateField label="last_used_at" source="last_used_at" />
                        <DateField label="createdAt" source="createdAt" />
                    </Datagrid>
                </ArrayField>
                <ArrayInput source="new_delivery_addresses" fullWidth >
                    <SimpleFormIterator disableReordering>
                        <TextInput label="recipient_name" source="recipient_name" validate={required()}/>
                        <TextInput label="recipient_phone" source="recipient_phone" validate={required()}/>
                        <TextInput label="postal_code(하이폰제외)" source="postal_code" validate={required()}/>
                        <TextInput label="address1 (도/시/군/구 + (도로명/건물번호 or 읍/면/동/리/번지))" source="address1" fullWidth validate={required()}/>
                        <TextInput label="address2 (건물상세주소 + 동/호수)" source="address2" fullWidth validate={required()}/>
                        <BooleanInput label="is_island" source="is_island"/>
                    </SimpleFormIterator>
                </ArrayInput>

            </FormTab>
            <FormTab label="Shop Info">
                <BooleanInput
                    label="Is visible"
                    source="seller.is_visible"
                    defaultValue={true}/>
                <WarningText>seller badge 여부를 관리자가 임의로 수정하지 마십시오.</WarningText>
                <BooleanInput
                    label="Is certified"
                    source="seller.is_certified"
                    defaultValue={false}/>
                <BooleanInput
                    label="Block Badge"
                    source="seller.is_blocked_badge"
                    defaultValue={false}/>
                <h3>Apply QR Code</h3>
                <BooleanInput
                    label="Offline Seller"
                    source="seller.is_offline_payment"
                    defaultValue={false}/>
                <TextInput label="fee" source="seller.fee"/>
                <DateTimeInput label="wave card fees until" source="seller.feeWavedUntil" />
                <h3>회원 배송지정보</h3>
                <TextInput label="account name" source="seller.seller_account.account_holder_name"/>
                <SelectInput label="bank name" source="seller.seller_account.bank_name" choices={BANK_LIST} optionText="name" optionValue="name" allowEmpty/>
                <TextInput label="bank account(하이폰제외)" source="seller.seller_account.bank_account"/>
                <BooleanInput
                    label="Is business account"
                    source="seller.is_business_account"
                    defaultValue={undefined}/>

                {/* Webapp and content feed links */}
                <FunctionField
                    label="Deep Link"
                    render={record => (
                        <a href={sellerUrl(WEBAPP_URL, {...record.seller, user: record})} target="_blank">
                            {sellerUrl(WEBAPP_URL, {...record.seller, user: record}).toString()}
                        </a>
                    )}
                />
                <FunctionField
                    label="Atom Feed"
                    render={record => (
                        <a href={sellerUrl(WEBAPP_URL, {...record.seller, user: record}) + '/feed.atom'} target="_blank">
                            {sellerUrl(WEBAPP_URL, {...record.seller, user: record}) + '/feed.atom'}
                        </a>
                    )}
                />
                <FunctionField
                    label="Google Shopping Feed"
                    render={record => (
                        <a href={sellerUrl(WEBAPP_URL, {...record.seller, user: record}) + '/googleshopping.xml'} target="_blank">
                            {sellerUrl(WEBAPP_URL, {...record.seller, user: record}) + '/googleshopping.xml'}
                        </a>
                    )}
                />
            </FormTab>
        </TabbedForm>
    </Edit>
    )
}

export const UserCreate = props => (
    <Create {...props} redirect="show" >
        <TabbedForm redirect="list">
            <FormTab label="User Info">
                <TextInput label="id" source="id" disabled/>
                <ImageInput source="image" accept="image/*" multiple={false} >
                    <ImageField/>
                </ImageInput>
                <ImageInput source="coverImage" accept="image/*" multiple={false} >
                    <ImageField/>
                </ImageInput>
                <TextInput source="email"/>
                <TextInput source="username"/>
                <TextInput source="nickname"/>
                <TextInput multiline source="bio"/>
                <SelectInput source="gender" label="gender" choices={[
                    {
                        id: 'male', name: 'male'
                    }, {
                        id: 'female', name: 'female'
                    }
                ]}/>
                <BooleanInput
                    source="ban_chat"
                    defaultValue={false}/>
                <BooleanInput
                    source="ban_community"
                    defaultValue={false}/>
                <BooleanInput
                    source="ban_marketplace"
                    defaultValue={false}/>
                <TextInput source="phone"/>
                <TextInput
                    label="password"
                    source="password"
                />
                <br/>
            </FormTab>
            <FormTab label="Shop Info">
                <FormDataConsumer>
                    {
                        ({formData, ...rest}) => {
                            <BooleanInput
                                label="Shop Certification"
                                source="seller.is_certified"
                                onChange={value => {
                                    // seller.is_certified can only be true if is_visible is only true
                                    if(value && value.target && value.target.checked) {
                                        form.change('seller.is_visible', true);
                                    }
                                }}
                                defaultValue={false} />
                        }
                    }
                </FormDataConsumer>
                <BooleanInput
                    label="Is visible"
                    source="seller.is_visible"
                    defaultValue={true}/>
                <TextInput label="fee" source="seller.fee"/>
                <h3>회원 배송지정보</h3>
                <TextInput label="account name" source="seller_account.account_holder_name"/>
                <SelectInput label="bank name" source="seller_account.bank_name" choices={BANK_LIST} optionText="name" optionValue="name" allowEmpty/>
                <TextInput label="bank account(하이폰제외)" source="seller_account.bank_account"/>
                <BooleanInput
                    label="Is business account"
                    source="seller.is_business_account"
                    defaultValue={undefined}/>
            </FormTab>
        </TabbedForm>
    </Create>
)
