import React from 'react';
import {useState} from 'react';
import {Button, SimpleForm, TextInput} from 'react-admin';
import {connect} from 'react-redux';
import {push} from 'connected-react-router';
import withStyles from '@material-ui/core/styles/withStyles';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import {API_URL} from '../config';
import {loadAccessToken} from '../lib/Auth';
import CircularProgress from '@material-ui/core/CircularProgress';
import SearchDictionaryRequestButton from "./SearchDictionaryRequestButton";

const outOfAppModalStyles = {
  dialogContent: {
    textAlign: 'center',
  },
  dialogPaperWidthMd: {
    width: '100%',
  },
  extractButton: {
    margin: '10px',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    gap: 12,
    width: '100%',
    alignItems: 'center',
  },
  input: {flex: 1},
  button: {
    marginBottom: 20,
    height: 48,
  },
  title: {
    fontWeight: 'bold',
    fontSize: 24,
  },
  tokenContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 12,
    width: '100%',
    backgroundColor: '#E5E5E5',
    borderRadius: 8,
    padding: 8,
  },
  token: {fontSize: 16, fontWeight: 'bold'},
  dictionaryContainer: {
    marginTop: 16,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    borderRadius: 8,
    border: '1px solid #E5E5E5',
    padding: 8,
    gap: 12,
  }
};

const PRODUCT_ES_ANALYZE_URL = `${API_URL}/es/analyze`;

const ESAnalyzeModal = withStyles(outOfAppModalStyles)(({
  classes,
  basePath,
  open,
  onClose,
  push,
  ...props
}) => {
  const [text, setText] = useState('');
  const [errorText, setErrorText] = useState('');
  const [loading, setLoading] = useState(false);
  const [tokens, setTokens] = useState([]);

  const handleKeyDown = async event => {
    if (event.key === 'Enter') {
      event.preventDefault();
      await sendAPI();
    }
  };

  const sendAPI = async () => {
    setLoading(true);
    setTokens([]);
    setErrorText('');
    try {
      const response = await fetch(PRODUCT_ES_ANALYZE_URL, {
        method: 'POST',
        headers: new Headers({
          Authorization: `bearer ${loadAccessToken()}`,
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify({text}),
      });
      const {result} = await response.json();
      if (result.error || result.statusCode === 400) {
        setErrorText(result.error || result.message);
      } else {
        const _tokens = result.tokens?.map(item => item.token);
        setTokens(_tokens);
        _tokens?.length === 0 && setErrorText('분석된 단어가 없습니다.');
      }
    } catch (error) {
      setErrorText(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      classes={{paperWidthMd: classes.dialogPaperWidthMd}}>
      <DialogTitle>ES Analyze</DialogTitle>
      <DialogContent classes={{root: classes.dialogContent}}>
        <SimpleForm toolbar={null}>
          <div style={outOfAppModalStyles.container}>
            <TextInput
              style={outOfAppModalStyles.input}
              source={'text'}
              value={text}
              onChange={event => setText(event.target.value)}
              onKeyDown={handleKeyDown}
            />
            <Button
              style={outOfAppModalStyles.button}
              disabled={!text}
              variant="contained"
              onClick={sendAPI}>
              <span style={{fontSize: 16}}>검색</span>
            </Button>
          </div>
        </SimpleForm>
        {errorText && <p>{errorText}</p>}
        {loading && (
          <div>
            <CircularProgress className={classes.icon} color="primary" />
          </div>
        )}
        {tokens?.length > 0 && (
          <div>
            <p style={outOfAppModalStyles.title}>분석된 단어 리스트</p>
            <div style={outOfAppModalStyles.tokenContainer}>
              {tokens.map((token, index) => (
                <span style={outOfAppModalStyles.token} key={index}>
                  {token}
                </span>
              ))}
            </div>
          </div>
        )}
        {text && tokens?.length > 0 && (
            <div style={outOfAppModalStyles.dictionaryContainer}>
              <p>{text}</p>
              <SearchDictionaryRequestButton text={text} />
            </div>

        )}
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} label="Cancel" onClick={onClose} />
      </DialogActions>
    </Dialog>
  );
});

export default connect(null, {
  push,
})(ESAnalyzeModal);
