import React from 'react';
import {
    DateField,
    Edit,
    FormDataConsumer,
    ImageField,
    Labeled,
    NumberField,
    ReferenceField,
    required,
    SaveButton,
    SelectInput,
    SimpleForm,
    TextInput,
    TextField,
    Toolbar
} from 'react-admin';

import UserLinkField from "../components/UserLinkField";
import ImageGallery from "../components/ImageGallery";

/**
 * Sub-component for a toolbar without the delete button.
 * @param props the react admin Toolbar component properties
 * @return {JSX.Element}
 * @constructor
 */
function CustomToolbar(props) {
    return (
        <Toolbar {...props}>
            <SaveButton disabled={props.pristine}/>
        </Toolbar>
    );
}

/**
 * Sub-component for the moderation decision selection.
 * @param record the current data record
 * @param props the react admin Filter component properties
 * @return {JSX.Element}
 * @constructor
 */
function ModerationSelectInput({record, ...props}) {
    return record?.moderationDecision ? (
        <TextInput disabled {...props} />
    ) : (
        <SelectInput
            choices={[
                {id: 'HIDDEN', name: 'Hide Reported Content'},
                {id: 'IGNORED', name: 'Ignore Report & Show Content Again'},
                {id: 'BANNED', name: 'User banned'}
            ]}
            validate={required()}
            {...props}
        />
    );
}

/**
 * Component for editing a report.
 * @param props the react admin Edit component properties
 * @return {JSX.Element}
 * @constructor
 */
export default function ReportEdit({classes, ...props}) {
    // build the UI elements
    return (
        <Edit {...props}>
            <SimpleForm actions={false} toolbar={<CustomToolbar />}>
                {/* generic report details */}
                <NumberField source="id"/>
                <DateField source="createdAt" label="Report Date"/>
                <TextField source="report_filter.name" label="신고필터유형" />
                <ReferenceField source="reporter_id" reference="users">
                    <UserLinkField/>
                </ReferenceField>
                <TextField source="reason"/>
                <Labeled label="신고 이미지"  children={<></>}/>
                <ImageGallery source="images" />

                {/* user report */}
                <FormDataConsumer>
                    {({formData, ...rest}) => formData.user && (
                        <Labeled label="User">
                            <ReferenceField {...rest} source="user.id" reference="users" allowEmpty>
                                <UserLinkField/>
                            </ReferenceField>
                        </Labeled>
                    )}
                </FormDataConsumer>

                {/* product report */}
                <FormDataConsumer>
                    {({formData, ...rest}) => formData.product && (
                        <>
                            <Labeled label="Product Image">
                                <ImageField {...rest} source="product.resizedSmallImages.0.url"/>
                            </Labeled>
                            <Labeled label="Product Seller">
                                <ReferenceField {...rest} source="product.seller_id" reference="users" allowEmpty>
                                    <UserLinkField/>
                                </ReferenceField>
                            </Labeled>
                            <Labeled label="Product">
                                <ReferenceField {...rest} source="product.id" reference="products" basePath="/products" link="show" allowEmpty>
                                    <TextField source="title"/>
                                </ReferenceField>
                            </Labeled>
                            <Labeled label="Product Date">
                                <DateField {...rest} source="product.createdAt"/>
                            </Labeled>
                        </>
                    )}
                </FormDataConsumer>

                {/* post report */}
                <FormDataConsumer>
                    {({formData, ...rest}) => formData.post && (
                        <>
                            <Labeled label="Post Image">
                                <ImageField {...rest} source="post.resizedSmallImages.0.url"/>
                            </Labeled>
                            <Labeled label="Post Author">
                                <ReferenceField {...rest} source="post.user_id" reference="users" allowEmpty>
                                    <UserLinkField/>
                                </ReferenceField>
                            </Labeled>
                            <Labeled label="Post">
                                <ReferenceField {...rest} source="post.id" reference="posts" basePath="/posts" link="show" allowEmpty>
                                    <TextField source="description"/>
                                </ReferenceField>
                            </Labeled>
                            <Labeled label="Post Date">
                                <DateField {...rest} source="post.createdAt"/>
                            </Labeled>
                        </>
                    )}
                </FormDataConsumer>

                {/* post comment report */}
                <FormDataConsumer>
                    {({formData, ...rest}) => formData.post_comment && (
                        <>
                            <Labeled label="Comment Author">
                                <ReferenceField {...rest} source="post_comment.user_id" reference="users" allowEmpty>
                                    <UserLinkField/>
                                </ReferenceField>
                            </Labeled>
                            <Labeled label="Comment">
                                <TextField {...rest} source="post_comment.text"/>
                            </Labeled>
                            <Labeled label="Comment Date">
                                <DateField {...rest} source="post_comment.createdAt"/>
                            </Labeled>
                        </>
                    )}
                </FormDataConsumer>

                {/* moderation status */}
                <DateField source="moderatedAt"/>
                <ModerationSelectInput source="moderationDecision"/>
            </SimpleForm>
        </Edit>
    );
}
