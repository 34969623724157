import {AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK, AUTH_GET_PERMISSIONS} from 'react-admin';
import {API_URL} from "./config";
import { saveAccessToken, loadAccessToken, removeAccessToken, saveUsername, removeUsername } from './lib/Auth';

export default (type, params) => {
    if (type === AUTH_LOGIN) {
        const {username, password} = params;
        const request = new Request(`${API_URL}/auth`, {
            method: 'POST',
            body: JSON.stringify({username, password}),
            headers: new Headers({'Content-Type': 'application/json'}),
        })
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status === 403 || response.status === 401) {
                    console.log('FETCH ERROR')
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(result => {
                if (result.error) {
                    throw new Error(result.error);
                };
                const {
                    result: {
                        access_token,
                        categories
                    }
                } = result
                console.log({access_token, categories});
                saveAccessToken(access_token);
                saveUsername(username);
                return Promise.resolve();
            });
    }
    if (type === AUTH_LOGOUT) {
        removeAccessToken();
        removeUsername();
        return Promise.resolve();
    }
    if (type === AUTH_ERROR) {
        console.log('AUTH ERROR TYPE :', type)
        console.log('AUTH ERROR PARAMS :', params)
        const status  = params.message;
        if (status === 'LOGOUT') {
            removeAccessToken();
            removeUsername();
            return Promise.reject();
        }
        return Promise.resolve();
    }

    if (type === AUTH_CHECK) {
        console.log('HALLO : ', loadAccessToken() )
        return loadAccessToken() ? Promise.resolve() : Promise.reject();
    }
    if (type === AUTH_GET_PERMISSIONS) {
        return Promise.resolve();
    }
    return Promise.resolve('ERROR!!!');
}