import {FunctionField} from 'react-admin';

const getNestedValue = (obj, path) => {
  return path.split('.').reduce((acc, part) => acc && acc[part], obj);
};

const CustomPriceField = ({source}) => {
  return (
    <FunctionField
      source={source}
      render={record => {
        const value = getNestedValue(record, source);

        // 가격을 원 단위로 포맷팅
        return value != null
          ? `${new Intl.NumberFormat('ko-KR', {maximumFractionDigits: 0}).format(value)}원`
          : '0원';
      }}
    />
  );
};

export default CustomPriceField;
